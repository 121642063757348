import { SingleAdminValidationSubmission, StatusType, ValidationSubmission } from '../../../types'

export const mapOrganisationStatus = (
  verification: ValidationSubmission | SingleAdminValidationSubmission
): StatusType.Pending | StatusType.Approved | StatusType.Rejected => {
  if (verification.validationRequest?.rejectedAt) {
    return StatusType.Rejected
  }

  if (('organisationId' in verification && verification?.organisationId) || verification?.organisation?.id) {
    return StatusType.Approved
  }

  return StatusType.Pending
}
