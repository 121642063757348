import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { IconRegistry } from '../../../../../../libs/shared/ui-lemonade/src/components/icon/icon-registry'

import { BankDetailsHeader } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsHeader/BankDetailsHeader'
import { BankDetailsDialog } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsDialog/BankDetailsDialog'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import styles from './BankDetailsView.module.scss'
import { useBreadcrumbsData } from '@percent/admin-dashboard/app/Layout/breadcrumbsContext/useBreadcrumbsData/useBreadcrumbsData'
import { Loader } from '@percent/admin-dashboard/common/components'
import { BankDetailsFormDialog } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsFormDialog/BankDetailsFormDialog'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { BankDetailsAddNoteForm } from './bankDetailsAddNoteForm/BankDetailsAddNoteForm'
import { BankDetailsTimeline } from './bankDetailsTimeline/BankDetailsTimeline'
import { BankDetailsDocuments } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsDocuments/BankDetailsDocuments'
import { BankDetailsAccountSection } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsAccountSection/BankDetailsAccountSection'
import { BankDetailsContactSection } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView//bankDetailsContactSection/BankDetailsContactSection'
import { Button } from '@percent/lemonade'
import { WireTransferType } from '@percent/domain/bank-details'
import { BankDetailsValidationDataSection } from './bankDetailsValidationDataSection/BankDetailsValidationDataSection'

export function BankDetailsView() {
  const {
    bankDetailsSubmitFeatureFlag,
    bankDetailsReviewAddNoteFeatureFlag,
    bankDetailsReviewTimelineFeatureFlag,
    bankDetailsValidationFeatureFlag
  } = useFeatureFlag()
  const { bankDetailsService } = useServices()
  const { id } = useParams<{ id: string }>()
  const { setPath } = useBreadcrumbsData()
  const { t } = useTranslation()
  const [dialogState, setDialogState] = useState(false)
  const [dialogFormState, setDialogFromState] = useState(false)
  const [formStatus, setFormStatus] = useState<null | 'approved' | 'rejected' | 'deleted'>(null)
  const [sortOrder, setSortOrder] = useState<number>(0)

  const sortTypes: { text: string; key: 'asc' | 'desc'; icon: keyof typeof IconRegistry }[] = [
    {
      text: t('typography.newestFirst'),
      key: 'desc',
      icon: 'arrow-down'
    },
    {
      text: t('typography.oldestFirst'),
      key: 'asc',
      icon: 'arrow-up'
    }
  ]

  const [{ data, isLoading }, { refresh }] = useQuery(bankDetailsService.getBankDetailsById, { id })

  const [{ data: timelineData, isLoading: isTimelineLoading, error: timelineError }, { refresh: refreshTimeline }] =
    useQuery(bankDetailsService.getBankDetailsReviewRequestLogs, {
      reviewId: id
    })

  const disableApprove =
    !data?.bankAccountDetails && data?.requiredBankAccountDetails?.type === WireTransferType.DOMESTIC_WITH_BIC

  const enableEdit =
    disableApprove ||
    (data?.requiredBankAccountDetails?.type === WireTransferType.DOMESTIC_WITH_BIC && data?.status === 'pending')

  useEffect(() => setPath(data?.organisationName), [setPath, data])

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <BankDetailsHeader
        organizationName={data?.organisationName}
        status={data?.status}
        reference={data?.reference}
        setDialogState={setDialogState}
        setFormStatus={setFormStatus}
        disabledApprove={disableApprove}
        claimer={data?.claimer}
        validationFeatureFlag={bankDetailsValidationFeatureFlag}
        bankData={data}
      />
      <BankDetailsDialog
        open={dialogState}
        onClose={() => setDialogState(false)}
        organisationId={data?.organisationId}
        organisationName={data?.organisationName}
        formStatus={formStatus}
        refresh={refresh}
        refreshTimeline={refreshTimeline}
      />
      {enableEdit && (
        <BankDetailsFormDialog
          organisationId={data?.organisationId}
          organisationName={data?.organisationName}
          open={dialogFormState}
          onClose={() => setDialogFromState(false)}
          bankDetailsData={data}
          refresh={refresh}
          refreshTimeline={refreshTimeline}
        />
      )}
      <div className={styles.container}>
        <div className={styles.leftPanel}>
          <BankDetailsContactSection data={data} />
          <BankDetailsAccountSection
            bankDetailsSubmitFeatureFlag={bankDetailsSubmitFeatureFlag}
            data={data}
            enableEdit={enableEdit}
            disableApprove={disableApprove}
            reviewId={id}
            validationFeatureFlag={bankDetailsValidationFeatureFlag}
            onEdit={() => setDialogFromState(true)}
          />
          {bankDetailsValidationFeatureFlag && <BankDetailsValidationDataSection data={data} />}
          {bankDetailsReviewAddNoteFeatureFlag && (
            <Grid container spacing={0} component={Paper} elevation={0} className={styles.listContainer}>
              <Grid item xs={12} className={styles.title}>
                <Typography variant="h6">{t('typography.addANote')}</Typography>
              </Grid>
              <BankDetailsAddNoteForm reviewId={id} refreshTimeline={refreshTimeline} />
            </Grid>
          )}
        </div>
        <div className={styles.rightPanel}>
          <BankDetailsDocuments refreshTimeline={refreshTimeline} bankDetailsId={id} bankDetailsStatus={data?.status} />
        </div>
      </div>
      {bankDetailsReviewTimelineFeatureFlag && (
        <Grid container spacing={0} component={Paper} elevation={0} className={styles.listContainer}>
          <Grid item xs={12} className={styles.title}>
            <Typography variant="h6">{t('typography.timeline')}</Typography>
            <Button
              endIcon={sortTypes[sortOrder].icon}
              endIconSize={4}
              variant="secondary"
              size="mini"
              onPress={() => {
                setSortOrder(sortOrder === 0 ? 1 : 0)
              }}
            >
              {sortTypes[sortOrder].text}
            </Button>
          </Grid>
          <BankDetailsTimeline
            data={timelineData}
            isLoading={isTimelineLoading}
            error={timelineError}
            sortOrder={sortTypes[sortOrder].key}
          />
        </Grid>
      )}
    </>
  )
}
