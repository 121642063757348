import {
  AgentVerificationStatus,
  OrganisationTypes,
  SingleAdminValidationSubmission,
  SingleValidationSubmission,
  StatusType
} from '../types/application.types'

export const validationSubmission: SingleValidationSubmission = {
  id: 'staging_validationsubmission_000000',
  validationInviteId: 'staging_validationinvite_0000',
  status: StatusType.Failed,
  configurationId: 'staging_hostedconfiguration_XXXXXXXXXX',
  configuration: {
    id: 'staging_hostedconfiguration_XXXXXXXXXX',
    name: 'Test configuration',
    allowedOrganisations: {
      types: [OrganisationTypes.Nonprofit]
    }
  },
  organisationId: 'staging_organisation_000000',
  validationRequestId: null,
  monitoringSubscriptionId: 'staging_monitoringsubscription_000000',
  agentVerificationId: 'staging_agentverification_000000',
  createdAt: '2022-05-23T14:22:57.277Z',
  eligibilitySubscriptionId: 'staging_eligibilitysubscription_000000',
  organisationName: 'Test Organisation Name',
  validationRequestName: null,
  validationRequestAcceptedAt: null,
  validationRequestRejectedAt: null,
  agentVerificationFirstName: 'John',
  agentVerificationLastName: 'Doe',
  agentVerificationEmail: 'jdoe@test.com',
  agentVerificationStatus: 'pending_user_verification',
  agentVerificationRejectionReason: null,
  partnerFields: {
    question1: ['option 1', 'option 2'],
    question2: 'option 3',
    question3: 4,
    question4: '5'
  },
  metadata: null,
  agentVerification: {
    id: 'staging_agentverification_000000',
    email: 'jdoe@test.com',
    firstName: 'John',
    lastName: 'Doe',
    status: AgentVerificationStatus.APPROVED,
    rejectionReason: null,
    rejectionReasonCode: null,
    language: 'en-EN'
  },
  monitoring: {
    status: 'live',
    results: {
      adverseMedia: {
        status: 'clear'
      },
      sanction: {
        status: 'clear'
      },
      warning: {
        status: 'clear'
      },
      hateSpeech: {
        status: 'clear'
      },
      controversial: {
        status: 'clear'
      },
      commercial: {
        status: 'clear'
      },
      complianceStatus: 'pass',
      registration: {
        active: 'yes'
      }
    }
  },
  eligibility: {
    status: 'live',
    results: {
      eligibilityStatus: 'pass',
      confirmedActivitySubTags: [
        {
          id: 'staging_activitysubtag_000000',
          name: 'Museum',
          description:
            'Organisations that acquire, conserve, research, educate and exhibit the heritage of humanity and its environment',
          createdAt: '2022-02-10T10:33:40.152Z',
          updatedAt: null,
          tag: {
            id: 'staging_activitytag_000000',
            name: 'Arts, Cultural & Historic',
            description: null,
            createdAt: '2022-02-10T10:33:40.152Z',
            updatedAt: null
          }
        }
      ],
      rejectedActivitySubTags: null
    }
  }
}

export const adminValidationSubmission: SingleAdminValidationSubmission = {
  id: 'staging_validationsubmission_000000',
  status: StatusType.Failed,
  configuration: {
    id: 'staging_hostedconfiguration_XXXXXXXXXX',
    name: 'Test configuration',
    allowedOrganisations: {
      types: [OrganisationTypes.Nonprofit]
    }
  },
  email: 'jdoe@test.com',
  firstName: 'John',
  lastName: 'Doe',
  partnerId: 'partner_xxxxxx',
  validationRequest: undefined,
  organisation: undefined,
  createdAt: '2022-05-23T14:22:57.277Z',
  partnerFields: {
    question1: ['option 1', 'option 2'],
    question2: 'option 3',
    question3: 4,
    question4: '5'
  },
  metadata: null,
  agentVerification: {
    id: 'staging_agentverification_000000',
    email: 'jdoe@test.com',
    firstName: 'John',
    lastName: 'Doe',
    status: AgentVerificationStatus.APPROVED,
    rejectionReason: null,
    rejectionReasonCode: null,
    language: 'en-EN'
  },
  monitoring: {
    status: 'live',
    results: {
      adverseMedia: {
        status: 'clear'
      },
      sanction: {
        status: 'clear'
      },
      warning: {
        status: 'clear'
      },
      hateSpeech: {
        status: 'clear'
      },
      controversial: {
        status: 'clear'
      },
      commercial: {
        status: 'clear'
      },
      complianceStatus: 'pass',
      registration: {
        active: 'yes'
      }
    }
  },
  eligibility: {
    status: 'live',
    results: {
      eligibilityStatus: 'pass',
      confirmedActivitySubTags: [
        {
          id: 'staging_activitysubtag_000000',
          name: 'Museum',
          description:
            'Organisations that acquire, conserve, research, educate and exhibit the heritage of humanity and its environment',
          createdAt: '2022-02-10T10:33:40.152Z',
          updatedAt: null,
          tag: {
            id: 'staging_activitytag_000000',
            name: 'Arts, Cultural & Historic',
            description: null,
            createdAt: '2022-02-10T10:33:40.152Z',
            updatedAt: null
          }
        }
      ],
      rejectedActivitySubTags: null
    }
  }
}
