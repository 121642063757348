import { Grid } from '@percent/lemonade'
import { BankDetailsValidationBoxProps } from './BankDetailsValidationBox.props'
import styled from 'styled-components'

const StyledGridColumn = styled(Grid.Col)`
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  color: rgba(0, 0, 0, 0.54);
`

export function BankDetailsValidationBox({
  entries,
}: BankDetailsValidationBoxProps) {
  return (
    <div>
      {entries.length > 0 && (
        <Grid columns={1} gutter="xs">
          {entries.map((entry) => (
            <StyledGridColumn key={entry.key}>
              {entry.key}: {entry.value}
            </StyledGridColumn>
          ))}
        </Grid>
      )}
    </div>
  )
}
