import * as Yup from 'yup'
import { sanitizeStringToRegex } from '@percent/utility'
import {
  RequiredBankAccountDetailsFields,
  WireTransferType,
} from '../../types/bankDetails.types'

type bankDetailsViewSchemaProps = {
  t: (id: string) => string
  addBankDetailsFormLabels?: {
    type: WireTransferType
    fields: RequiredBankAccountDetailsFields
  }
}

export const bankDetailsViewSchema = ({
  t,
  addBankDetailsFormLabels,
}: bankDetailsViewSchemaProps) => {
  const fields = Object.entries(addBankDetailsFormLabels?.fields || {}).reduce(
    (acc, [key, { errorMessage, regex, required }]) => {
      const rules = Yup.string()
        .matches(sanitizeStringToRegex(regex || ''), errorMessage)
        .uppercase()

      return {
        ...acc,
        [key]: required ? rules.required(t('errorMessage.required')) : rules,
      }
    },
    {}
  )

  return Yup.object().shape({
    holderName: Yup.string()
      .max(50, t('errorMessage.holderName'))
      .min(2, t('errorMessage.holderName'))
      .required(t('errorMessage.holderNameRequired')),
    ...fields,
  })
}
