import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { Modal, ConfirmationModal } from '@percent/lemonade'
import { RemoveFromAutoApprovalListProps } from './RemoveFromAutoApprovalListModal.types'

export function RemoveFromAutoApprovalListModal({
  autoApprovalEntry,
  setAutoApprovalEntry,
  refresh
}: Readonly<RemoveFromAutoApprovalListProps>) {
  const { t } = useTranslation()
  const { autoApprovalListService } = useServices()

  const [{ isLoading }, { apiFunc }] = useMutation(autoApprovalListService.removeFromAutoApprovalList, () => {
    refresh()
    setAutoApprovalEntry(undefined)
  })

  const removeFromAutoApprovalList = () => apiFunc({ id: autoApprovalEntry?.id || '' })

  return (
    <Modal open={!!autoApprovalEntry?.id}>
      <ConfirmationModal
        title={t('dialog.removeFromApprovalList.title')}
        loading={isLoading}
        primaryButtonText={t('button.remove')}
        primaryBtnTestId="remove-from-approval-list"
        secondaryButtonText={t('button.cancel')}
        handleSubmit={removeFromAutoApprovalList}
        handleClose={() => setAutoApprovalEntry(undefined)}
      >
        {t('dialog.removeFromApprovalList.description', {
          agentEmail: autoApprovalEntry?.email,
          organisationId: autoApprovalEntry?.organisationId
        })}
      </ConfirmationModal>
    </Modal>
  )
}
