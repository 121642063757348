import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { mapToVerificationConfiguration } from '@percent/admin-dashboard/app/validationSubmissions/utils/configurationMappers'
import { ErrorView, Loader } from '@percent/admin-dashboard/common/components'
import { useActivityTagsState, useQuery, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { getCountryFromAlpha3 } from '@percent/admin-dashboard/common/utility/getCountryFromCountryCode'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import {
  ValidationRequestRejectionReasonCodes,
  VerificationAgentDetailsCard,
  VerificationDocumentsCard,
  VerificationMetadataCard,
  VerificationOrganisationDetailsCard,
  VerificationPartnerFieldsCard,
  VerificationSummaryCard
} from '@percent/domain/verifications'
import { Button, Tab } from '@percent/lemonade'
import styles from './ValidationSubmissionDetailsContainer.module.scss'
import { ValidationSubmissionRelationships } from './validationSubmissionRelationships/ValidationSubmissionRelationships'
import { ValidationSubmissionTimeline } from './validationSubmissionTimeline/ValidationSubmissionTimeline'
import { ValidationSubmissionTopBar } from './validationSubmissionTopBar/ValidationSubmissionTopBar'

export function ValidationSubmissionDetailsContainer() {
  const [activeTab, setActiveTab] = useState('')

  const { validationSubmissionsService, adminService } = useServices()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { push } = useHistory()
  const { activityTags, isLoading: isActivityTagsLoading } = useActivityTagsState()

  const [
    {
      data: validationSubmission,
      isLoading: isValidationSubmissionLoading,
      errorMessage: validationSubmissionErrorMessage
    }
  ] = useQuery(validationSubmissionsService.getValidationSubmission, { id })

  const [
    {
      data: validationSubmissionConfiguration,
      isLoading: isValidationSubmissionConfigurationLoading,
      errorMessage: validationSubmissionConfigurationErrorMessage
    }
  ] = useQuery(
    validationSubmissionsService.getValidationSubmissionConfiguration,
    { id: validationSubmission?.configuration?.id },
    { skip: !validationSubmission?.configuration?.id }
  )

  const organisationId = validationSubmission?.organisation?.id

  const [{ dataOrNull: validationSubmissionDocuments, isLoading: isLoadingValidationSubmissionDocuments }] =
    useQueryList(adminService.getValidationSubmissionDocuments, {
      validationSubmissionId: id
    })

  const [{ data: organisation, isLoading: isOrganisationLoading, errorMessage: organisationErrorMessage }] = useQuery(
    adminService.getOrganisation,
    { id: organisationId || '' },
    { skip: !organisationId }
  )
  const goToOrganisationPage = () => push(`/organizations/${organisationId}`)

  if (isValidationSubmissionLoading || isLoadingValidationSubmissionDocuments || isOrganisationLoading)
    return <Loader />

  if (validationSubmissionErrorMessage || validationSubmissionConfigurationErrorMessage || organisationErrorMessage) {
    return (
      <ErrorView
        errorMessage={
          validationSubmissionErrorMessage || validationSubmissionConfigurationErrorMessage || organisationErrorMessage
        }
      />
    )
  }

  const hasNoEligibleOrganisationType =
    !!organisation &&
    validationSubmission?.validationRequest?.rejectionReasonCode ===
      ValidationRequestRejectionReasonCodes.NotEligible &&
    // TODO: double check after DELPHI PR created https://wearepercent.atlassian.net/browse/DELPHI-1062
    !validationSubmission.configuration?.allowedOrganisations?.types?.some(item => organisation?.types.includes(item))
  const hasMetadata = validationSubmission?.metadata && !!Object.keys(validationSubmission.metadata).length

  const checksLinks = {
    agentVerification: {
      jiraLink: validationSubmission?.agentVerification?.jiraLink,
      goToCheckLink: `/agent-verification/${validationSubmission?.agentVerification?.id}`
    },
    eligibility: {
      jiraLink: validationSubmission?.eligibility?.jiraLink,
      goToCheckLink: `/eligibility/${validationSubmission?.eligibility?.id}`
    },
    monitoring: {
      jiraLink: validationSubmission?.monitoring?.jiraLink,
      goToCheckLink: `/organizations/${organisation?.id}/compliance`
    },
    organisation: {
      jiraLink: validationSubmission?.validationRequest?.jiraLink,
      goToCheckLink: !validationSubmission?.validationRequest?.id
        ? `/organizations/${organisation?.id}`
        : `/validations/${validationSubmission?.validationRequest?.id}`
    }
  }

  return (
    <div>
      <ValidationSubmissionTopBar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        id={validationSubmission.id}
        organizationName={validationSubmission?.organisation?.name ?? validationSubmission?.validationRequest?.name}
      />
      <Tab index={`/validation-submissions/${id}`} value={activeTab}>
        <div className={styles.applicationDetailsContainer}>
          <VerificationSummaryCard
            verification={validationSubmission}
            organisation={organisation}
            hasNoEligibleOrganisationType={hasNoEligibleOrganisationType}
            cardTitle={t('typography.checksSummary')}
            checksLinks={checksLinks}
            configuration={
              validationSubmissionConfiguration && mapToVerificationConfiguration(validationSubmissionConfiguration)
            }
            activityTags={activityTags}
            isLoadingConfiguration={isValidationSubmissionConfigurationLoading || isActivityTagsLoading}
          />
          {validationSubmission?.agentVerification?.id && (
            <VerificationAgentDetailsCard
              agentName={`${validationSubmission?.agentVerification?.firstName} ${validationSubmission?.agentVerification?.lastName}`}
              agentEmail={validationSubmission?.agentVerification?.email}
              language={validationSubmission?.agentVerification?.language}
            />
          )}
          {!validationSubmission?.agentVerification?.id && validationSubmission.firstName && (
            <VerificationAgentDetailsCard
              agentName={`${validationSubmission.firstName} ${validationSubmission.lastName}`}
              agentEmail={validationSubmission.email}
            />
          )}
          {organisation && (
            <VerificationOrganisationDetailsCard
              organisation={organisation}
              isLoading={isValidationSubmissionLoading}
              validationRequest={validationSubmission.validationRequest}
              eligibility={validationSubmission?.eligibility}
              hasNoEligibleOrganisationType={hasNoEligibleOrganisationType}
              getCountryNameByCode={getCountryFromAlpha3}
              action={
                organisationId && (
                  <Button variant="secondary" onPress={goToOrganisationPage}>
                    {t('typography.viewOrganizationDetails')}
                  </Button>
                )
              }
            />
          )}
          <ValidationSubmissionRelationships relationships={validationSubmission.organisation?.relationships} />
          {validationSubmission.partnerFields && !!Object.keys(validationSubmission.partnerFields)?.length && (
            <VerificationPartnerFieldsCard partnerFields={validationSubmission.partnerFields} />
          )}
          {hasMetadata && <VerificationMetadataCard metadata={validationSubmission.metadata!} />}
          <VerificationDocumentsCard
            documents={validationSubmissionDocuments || []}
            isLoading={isLoadingValidationSubmissionDocuments}
          />
        </div>
      </Tab>
      <Tab index={`/validation-submissions/${id}/timeline`} value={activeTab}>
        <ValidationSubmissionTimeline validationSubmission={validationSubmission} />
      </Tab>
    </div>
  )
}
