import { Button, FormField, TextInput } from '@percent/lemonade'
import styled from 'styled-components'
import { FormikProvider } from 'formik'
import { BankDetailsViewProps } from './BankDetailsView.types'
import { UploadDocument } from '../uploadDocument/UploadDocument'
import { BankDetailsValidationError } from '../../types/bankDetails.types'

const StyledSubmitButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: end;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
`

export function BankDetailsView({
  setSelectedFile,
  buttonFlag,
  uploadButtonFlag,
  disableUploadBtn,
  disabledField,
  bankDetailsData,
  selectedFile,
  fileFieldText,
  isPostLoading,
  translations,
  formik,
  fields,
  isAdminDashboard = false,
  bankData,
  isValidated,
  onFileChange,
  onValidationFailed,
  onValidationSuccess,
}: BankDetailsViewProps) {
  const {
    errors,
    values,
    touched,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    isValid,
  } = formik

  const isFormValid = bankDetailsData?.addBankDetailsFormLabels
    ? selectedFile && isValid && dirty && !isPostLoading
    : selectedFile && !isPostLoading

  const uploadDocumentErrorMessage = isValidated
    ? bankData?.validationErrors.find(
        (error: BankDetailsValidationError) => error.field === 'uploadDocument'
      )?.message
    : null

  return (
    <StyledForm onSubmit={handleSubmit}>
      {bankDetailsData?.addBankDetailsFormLabels && (
        <FormikProvider value={formik}>
          <>
            <FormField
              label={translations.accountHolderName}
              status={
                touched.holderName && errors.holderName ? 'danger' : 'default'
              }
              statusMessage={errors.holderName}
              disabled={disabledField}
              description={translations.accountHolderDescription}
              descriptionVariant="light"
              data-testid="holderName"
              necessity="required"
            >
              <TextInput
                name="holderName"
                value={values.holderName}
                placeholder={
                  Number(bankDetailsData.organisationName?.length) <= 50
                    ? bankDetailsData.organisationName
                    : translations.accountHolderPlaceholder
                }
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormField>
            {fields.map(({ field, hint, label, required, placeholder }) => (
              <FormField
                label={label}
                status={
                  touched?.[field] && errors?.[field] ? 'danger' : 'default'
                }
                statusMessage={errors?.[field]}
                description={hint}
                descriptionVariant="light"
                disabled={disabledField}
                data-testid={field}
                necessity={required ? 'required' : 'optional'}
                key={field}
              >
                <TextInput
                  name={field}
                  value={values?.[field]?.toUpperCase()}
                  placeholder={placeholder}
                  onBlur={handleBlur}
                  onChange={({ target: { value } }) => {
                    const onlyLettersDigitsAndHyphensRegex = /^[0-9a-zA-Z-]*$/
                    if (
                      field !== 'accountNumber' ||
                      onlyLettersDigitsAndHyphensRegex.test(value)
                    ) {
                      setFieldValue(field, value)
                    }
                  }}
                />
              </FormField>
            ))}
          </>
        </FormikProvider>
      )}
      <UploadDocument
        helperText={translations.helperBankAccountText}
        helperLinkText={translations.helperBankAccountLinkText}
        uploadDocumentPlaceholder={translations.uploadDocumentPlaceholder}
        labelText={translations.proofBankAccount}
        uploadButton={translations.uploadDocument}
        fileFieldText={fileFieldText}
        setSelectedFile={setSelectedFile}
        buttonFlag={uploadButtonFlag}
        disableUploadBtn={disableUploadBtn}
        inputDisableStyle={!uploadButtonFlag}
        isAdminDashboard={isAdminDashboard}
        errorMessage={uploadDocumentErrorMessage}
        onFileChange={onFileChange}
        onValidationFailed={onValidationFailed}
        onValidationSuccess={onValidationSuccess}
      />
      {buttonFlag && (
        <StyledSubmitButtonContainer>
          <Button
            type="submit"
            variant={isFormValid ? 'primary' : 'secondary'}
            data-testid="add-bank-details-submit-button"
            disabled={!isFormValid}
          >
            {translations.submitBankDocuments}
          </Button>
        </StyledSubmitButtonContainer>
      )}
    </StyledForm>
  )
}
