import { factory, primaryKey } from '@mswjs/data'

const dbBase = {
  countryCode: String,
  organisationId: primaryKey(String),
  organisationName: String,
  claimer: { email: String },
  status: String,
  requestDate: String,
  document: {
    url: String
  },
  reference: String,
  bankValidationData: {
    success: Boolean,
    validationErrors: Array<{
      field: string
      message: string
    }>,
    branch: {
      bankName: String,
      address: {
        addressLine1: String,
        addressLine2: String,
        country: String
      }
    }
  }
}

const fieldsBase = {
  placeholder: String,
  errorMessage: String,
  hint: String,
  label: String,
  regex: String,
  required: Boolean
}

export const dbDomestic = factory({
  // Disable check to handle validationErrors properly.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  bankDetails: {
    ...dbBase,
    bankAccountDetails: {
      holderName: String,
      wireTransferDetails: {
        type: String,
        bankCode: {
          value: String,
          label: String
        },
        accountNumber: {
          value: String,
          label: String
        }
      }
    }
  }
})

export const dbIBAN = factory({
  // Disable check to handle validationErrors properly.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  bankDetails: {
    ...dbBase,
    bankAccountDetails: {
      holderName: String,
      wireTransferDetails: {
        type: String,
        bic: {
          value: String,
          label: String
        },
        iban: {
          value: String,
          label: String
        }
      }
    }
  }
})

export const dbDomesticWithBIC = factory({
  // Disable check to handle validationErrors properly.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  bankDetails: {
    ...dbBase,
    requiredBankAccountDetails: {
      fields: {
        accountNumber: fieldsBase,
        bankCode: fieldsBase,
        bic: fieldsBase,
        branchCode: fieldsBase
      },
      type: String
    }
  }
})

export const dbDocuments = factory({
  document: {
    id: primaryKey(String),
    fileName: String,
    mimeType: String,
    url: String,
    createdAt: String,
    createdBy: String
  }
})
