import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OrganizationDetailsProps } from '../OverviewDetails.types'

import { Button, CardDashboard, CardContent, Spacer } from '@percent/lemonade'
import { EditLocationModal } from './EditLocationModal'
import { getCountryFromAlpha3 } from '@percent/admin-dashboard/common/utility/getCountryFromCountryCode'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'

export function Location({ organization, refresh }: OrganizationDetailsProps) {
  const [dialogState, setDialogState] = useState(false)
  const { t } = useTranslation()
  const handleRegistriesDialog = () => {
    setDialogState(prevState => !prevState)
  }
  const { editLocationDetailsFeatureFlag } = useFeatureFlag()

  return (
    <>
      <CardDashboard
        title={t('typography.location')}
        action={
          editLocationDetailsFeatureFlag && (
            <Button onPress={handleRegistriesDialog} variant="secondary" data-testid="location-details-edit-button">
              {t('typography.editLocation')}
            </Button>
          )
        }
      >
        <CardContent title={t('typography.addressLine1')} testId="location-details-address1">
          {organization.addressLine1 || t('typography.notAvailable')}
        </CardContent>
        <CardContent title={t('typography.addressLine2')} testId="location-details-address2">
          {organization.addressLine2 || t('typography.notAvailable')}
        </CardContent>
        <CardContent title={t('typography.city')} testId="location-details-city">
          {organization.city || t('typography.notAvailable')}
        </CardContent>
        <CardContent
          testId="location-details-state"
          title={t(organization.countryCode !== 'USA' ? 'typography.countyOrState' : 'typography.state')}
        >
          {organization.state || t('typography.notAvailable')}
        </CardContent>
        <CardContent title={t('typography.postalCode')} testId="location-details-postcode">
          {organization.postcode || t('typography.notAvailable')}
        </CardContent>
        <CardContent title={t('typography.country')} testId="location-details-country">
          {getCountryFromAlpha3(organization.countryCode) || t('typography.notAvailable')}
        </CardContent>
      </CardDashboard>
      <Spacer size={6} axis="vertical" />
      <EditLocationModal
        open={dialogState}
        onClose={handleRegistriesDialog}
        organization={organization}
        refresh={refresh}
      />
    </>
  )
}
