import { Grid, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { isValidAlpha3CountryCode, getCountryFromAlpha3 } from '../../../../common/utility/getCountryFromCountryCode'

import styles from './BankDetailsValidationDataSection.module.scss'
import { BankDetailsValidationDataSectionProps } from './BankDetailsValidationDataSection.types'
import { Alert } from '@percent/lemonade'

export function BankDetailsValidationDataSection({ data }: BankDetailsValidationDataSectionProps) {
  const { t } = useTranslation()

  const validationData = data?.bankValidationData

  const missingBankData = !validationData?.branch?.bankName
  const branch = validationData?.branch

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.listContainer}>
      <Grid item xs={12} className={styles.title}>
        <Typography variant="h6">{t('typography.bankData')}</Typography>
      </Grid>
      {missingBankData ? (
        <div className={styles.notFoundDataAlert}>
          <Alert variant="warning" title={t('bankData.missingBankData.title')}>
            {t('bankData.missingBankData.message')}
          </Alert>
        </div>
      ) : (
        <ul className={styles.bankDetailsList}>
          <li>
            <p data-testid="bank-name-field">{t('bankDetails.bankName')}</p>
            <span data-testid="bank-name-value">{branch?.bankName}</span>
          </li>
          {!!branch?.branchName && (
            <li>
              <p>{t('bankDetails.branchName')}</p>
              <span data-testid="branch-name-value">{branch.branchName}</span>
            </li>
          )}
          {!!branch?.address?.addressLine1 && (
            <li>
              <p>{t('bankDetails.addressLine1')}</p>
              <span data-testid="address-line-1-value">{branch.address.addressLine1}</span>
            </li>
          )}
          {!!branch?.address?.addressLine2 && (
            <li>
              <p>{t('bankDetails.addressLine2')}</p>
              <span data-testid="address-line-2-value">{branch.address.addressLine2}</span>
            </li>
          )}
          {!!branch?.address?.addressLine3 && (
            <li>
              <p>{t('bankDetails.addressLine3')}</p>
              <span data-testid="address-line-3-value">{branch.address.addressLine3}</span>
            </li>
          )}
          {!!branch?.address?.city && (
            <li>
              <p>{t('bankDetails.city')}</p>
              <span data-testid="city-value">{branch.address.city}</span>
            </li>
          )}
          {!!branch?.address?.postalCode && (
            <li>
              <p>{t('bankDetails.postalCode')}</p>
              <span data-testid="postal-code-value">{branch.address.postalCode}</span>
            </li>
          )}
          {!!branch?.address?.state && (
            <li>
              <p>{t('bankDetails.state')}</p>
              <span data-testid="state-value">{branch.address.state}</span>
            </li>
          )}
          {!!branch?.address?.country && (
            <li>
              <p>{t('bankDetails.country')}</p>
              <span data-testid="validation-country-value">
                {isValidAlpha3CountryCode(branch.address.country)
                  ? getCountryFromAlpha3(branch.address.country)
                  : branch.address.country}
              </span>
            </li>
          )}
        </ul>
      )}
    </Grid>
  )
}
