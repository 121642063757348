import { TableCell, TableRow } from '@material-ui/core'
import { ChangeEvent, EventHandler, KeyboardEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AddToAutoRequestRejectionListModal } from '../addToAutoRequestRejectionListModal/AddToAutoRequestRejectionListModal'
import { RemoveFromAutoRequestRejectionListModal } from '../removeFromAutoRequestRejectionListModal/RemoveFromAutoRequestRejectionListModal'

import { AutoRequestRejectionListItem } from '@percent/admin-dashboard/api/actions/autoRequestRejectionList/autoRequestRejectionList.types'
import { Table } from '@percent/admin-dashboard/common/components'
import { Button, ButtonText, SearchInput, Spacer } from '@percent/lemonade'
import { AutoRequestRejectionListTableProps } from './AutoRequestRejectionListTable.types'

export function AutoRequestRejectionListTable({
  data,
  isLoading,
  nextPage,
  previousPage,
  totalResults,
  errorMessage,
  queryParams,
  setQueryParams,
  refresh
}: Readonly<AutoRequestRejectionListTableProps>) {
  const { t } = useTranslation()

  const [isAddToRejectionListModalOpen, setIsAddToRejectionListModalOpen] = useState(false)
  const [selectedAutoRequestRejectionEntry, setSelectedAutoRequestRejectionEntry] =
    useState<AutoRequestRejectionListItem>()
  const [searchValue, setSearchValue] = useState<string>(queryParams.query || '')

  const cells = [
    { id: 'registryId', isSortable: false },
    { id: 'registry', isSortable: false },
    { id: 'actions', isSortable: false }
  ]

  const handleKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      const searchTerm = (event.target as HTMLInputElement).value

      return event.key === 'Enter'
        ? searchTerm.trim() === ''
          ? setQueryParams({ query: undefined })
          : setQueryParams({
              query: searchTerm
            })
        : null
    },
    [setQueryParams]
  )

  const handleClearValue = useCallback(() => {
    setSearchValue('')
    setQueryParams({ query: undefined })
  }, [setQueryParams])

  const openAddToAutoRequestRejectionListModal = () => setIsAddToRejectionListModalOpen(true)

  const filters = useMemo(
    () => (
      <>
        <SearchInput
          value={searchValue ?? ''}
          handleClearValue={handleClearValue}
          onKeyDown={handleKeyPress}
          onChange={handleKeyChange}
          placeholder={t('table.autoRequestRejectionListSearchPlaceholder')}
        />
        <Spacer size={4} axis="horizontal" />
        <Button onPress={openAddToAutoRequestRejectionListModal}>+ {t('button.addToAutoRequestRejectionList')}</Button>
      </>
    ),
    [searchValue, handleClearValue, handleKeyPress, t]
  )

  return (
    <>
      <Table
        isLoading={isLoading}
        totalResults={errorMessage ? 0 : totalResults || 0}
        data={data}
        previousPage={previousPage}
        nextPage={nextPage}
        columns={cells}
        filtersContent={filters}
        emptyTableText={t('table.emptyAutoRequestRejectionList')}
      >
        {data?.map(row => (
          <TableRow key={row.id}>
            <TableCell>{row.organisationIdInRegistry}</TableCell>
            {/* TODO: to be changed to row.registryName whenever BE is ready */}
            <TableCell>{row.registryId}</TableCell>
            <TableCell>
              <ButtonText icon="reject" onPress={() => setSelectedAutoRequestRejectionEntry(row)}>
                {t('button.remove')}
              </ButtonText>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <AddToAutoRequestRejectionListModal
        isOpen={isAddToRejectionListModalOpen}
        setIsOpen={setIsAddToRejectionListModalOpen}
        refresh={refresh}
      />
      <RemoveFromAutoRequestRejectionListModal
        autoRequestRejectionEntry={selectedAutoRequestRejectionEntry}
        setAutoRequestRejectionEntry={setSelectedAutoRequestRejectionEntry}
        refresh={refresh}
      />
    </>
  )
}
