import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OrganizationDetailsProps } from '../OverviewDetails.types'

import { Badge, Button, ButtonText, CardDashboard, CardContent, Spacer } from '@percent/lemonade'
import { EditOfficialDetailsModal } from './EditOfficialDetailsModal'
import styles from './OfficialDetails.module.scss'
import { EditRegistrationStatusModal } from './EditRegistrationStatusModal'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'

export function OfficialDetails({ organization, refresh }: OrganizationDetailsProps) {
  const [dialogState, setDialogState] = useState(false)
  const [openRegistrationModal, setOpenRegistrationModal] = useState(false)
  const { t } = useTranslation()
  const handleRegistriesDialog = () => {
    setDialogState(prevState => !prevState)
  }

  const handleRegistrationModal = () => {
    setOpenRegistrationModal(prevState => !prevState)
  }
  const { editOfficialDetailsFeatureFlag, editRegistrationStatusFeatureFlag } = useFeatureFlag()

  return (
    <>
      <CardDashboard
        title={t('typography.officialDetails')}
        action={
          editOfficialDetailsFeatureFlag && (
            <Button onPress={handleRegistriesDialog} variant="secondary" data-testid="official-details-edit-button">
              {t('typography.editOfficialDetails')}
            </Button>
          )
        }
      >
        <CardContent title={t('typography.name')} testId="official-details-org-name">
          {organization.name}
        </CardContent>
        <CardContent title={t('typography.registry')} testId="official-details-org-registryName">
          {organization.registryName}
        </CardContent>
        <CardContent title={t('typography.registryId')} testId="official-details-org-registryId">
          {organization.registryId}
        </CardContent>

        <CardContent title={t('typography.registrationStatus')}>
          <div className={styles.registrationStatus}>
            <Badge
              variant={organization.registrationActive ? 'positive' : 'critical'}
              testId="official-details-registration-status"
            >
              {t(organization.registrationActive ? 'status.active' : 'status.inactive')}
            </Badge>
            {editRegistrationStatusFeatureFlag && (
              <ButtonText onPress={handleRegistrationModal} data-testid="official-details-edit-registration-status">
                {t('typography.editStatus')}
              </ButtonText>
            )}
          </div>
        </CardContent>
      </CardDashboard>
      <Spacer size={6} axis="vertical" />
      <EditOfficialDetailsModal
        open={dialogState}
        onClose={handleRegistriesDialog}
        organization={organization}
        refresh={refresh}
      />
      <EditRegistrationStatusModal
        open={openRegistrationModal}
        onClose={handleRegistrationModal}
        organization={organization}
        refresh={refresh}
      />
    </>
  )
}
