import { Box, Divider, TableCell, TableRow, Typography } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MarkAllAsApprovedModal } from '../../markAllAsApprovedModal/MarkAllAsApprovedModal'
import { MarkAllChequesAsSentModal } from '../../markAllChequesAsSentModal/MarkAllChequesAsSentModal'

import { Badge, BadgeVariant, Button, IconRegistry } from '@percent/lemonade'
import { DisbursementDetailsTableHeaderProps, DisbursementStatusAndTotal } from './DisbursementDetailsTable.types'
import style from './DisbursementDetailsTable.module.scss'
import { ErrorInfo, Table } from '@percent/admin-dashboard/common/components'
import { useCSVDownloader, useFoundations } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'

export enum DisbursementBatchActionModalKind {
  MARK_ALL_AS_APPROVED = 'mark_all_as_approved',
  MARK_ALL_AS_SENT = 'mark_all_as_sent'
}

const variantFromStatus: Record<DisbursementStatusAndTotal, BadgeVariant> = {
  total: 'default',
  approvalRequired: 'informative',
  approved: 'default',
  inTransit: 'informative',
  paid: 'positive',
  cancelled: 'critical'
} as const
const iconFromStatus: Record<DisbursementStatusAndTotal, keyof typeof IconRegistry> = {
  total: 'claims',
  approvalRequired: 'clock',
  approved: 'check-badge',
  inTransit: 'check-badge',
  paid: 'approve',
  cancelled: 'reject'
} as const

function StatusBadge({ status, children }: React.PropsWithChildren<{ status: DisbursementStatusAndTotal }>) {
  return (
    <Badge variant={variantFromStatus[status]} icon={iconFromStatus[status]} testId={status}>
      {children}
    </Badge>
  )
}

export function DisbursementDetailsTableHeader({
  disbursementBatchId,
  disbursementBatchEndDate,
  disbursementBatchFoundationId,
  summary,
  detailedCounts,
  refreshData
}: DisbursementDetailsTableHeaderProps) {
  const { t } = useTranslation()
  const {
    reportingService: { getDisbursementBatchPaymentReport }
  } = useServices()

  const { foundations } = useFoundations()

  const [activeActionModal, setActiveActionModal] = useState<DisbursementBatchActionModalKind | undefined>(undefined)

  const closeModal = () => {
    setActiveActionModal(undefined)
  }

  const fileName = useMemo(() => {
    return `${
      foundations ? foundations[disbursementBatchFoundationId]?.countryCode : disbursementBatchFoundationId
    }_payments_to_${dayJS(disbursementBatchEndDate).format('YYYY-MM-DD')}_on_${dayJS().format(
      'YYYY-MM-DD_HH-mm-ss-SSS'
    )}`
  }, [disbursementBatchEndDate, disbursementBatchFoundationId, foundations])

  const [{ loading, rawError }, { downloadCSV }] = useCSVDownloader({
    service: getDisbursementBatchPaymentReport(disbursementBatchId),
    fileName,
    successMessage: t('toast.downloadBatchPaymentFileSuccess'),
    errorMessage: t('toast.downloadBatchPaymentFileError')
  })

  const handleReportDownload = async () => {
    if (downloadCSV) {
      await downloadCSV(false)
    }
  }

  const errors = useMemo(
    () =>
      rawError ? (
        <Box className={style.filtersAndActionsWrapper}>
          <ErrorInfo
            header={`${t('typography.disbursementBatchExportErrorTitle')} ${rawError.message}`}
            reasons={rawError.reasons}
            footer={t('typography.disbursementBatchExportErrorInstruction')}
          />
        </Box>
      ) : null,
    [t, rawError]
  )

  const nameFromStatus: Record<DisbursementStatusAndTotal, string> = {
    total: t('typography.totalDisbursements'),
    approvalRequired: t('status.pendingApproval'),
    approved: t('status.pendingPayment'),
    inTransit: t('status.sent'),
    paid: t('status.paid'),
    cancelled: t('status.removed')
  }

  const statusActions = (status: DisbursementStatusAndTotal, count: number) => {
    const actions: JSX.Element[] = []

    if (status === 'approvalRequired' && count > 0) {
      actions.push(
        <>
          <Button
            startIcon="check-badge"
            variant="tertiary"
            size="small"
            onPress={() => setActiveActionModal(DisbursementBatchActionModalKind.MARK_ALL_AS_APPROVED)}
            data-testId="markPendingAsApprovedBtn"
          >
            {t('button.markPendingAsApproved')}
          </Button>
          {activeActionModal && activeActionModal === DisbursementBatchActionModalKind.MARK_ALL_AS_APPROVED ? (
            <MarkAllAsApprovedModal
              isOpen={!!activeActionModal}
              onClose={closeModal}
              disbursementBatchId={disbursementBatchId}
              numberOfDisbursements={count}
              refreshData={refreshData}
            />
          ) : null}
        </>
      )
    }

    const approvedBankTransfersCount = detailedCounts.find(
      ({ type, status: detailedCountStatus }) =>
        type === 'bank_transfer' && (detailedCountStatus === 'approved' || detailedCountStatus === 'approvalRequired')
    )?.count

    if (status === 'approved' && approvedBankTransfersCount) {
      actions.push(
        <Button
          startIcon="download"
          variant="tertiary"
          size="small"
          onPress={handleReportDownload}
          loading={loading}
          disabled={loading}
          data-testid="downloadBatchPaymentFileBtn"
        >
          {t('button.downloadBatchPaymentFile')}
        </Button>
      )
    }

    const approvedChequesCount = detailedCounts.find(
      ({ type, status: detailedCountStatus }) => type === 'cheque' && detailedCountStatus === 'approved'
    )?.count

    if (status === 'approved' && approvedChequesCount) {
      actions.push(
        <>
          <Button
            startIcon="check-badge"
            variant="tertiary"
            size="small"
            onPress={() => setActiveActionModal(DisbursementBatchActionModalKind.MARK_ALL_AS_SENT)}
            data-testId="markApprovedChequesAsSentBtn"
          >
            {t('button.markChequesAsSent')}
          </Button>
          {activeActionModal && activeActionModal === DisbursementBatchActionModalKind.MARK_ALL_AS_SENT ? (
            <MarkAllChequesAsSentModal
              isOpen={!!activeActionModal}
              onClose={closeModal}
              disbursementBatchId={disbursementBatchId}
              numberOfDisbursements={approvedChequesCount}
              refreshData={refreshData}
            />
          ) : null}
        </>
      )
    }

    return actions.length ? actions : null
  }

  const cells = [
    { id: 'status', isSortable: false, props: { width: '230px' } },
    { id: 'count', isSortable: false }
  ]

  return (
    <>
      <Box className={style.headerWrapper}>
        <Typography variant="h6">{t('menu.disbursements')}</Typography>

        <Divider orientation="horizontal" className={style.divider} />
      </Box>

      <Table
        isLoading={false}
        data={summary}
        noFooter
        columns={cells}
        totalResults={5}
        nextPage={() => {}}
        previousPage={() => {}}
        emptyTableText=""
        className={style.table}
        testId="disbursementsTableHeader"
        filtersContent={errors}
      >
        {summary.map(({ status, count }) => {
          const actions = statusActions(status, count)

          return (
            <TableRow key={status}>
              <TableCell>{nameFromStatus[status]}</TableCell>
              <TableCell className={style.cellGrow}>
                <StatusBadge status={status}>{count}</StatusBadge>
              </TableCell>
              {actions && (
                <TableCell className={style.actionsSection} align="right">
                  {actions}
                </TableCell>
              )}
            </TableRow>
          )
        })}
      </Table>

      <Divider orientation="horizontal" className={style.divider} />
    </>
  )
}
