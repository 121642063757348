import {
  cloneElement,
  forwardRef,
  HTMLProps,
  isValidElement,
  useMemo,
} from 'react'
import { TooltipProps } from './tooltip.types'
import styled from 'styled-components'
import { FloatingPortal } from '@floating-ui/react-dom-interactions'
import { TooltipContext } from './context/tooltip'
import { mergeRefs } from '../../util'
import { useTooltip } from './hooks/useTooltip'
import { useTooltipState } from './hooks/useTooltipState'

const DEFAULT_LINE_CLAMP = 8

const TooltipContentText = styled.div<{
  $isOnLightBg?: boolean
  $lineClamp?: number
}>`
  ${({ theme, $isOnLightBg, $lineClamp }) => `
  display: ${$lineClamp ? '-webkit-box' : 'block'};
  color: ${$isOnLightBg ? theme.colors.black : theme.colors.white};
  font-size: ${theme.fontSizes.bodySmall};
  width: max-content;
  max-width: 100%;
  max-height: 100%;
  ${
    $lineClamp
      ? `
  -webkit-line-clamp: ${$lineClamp};
  -webkit-box-orient: vertical;
  `
      : ''
  }
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  line-height: 16px;
  letter-spacing: 0.25px;
  padding: 1px; // Fixes letters with descenders being clipped
  `}
`

const TooltipContentContainer = styled.div<{
  $lightBg?: boolean
  $shadowSize?: 'small' | 'medium'
}>`
  ${({ theme, $lightBg, $shadowSize }) => `
    display: inline-block;
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    padding: ${theme.sizes[2]} ${theme.sizes[3]};
    border-radius: ${theme.borderRadii.medium};
    background-color: ${$lightBg ? theme.colors.white : theme.colors.black};
    opacity: 1;
    max-width: 320px;
    box-sizing: border-box;
    box-shadow: ${
      $shadowSize === 'medium'
        ? '0px 8px 32px 0px rgba(13, 13, 13, 0.16)'
        : '0px 2px 16px 0px rgba(13, 13, 13, 0.08)'
    } ;

    @media (max-width: 480px) {
      max-width: 280px;
      font-size: ${theme.fontSizes.caption};
    }
  `}
`

export function Tooltip({
  children,
  content,
  lightBg,
  lineClamp,
  shadowSize = 'small',
  ...options
}: TooltipProps) {
  const tooltip = useTooltip(options)

  return (
    <TooltipContext.Provider value={tooltip}>
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipContent
        lineClamp={lineClamp}
        lightBg={lightBg}
        shadowSize={shadowSize}
      >
        {content}
      </TooltipContent>
    </TooltipContext.Provider>
  )
}

export const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const state = useTooltipState()

    // eslint-disable-next-line
    const childrenRef = (children as any)?.ref
    const ref = useMemo(
      () => mergeRefs([state.reference, propRef, childrenRef]),
      [state.reference, propRef, childrenRef]
    )

    if (isValidElement(children)) {
      return cloneElement(
        children,
        state.getReferenceProps({
          ref,
          ...props,
          ...children.props,
        })
      )
    }

    return (
      <div ref={ref} {...state.getReferenceProps(props)}>
        {children}
      </div>
    )
  }
)

export const TooltipContent = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement> & {
    lightBg?: boolean
    lineClamp?: number
    shadowSize?: 'small' | 'medium'
  }
>(function TooltipContent(props, propRef) {
  const state = useTooltipState()

  const lineClamp = props.lineClamp ?? DEFAULT_LINE_CLAMP

  const ref = useMemo(
    () => mergeRefs([state.floating, propRef]),
    [state.floating, propRef]
  )

  return (
    <FloatingPortal>
      {state.open && (
        <div
          ref={ref}
          style={{
            zIndex: 2147483647,
            position: state.strategy,
            top: state.y ?? 0,
            left: state.x ?? 0,
            visibility: state.x == null ? 'hidden' : 'visible',
            ...props.style,
          }}
          {...state.getFloatingProps(props)}
        >
          <TooltipContentContainer
            $lightBg={props.lightBg}
            $shadowSize={props.shadowSize}
          >
            <TooltipContentText
              $isOnLightBg={props.lightBg}
              $lineClamp={lineClamp}
            >
              {props.children}
            </TooltipContentText>
          </TooltipContentContainer>
        </div>
      )}
    </FloatingPortal>
  )
})
