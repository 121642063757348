import React from 'react'
import styled, { CSSProp } from 'styled-components'
import { AvatarProps, StyledAvatarProps, SizeVariant } from './avatar.types'
import { BorderRadius } from '../../theme/theme'
import { styledVariant } from '../../util'
import { Theme } from '../../theme'

const BorderSizeMap: Record<SizeVariant, string> = {
  xsmall: '.0625rem',
  small: '.0625rem',
  medium: '.0625rem',
  large: '.125rem',
  xlarge: '.125rem',
}

const BorderRadiiMap: Record<SizeVariant, BorderRadius> = {
  xsmall: 'small',
  small: 'small',
  medium: 'medium',
  large: 'medium',
  xlarge: 'medium',
}

const sizes: Record<
  SizeVariant,
  (obj: { theme: Theme; $size: SizeVariant }) => CSSProp
> = {
  xsmall: ({ theme, $size }) => `
    width: ${theme.sizes[4]};
    min-width: ${theme.sizes[4]};
    height: ${theme.sizes[4]};
    min-height: ${theme.sizes[4]};
    font-size: ${theme.fontSizes.caption};
    border-radius: ${theme.borderRadii[BorderRadiiMap[$size]]};
    `,
  small: ({ theme, $size }) => `
    width: ${theme.sizes[6]};
    min-width: ${theme.sizes[6]};
    height: ${theme.sizes[6]};
    min-height: ${theme.sizes[6]};
    font-size: ${theme.fontSizes.bodySmall};
    border-radius: ${theme.borderRadii[BorderRadiiMap[$size]]};
    `,
  medium: ({ theme, $size }) => `
    width: ${theme.sizes[12]};
    min-width: ${theme.sizes[12]};
    height: ${theme.sizes[12]};
    min-height: ${theme.sizes[12]};
    font-size: ${theme.fontSizes.body};
    border-radius: ${theme.borderRadii[BorderRadiiMap[$size]]};
    `,
  large: ({ theme, $size }) => `
    width: ${theme.sizes[16]};
    min-width: ${theme.sizes[16]};
    height: ${theme.sizes[16]};
    min-height: ${theme.sizes[16]};
    font-size: ${theme.fontSizes.h2};
    border-radius: ${theme.borderRadii[BorderRadiiMap[$size]]};
    `,
  xlarge: ({ theme, $size }) => `
    width: ${theme.sizes[24]};
    min-width: ${theme.sizes[24]};
    height: ${theme.sizes[24]};
    min-height: ${theme.sizes[24]};
    font-size: ${theme.fontSizes.xl};
    border-radius: ${theme.borderRadii[BorderRadiiMap[$size]]};
  `,
}

export const StyledAvatar = styled.div<StyledAvatarProps>`
  ${styledVariant('$size', sizes)};

  ${({ theme, $size, $image, $border, $backgroundSizeMode, $transparent }) =>
    `
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    background-image: url(${$image});
    background-size: ${$backgroundSizeMode};
    background-color:  ${$transparent ? 'transparent' : theme.colors.gray80};
    background-position: center;
    background-repeat: no-repeat;

    ${
      $border &&
      $image &&
      $size &&
      `
    border: ${BorderSizeMap[$size]} solid ${theme.colors.white};
    outline: ${BorderSizeMap[$size]} solid white;
    outline-offset: -${BorderSizeMap[$size]};
    border-radius: ${theme.borderRadii[BorderRadiiMap[$size]]};
    `
    }
    `}
`

export const StyledBadge = styled.div<{ $badge: AvatarProps['badge'] }>`
  ${({ theme, $badge }) =>
    `
    width: 32px;
    height: 32px;
    position: absolute;
    bottom: -8px;
    right: -8px;

    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;


    background-image: url(${$badge});
    background-color:  ${theme.colors.gray80};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    border-radius: 50%;
    border: 2px solid ${theme.colors.white};
  `}
`

export const StyledInitial = styled.span`
  ${({ theme }) =>
    `
    color: ${theme.colors.gray800};
    font-weight: ${theme.fontWeights.medium};
    `}
`

export const Avatar = ({
  image,
  size = 'medium',
  alt,
  border,
  backgroundSizeMode = 'cover',
  badge,
  customInitials,
  transparent,
}: AvatarProps) => {
  return (
    <StyledAvatar
      $image={image}
      $size={size}
      $border={border}
      $backgroundSizeMode={backgroundSizeMode}
      $transparent={transparent}
      data-testid="avatar"
    >
      {!image && (
        <StyledInitial>
          {customInitials || alt?.charAt(0).toUpperCase()}
        </StyledInitial>
      )}
      {badge && <StyledBadge $badge={badge} />}
    </StyledAvatar>
  )
}
