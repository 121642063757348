import { GroupedFraudChecks } from './getGroupedFraudChecks'

type DomainFraudChecks = GroupedFraudChecks['domains'][number]['fraudChecks']

export const getFailedDomainAutoChecks = (fraudChecks: DomainFraudChecks | undefined) => {
  const advancedChecks = fraudChecks?.advanced.filter(c => c.data.flags.length > 0).flatMap(c => c.data.flags)
  const basicChecks = fraudChecks?.basic
    .filter(c => {
      switch (c.checkName) {
        case 'can_website_be_opened':
          return !c.data.canBeOpened
        case 'disallowed_domain_list':
          return c.data.isDisallowed
        case 'has_website_hosting_provider_subdomain':
          return c.data.hasWebsiteHostingProviderSubdomain
        case 'has_website_placeholder_content':
          return c.data.hasWebsitePlaceholderContent
        case 'has_website_redirect':
          return c.data.hasWebsiteRedirect
        case 'is_ssl_certificate_valid':
          return !c.data.isSslCertificateValid
        case 'is_website_empty':
          return c.data.isWebsiteEmpty
        default:
          return false
      }
    })
    .map(c => c.checkName)

  return {
    advanced: advancedChecks,
    basic: basicChecks,
    total: (advancedChecks?.length ?? 0) + (basicChecks?.length ?? 0)
  }
}

export type FailedDomainAutoChecks = ReturnType<typeof getFailedDomainAutoChecks>
