import {
  AdvancedDomainFraudCheck,
  ApplicationFraudCheck,
  BasicDomainFraudCheck,
  FraudCheck,
  FraudCheckResultsResponse
} from '@percent/admin-dashboard/api/types'

type BasicDomainCheck = BasicDomainFraudCheck['data'] extends infer R
  ? R extends { checkName: infer N; analysedDomains: (infer AD)[] }
    ? { checkName: N; data: AD }
    : never
  : never

type AdvancedDomainCheck = AdvancedDomainFraudCheck['data'] extends infer R
  ? R extends { checkName: infer N; analysedDomains: (infer AD)[] }
    ? { checkName: N; data: AD }
    : never
  : never

export type GroupedFraudChecks = {
  agent: (FraudCheck & ApplicationFraudCheck)[]
  domains: {
    name: string
    fraudChecks: {
      advanced: AdvancedDomainCheck[]
      basic: BasicDomainCheck[]
    }
  }[]
}

export const getGroupedFraudChecks = (fraudCheckResults?: FraudCheckResultsResponse) => {
  return fraudCheckResults?.checkResults.reduce<GroupedFraudChecks>(
    (acc, curr) => {
      if (curr.type === 'application_check') acc.agent.push(curr)
      else if (curr.type === 'advanced_domain_check') {
        curr.data.analysedDomains.forEach(ad => {
          const domainItem = acc.domains.find(d => d.name === ad.domain)
          const { checkName } = curr.data
          // NOTE: it has to match as it's static shape returned from BE. Treat this const as type guard
          const advancedDomainCheck = { checkName, data: ad } as AdvancedDomainCheck

          if (domainItem) {
            return domainItem.fraudChecks.advanced.push(advancedDomainCheck)
          }

          return acc.domains.push({
            name: ad.domain,
            fraudChecks: {
              advanced: [advancedDomainCheck],
              basic: []
            }
          })
        })
      } else if (curr.type === 'basic_domain_check') {
        curr.data.analysedDomains.forEach(ad => {
          const domainItem = acc.domains.find(d => d.name === ad.domain)
          const { checkName } = curr.data
          // NOTE: it has to match as it's static shape returned from BE. Treat this const as type guard
          const basicDomainCheck = { checkName, data: ad } as BasicDomainCheck

          if (domainItem) {
            return domainItem.fraudChecks.basic.push(basicDomainCheck)
          }

          return acc.domains.push({
            name: ad.domain,
            fraudChecks: {
              advanced: [],
              basic: [basicDomainCheck]
            }
          })
        })
      }

      return acc
    },
    { agent: [], domains: [] }
  )
}
