import { Icon } from '../icon'
import styled, { css } from 'styled-components'
import { Listbox } from '@headlessui/react'
import { FakeCheckbox } from '../fake-checkbox'
import { FilterListProps } from './filter-list.types'
import { Loader } from '../loader'
import { Tooltip } from '../tooltip'

export const ListboxWrapper = styled.div`
  position: relative;
`

export const StyledButton = styled(Listbox.Button)`
  ${({ theme }) => css`
    position: relative;
    margin: 0;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    gap: ${theme.sizes.xxs};
    padding: ${theme.sizes.xxs} ${theme.sizes.xs};
    border-radius: ${theme.borderRadii.medium};
    font-size: ${theme.fontSizes.bodySmall};
    font-weight: ${theme.fontWeights.medium};
    white-space: nowrap;
    :active {
      background: ${theme.colors.info30};
      color: ${theme.colors.primary400};

      > svg {
        > path {
          fill: ${theme.colors.primary400};
        }
      }
    }

    :focus {
      outline: 1px solid ${theme.colors.primary400};
    }

    :hover {
      background: ${theme.colors.gray70};
    }

    :disabled {
      pointer-events: all !important;
      background: none;
      color: ${theme.colors.gray100};
      > svg {
        > path {
          fill: ${theme.colors.gray100};
        }
      }
    }
  `}
`

export const StyledBadge = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.buttonText};
    padding: 1px ${theme.sizes.xxs};
    background: ${theme.colors.primary400};
    color: ${theme.colors.white};
    border-radius: 10px;
  `}
`
export const FilterOptions = styled(Listbox.Options)`
  ${({ theme }) => css`
    z-index: 10;
    list-style-type: none;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: fit-content;
    background: ${theme.colors.white};
    gap: ${theme.sizes.xxs};
    padding: ${theme.sizes.xs};
    margin: 0;
    box-shadow: ${theme.boxShadows.medium};
    border-radius: ${theme.borderRadii.medium};
    max-height: 300px;
    min-width: 100px;
    overflow-y: auto;

    li {
      font-size: ${theme.fontSizes.buttonText};
      font-weight: ${theme.fontWeights.medium};
      border: none;
      background: none;
      padding: ${theme.sizes.xxs} ${theme.sizes.xs};
      margin: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: ${theme.sizes.xs};
      cursor: pointer;
      border-radius: ${theme.borderRadii.medium};
      border: 1px solid transparent;

      &:active,
      &[data-headlessui-state='active'],
      &[data-headlessui-state='active selected'] {
        border: 1px solid ${theme.colors.primary400};
        background: ${theme.colors.gray70};
      }

      :hover {
        background: ${theme.colors.gray70};
        border: 1px solid transparent;
      }
    }
  `}
`

const DisabledButtonTooltipOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

export const FilterList = ({
  label,
  options,
  selectedValues,
  onChange,
  isLoading = false,
  testId,
  badgeTestId,
  isDisabled,
  tooltipContent,
}: FilterListProps) => (
  <Listbox
    value={selectedValues}
    onChange={onChange}
    multiple
    data-testid={testId}
    disabled={isDisabled}
  >
    {({ open }) => (
      <ListboxWrapper>
        <StyledButton>
          <span>{label}</span>
          {selectedValues.length > 0 && (
            <StyledBadge data-testid={badgeTestId || 'filter-list-badge'}>
              {selectedValues.length}
            </StyledBadge>
          )}
          <Icon
            name={open ? 'chevron-up' : 'chevron-down'}
            size={3}
            color="black"
          />
          {isDisabled && tooltipContent && (
            <Tooltip content={tooltipContent}>
              <DisabledButtonTooltipOverlay data-testid="tooltip-overlay" />
            </Tooltip>
          )}
        </StyledButton>
        <FilterOptions>
          {isLoading && <Loader />}
          {!isLoading &&
            options.map(({ label, value }) => (
              <Listbox.Option key={value} value={value} data-testid={value}>
                <FakeCheckbox
                  isSelected={selectedValues.includes(value)}
                  selectedTestId={`${value}-fake-checkbox-selected`}
                />
                <span>{label}</span>
              </Listbox.Option>
            ))}
        </FilterOptions>
      </ListboxWrapper>
    )}
  </Listbox>
)
