import { Table as MantineTable } from '@mantine/core'
import { useTableContext } from '../context/table-provider'
import styled from 'styled-components'
import { Icon } from '@percent/lemonade'

const TableHeadWrapper = styled.div<{ isSortable?: boolean }>`
  display: inline-flex;
  width: fit-content;
  gap: 8px;
  align-items: center;
  ${(p) => p.isSortable && 'cursor: pointer;'}
`

export function TableHeader() {
  const {
    columns,
    tableHeadTestId,
    handleRequestSort,
    sortDirection,
    sortByColumn,
  } = useTableContext()

  return (
    <MantineTable.Thead data-testid={tableHeadTestId}>
      <MantineTable.Tr>
        {columns.map((col) => (
          <MantineTable.Th key={col.id} style={{ ...col.props }}>
            <TableHeadWrapper
              isSortable={col.isSortable}
              onClick={() => handleRequestSort?.(col.id)}
            >
              {col.content || col.id}
              {handleRequestSort &&
                col.isSortable &&
                sortByColumn !== col.id && (
                  <Icon
                    name="sort"
                    size={6}
                    customTestId={`table-${col.id}-default-sort-icon`}
                  />
                )}
              {sortByColumn === col.id &&
                (sortDirection === 'ASC' ? (
                  <Icon
                    name="sort-asc"
                    size={6}
                    customTestId={`table-${col.id}-asc-sort-icon`}
                  />
                ) : (
                  <Icon
                    name="sort-desc"
                    size={6}
                    customTestId={`table-${col.id}-desc-sort-icon`}
                  />
                ))}
            </TableHeadWrapper>
          </MantineTable.Th>
        ))}
      </MantineTable.Tr>
    </MantineTable.Thead>
  )
}
