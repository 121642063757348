export enum PartnerScopes {
  Donation = 'donation',
  Organisation = 'organisation',
  DonationSession = 'donation_session',
  ValidationRequest = 'validation_request',
  WebhookSubscription = 'webhook_subscription',
  AgentVerification = 'agent_verification',
  ActivityCheck = 'activity_check',
  MonitoringSubscription = 'monitoring_subscription',
  EligibilitySubscription = 'eligibility_subscription',
  ValidationInvite = 'validation_invite',
  CodeRedemption = 'discount_redemption',
  SingleSignOn = 'single_sign_on',
  SingleSignOnNotStrict = 'single_sign_on_not_strict',
  SingleValidationSubmission = 'single_validation_submission_api',
  DonationLink = 'donation_link',
  DonationMatching = 'donation_matching',
  WorkplaceGiving = 'workplace_giving',
  DonationWallet = 'donation_wallet',
  Volunteering = 'volunteering',
  Slack = 'slack',
  Gamification = 'gamification',
  ScimApi = 'scim_api',
  Grants = 'grants'
}
