import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import axios from 'axios'

import {
  BankDataView,
  BankDetail,
  BankDetailsValidationResult,
  BankDetailsView,
  useAddBankDetailsForm,
  WireTransferType
} from '@percent/domain/bank-details'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { AcknowledgeModal, ActionModal, Grid, Modal, ModalContent, ModalHeader, ModalTitle } from '@percent/lemonade'
import { Card } from '@percent/admin-dashboard/common/components/card/Card'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { BankDetailsValidationPayload } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'

type AddBankDetailsModalProps = {
  open: boolean
  onClose: VoidFunction
  refresh: VoidFunction
  bankDetailsData: BankDetail
}

const StyledGrid = styled(Grid)`
  padding: var(--grid-col-padding);
`

const StyledGridCol = styled(Grid.Col)`
  padding: 0;
`

export function AddBankDetailsModal({ open, onClose, refresh, bankDetailsData }: AddBankDetailsModalProps) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { bankDetailsService } = useServices()
  const [selectedFile, setSelectedFile] = useState<File>()
  const [isFileValid, setIsFileValid] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [{ isLoading, success }, { apiFunc }] = useMutation(bankDetailsService.postBankDetails, undefined, err => {
    setError(err.message)
  })
  const [isValidated, setIsValidated] = useState(false)
  const [bankData, setBankData] = useState<BankDetailsValidationResult | undefined>(undefined)
  const { bankDetailsValidationFeatureFlag, bankDetailsValidationWithDocumentFeatureFlag } = useFeatureFlag()

  const translations = {
    accountHolderName: t('typography.accountHolderName'),
    accountHolderPlaceholder: t('dialog.addBankDetails.form.accountHolderName.placeholder'),
    accountHolderDescription: t('dialog.addBankDetails.form.accountHolderName.description'),
    helperBankAccountText: t('typography.helperBankAccountText'),
    helperBankAccountLinkText: t('typography.helperBankAccountLinkText'),
    uploadDocumentPlaceholder: t('typography.uploadDocumentPlaceholder'),
    proofBankAccount: t('typography.bankAccountProof'),
    uploadDocument: t('button.uploadDocument'),
    submitBankDocuments: t('button.submitBankDocuments')
  }

  const bankDataTranslations = useMemo(
    () => ({
      bankName: t('bankDetails.bankName'),
      branchName: t('bankDetails.branchName'),
      addressLine1: t('bankDetails.addressLine1'),
      addressLine2: t('bankDetails.addressLine2'),
      addressLine3: t('bankDetails.addressLine3'),
      state: t('bankDetails.state'),
      city: t('bankDetails.city'),
      postalCode: t('bankDetails.postalCode'),
      country: t('bankDetails.country'),
      title: t('bankDetails.validation.title'),
      description: t('bankDetails.validation.description'),
      foundBankData: t('bankDetails.validation.foundBankData'),
      noDataFound: t('bankDetails.validation.noDataFound'),
      invalidDataTitle: t('bankDetails.validation.invalidDataTitle'),
      invalidDataDescription: t('bankDetails.validation.invalidDataDescription'),
      unknownValidationError: t('bankDetails.validation.unknownError'),
      validDataMessage: t('bankDetails.validation.validDataMessage'),
      validDataTitle: t('bankDetails.validation.validDataTitle'),
      proofOfBankAccountLabel: t('typography.bankAccountProof')
    }),
    [t]
  )

  const { formik, fields } = useAddBankDetailsForm({
    bankDetailsData,
    t,
    apiFunc,
    selectedFile,
    organizationId: id
  })

  const { handleSubmit, isValid, dirty, resetForm, values, setFieldError, setFieldTouched } = formik

  useEffect(() => {
    if (bankDetailsValidationFeatureFlag) {
      setIsValidated(false)
    }
  }, [values, setIsValidated, bankDetailsValidationFeatureFlag])

  const handleCloseModal = () => {
    onClose()
    refresh()
  }

  const [{ isLoading: isFetchBankDataLoading }, { apiFunc: fetchBankData }] = useMutation(
    bankDetailsService.fetchBankData,
    async response => {
      setBankData(response.data.data)
      setIsValidated(true)
    },
    async () => {
      setBankData({ success: false, validationErrors: [] })
      setIsValidated(true)
    }
  )

  const [{ isLoading: isValidationWithDocumentLoading }, { apiFunc: validateWithDocument }] = useMutation(
    bankDetailsService.validateBankDetailsWithDocument,
    async response => {
      setBankData(response.data.data)
      setIsValidated(true)
    },
    async (err: Error) => {
      if (axios.isAxiosError(err) && err.response?.status === 400) {
        setBankData({
          success: false,
          validationErrors: [
            {
              field: 'uploadDocument',
              message: (err.response?.data as unknown as { error: { message: string } }).error.message
            }
          ]
        })
        setIsFileValid(false)
      } else {
        setBankData({ success: false, validationErrors: [] })
      }
      setIsValidated(true)
    }
  )

  const isValidationLoading = isFetchBankDataLoading || isValidationWithDocumentLoading

  const handleSubmitFormWithValidation = (event?: React.FormEvent<HTMLFormElement>) => {
    if (!isValidated && bankDetailsData.addBankDetailsFormLabels) {
      const { holderName, ...payload } = values

      let paymentDetails: BankDetailsValidationPayload['paymentDetails']

      switch (bankDetailsData.addBankDetailsFormLabels.type) {
        case WireTransferType.IBAN:
          paymentDetails = {
            type: WireTransferType.IBAN,
            iban: payload.iban!,
            bic: payload.bic!
          }
          break
        case WireTransferType.DOMESTIC_WITH_BIC:
          paymentDetails = {
            type: WireTransferType.DOMESTIC_WITH_BIC,
            accountNumber: payload.accountNumber!,
            bic: payload.bic!,
            bankCode: payload.bankCode,
            branchCode: payload.branchCode
          }
          break
        case WireTransferType.DOMESTIC:
          paymentDetails = {
            type: WireTransferType.DOMESTIC,
            accountNumber: payload.accountNumber!,
            bankCode: payload.bankCode!
          }
          break
        default:
          throw new Error('Invalid payment details type')
      }

      if (bankDetailsValidationWithDocumentFeatureFlag) {
        validateWithDocument({
          organisationId: id,
          file: selectedFile!,
          bankAccountDetails: {
            holderName: holderName!,
            paymentDetails
          }
        })
      } else {
        fetchBankData({
          organisationId: id,
          holderName: holderName!,
          paymentDetails
        })
      }
    } else {
      handleSubmit(event)
    }
  }

  const successModal = success && (
    <AcknowledgeModal
      result="positive"
      title={t('dialog.proofBankSuccessModal.title')}
      description={t('dialog.proofBankSuccessModal.subTitle')}
      buttonText={t('button.done')}
      handleClose={handleCloseModal}
      viewTestId="success-modal"
      buttonTestId="close-acknowledge-modal"
    />
  )
  const errorModal = error && (
    <AcknowledgeModal
      result="negative"
      title={t('dialog.proofBankErrorModal.title')}
      description={t('dialog.proofBankErrorModal.subTitle')}
      buttonText={t('button.done')}
      handleClose={() => setError(undefined)}
      viewTestId="error-modal"
      buttonTestId="close-acknowledge-modal"
    />
  )

  const areBankDetailsChanged =
    bankDetailsData?.addBankDetailsFormLabels && isValidated && isValid && dirty && isFileValid
  const isFileUploadedWithoutLabels = !bankDetailsData?.addBankDetailsFormLabels && selectedFile && isFileValid

  const areBankDetailsChangedAndNotValidated =
    !isValidationLoading &&
    !isValidated &&
    bankDetailsData?.addBankDetailsFormLabels &&
    isValid &&
    dirty &&
    selectedFile &&
    isFileValid

  const isDisabledWithValidation = !(
    (!isLoading && areBankDetailsChanged && isValidated) ||
    (!isLoading && isFileUploadedWithoutLabels && isValidated) ||
    (!isLoading && areBankDetailsChangedAndNotValidated)
  )

  const isDisabled = !(
    (!isLoading && isFileUploadedWithoutLabels) ||
    (!isLoading && areBankDetailsChangedAndNotValidated)
  )
  const actionModal = (
    <ActionModal
      title={t('typography.bankDetails')}
      primaryButtonText={
        bankDetailsValidationFeatureFlag && !isValidated
          ? t('button.validateBankData')
          : t('button.submitBankDocuments')
      }
      secondaryButtonText={t('button.cancel')}
      type="submit"
      variant={
        (bankDetailsData?.addBankDetailsFormLabels && isValid && dirty) ||
        (!bankDetailsData?.addBankDetailsFormLabels && selectedFile && isFileValid)
          ? 'primary'
          : 'secondary'
      }
      disabled={bankDetailsValidationFeatureFlag ? isDisabledWithValidation : isDisabled}
      loading={isLoading}
      handleClose={() => {
        onClose()
        resetForm()
      }}
      handleSubmit={bankDetailsValidationFeatureFlag ? handleSubmitFormWithValidation : handleSubmit}
      primaryBtnTestId="add-bank-details-submit-button"
      secondaryBtnTestId="add-bank-details-cancel-button"
      maxHeight="auto"
    >
      <div
        style={{
          overflowY: 'auto',
          maxHeight: '32rem'
        }}
      >
        <Card size="auto">
          <BankDetailsView
            setSelectedFile={setSelectedFile}
            buttonFlag={false}
            uploadButtonFlag
            bankDetailsData={bankDetailsData}
            disableUploadBtn={false}
            disabledField={false}
            selectedFile={selectedFile}
            isPostLoading={isLoading}
            fileFieldText={selectedFile?.name}
            translations={translations}
            formik={formik}
            fields={fields}
            isAdminDashboard
            bankData={bankData}
            isValidated={isValidated}
            onFileChange={() => {
              setIsValidated(false)
              setIsFileValid(false)
            }}
            onValidationFailed={() => setIsFileValid(false)}
            onValidationSuccess={() => setIsFileValid(true)}
          />
        </Card>
      </div>
    </ActionModal>
  )

  const modalWithValidation = (
    <StyledGrid gutter="xs">
      <StyledGridCol span={4}>
        <ModalHeader>
          <ModalTitle>{t('bankDetails.validation.title')}</ModalTitle>
        </ModalHeader>
        <ModalContent maxHeight="auto">
          <BankDataView
            setFieldError={setFieldError}
            setFieldTouched={setFieldTouched}
            bankData={bankData}
            isValidated={isValidated}
            fields={fields}
            translations={bankDataTranslations}
          />
        </ModalContent>
      </StyledGridCol>
      <StyledGridCol span={8}>{actionModal}</StyledGridCol>
    </StyledGrid>
  )

  const modalSizeWithValidation = successModal || errorModal ? 700 : 1200
  const modalSizeWithoutValidation = successModal || errorModal ? 560 : 1000

  return (
    <Modal
      width={bankDetailsValidationFeatureFlag ? modalSizeWithValidation : modalSizeWithoutValidation}
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="brand-details-form-modal"
    >
      {successModal || errorModal || (bankDetailsValidationFeatureFlag ? modalWithValidation : actionModal)}
    </Modal>
  )
}
