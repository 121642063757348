import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Box } from '@mui/material'

import { Button, ButtonText, CardContent, CardDashboard, Loader, Spacer } from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useMutation, useQuery } from '@percent/admin-dashboard/common/hooks'
import { ErrorView } from '@percent/admin-dashboard/common/components'
import { formatMoney } from '@percent/utility'
import { ReactComponent as DonationsIcon } from '@percent/admin-dashboard/common/assets/images/donations_icon.svg'
import { ReassignDonationModal } from '@percent/admin-dashboard/common/components/reassignDonationModal/ReassignDonationModal'
import { DonationReassignmentProps } from '@percent/admin-dashboard/common/components/reassignDonationModal/ReassignDonationModal.types'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import styles from './Donations.module.scss'

export function Donations({ organisationId, organisationName }: { organisationId: string; organisationName: string }) {
  const {
    t,
    i18n: { language: locale }
  } = useTranslation()
  const { donationsService } = useServices()
  const { push } = useHistory()
  const { reassignDonationFeatureFlag } = useFeatureFlag()

  const [isReassignDonationModalOpen, setIsReassignDonationModalOpen] = useState(false)
  const [errorDialogState, setErrorDialogState] = useState(false)
  const [successDialogState, setSuccessDialogState] = useState(false)

  const [{ data, isLoading, errorMessage }, { refresh }] = useQuery(donationsService.getTotalDonationsWithAmount, {
    organisationId,
    currencyCode: 'USD'
  })

  const handleGoToDonationsPage = async () => {
    return push(`/donations?organisationId=${organisationId}`)
  }

  const handleReassignDonationsModalOpen = () => {
    setIsReassignDonationModalOpen(prevState => !prevState)
  }

  const [{ isLoading: isReassignDonationsLoading, error: reassignError }, { apiFunc }] = useMutation(
    donationsService.reassignAllDonations,
    () => setSuccessDialogState(true),
    () => setErrorDialogState(true)
  )

  const reassignAllDonations = async ({
    organisationId: organisationIdTo,
    reason,
    reassignmentKind
  }: DonationReassignmentProps) => {
    await apiFunc({
      payload: {
        organisationIdFrom: organisationId,
        organisationIdTo,
        reason,
        reassignmentKind
      }
    })
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <CardDashboard
        title={t('typography.donations')}
        action={
          reassignDonationFeatureFlag && (
            <Button
              onPress={handleReassignDonationsModalOpen}
              variant="secondary"
              data-testid="reassign-all-donations-button"
            >
              {t('typography.reassignToNewOrganisation')}
            </Button>
          )
        }
      >
        {errorMessage ? (
          <ErrorView errorMessage={errorMessage} />
        ) : (
          <>
            <CardContent title={t('typography.totalDonationsCount')} testId="donations-total-count">
              <Box className={styles.countContainer}>
                {data.count}
                <ButtonText onPress={handleGoToDonationsPage}>
                  <Box className={styles.viewDonationsButtonContainer}>
                    <DonationsIcon fill="black" className={styles.icon} />
                    {t('typography.viewDonations')}
                  </Box>
                </ButtonText>
              </Box>
            </CardContent>
            <CardContent title={t('typography.totalDonationsAmount')} testId="donations-total-amount">
              {formatMoney(data.converted, { locale })} ({t('typography.convertedInfo')})
            </CardContent>
          </>
        )}
      </CardDashboard>
      <Spacer size={6} axis="vertical" />
      {reassignDonationFeatureFlag && (
        <ReassignDonationModal
          open={isReassignDonationModalOpen}
          refresh={refresh}
          sourceOrganisationName={organisationName}
          onClose={() => setIsReassignDonationModalOpen(false)}
          isReassignAll
          serviceProps={{
            apiFunc: reassignAllDonations,
            errorDialogState,
            success: successDialogState,
            error: reassignError,
            setErrorDialogState,
            setSuccessDialogState,
            isLoading: isReassignDonationsLoading
          }}
        />
      )}
    </>
  )
}
