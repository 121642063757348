import { agentVerificationServiceCapabilities } from '@percent/admin-dashboard/api/types'
import { PBPGetResponse, PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'
import {
  AgentVerificationList,
  AgentVerificationLogsResponse,
  DomainVerificationResponse,
  FraudulentFlagsResponse,
  GetAgentVerificationsProps,
  RejectAgentVerificationArgs,
  ApproveAgentVerificationArgs,
  AgentVerificationSimilarResponse,
  FraudCheckResultsResponse
} from './agentVerification.types'
import { throttleAsync } from '@percent/utility'

export const agentVerificationService = ({ percentClient }: agentVerificationServiceCapabilities) => ({
  getAgentVerification: ({ id }: { id: string }) => percentClient.get(`/v1/admin/agent-verifications/${id}`),
  getAgentVerifications: throttleAsync(
    (params: GetAgentVerificationsProps) =>
      percentClient.get<PBPListResponse<AgentVerificationList>>('/v1/admin/agent-verifications', { params }),
    1000,
    { leading: true, trailing: true }
  ),
  approveAgentVerification: ({ id, approvalReasonCode }: ApproveAgentVerificationArgs) =>
    percentClient.post(`/v1/admin/agent-verifications/${id}/approve`, { approvalReasonCode }),
  rejectAgentVerification: ({ id, rejectionReasonCode, fraudulentFlags }: RejectAgentVerificationArgs) =>
    percentClient.post(`/v1/admin/agent-verifications/${id}/reject`, {
      rejectionReasonCode,
      fraudulentFlags
    }),
  resendAgentVerificationEmail: ({ id }: { id?: string }) =>
    percentClient.post(`v1/admin/agent-verifications/${id}/emails`),
  approveAgentVerificationEmail: ({ id }: { id?: string }) =>
    percentClient.post(`v1/admin/agent-verifications/${id}/emails/approve`),
  patchAgentVerificationActionable: ({ id, externalActionRequired }: { id: string; externalActionRequired: boolean }) =>
    percentClient.patch(`/v1/admin/agent-verifications/${id}`, { externalActionRequired }),
  approveDomain: ({ id }: { id?: string }) => percentClient.post(`v1/admin/agent-verifications/${id}/verify-domain`),
  getFraudulentFlags: () =>
    percentClient.get<FraudulentFlagsResponse>(`/v1/admin/agent-verifications/fraudulent-flags`),
  getAgentVerificationLogs: ({ id }: { id: string }) =>
    percentClient.get<PBPGetResponse<AgentVerificationLogsResponse>>(`/v1/admin/agent-verifications/${id}/logs`),
  getAgentVerificationSimilar: ({ id }: { id: string }) =>
    percentClient.get<PBPGetResponse<AgentVerificationSimilarResponse>>(`/v1/admin/agent-verifications/${id}/similar`),
  requestFraudCheckResult: ({ id }: { id: string }) =>
    percentClient.get<PBPGetResponse<FraudCheckResultsResponse>>(
      `/v1/admin/agent-verifications/${id}/fraud-check-results`
    ),
  requestMarkAsSafe: ({ id }: { id: string }) => percentClient.post(`/v1/admin/agent-verifications/${id}/mark-as-safe`),
  requestAgentVerificationDocument: ({ id }: { id: string }) =>
    percentClient.post(`v1/admin/agent-verifications/${id}/request-document`),
  getAgentVerificationDomainVerification: ({ id }: { id: string }) =>
    percentClient.get<PBPGetResponse<DomainVerificationResponse>>(
      `/v1/admin/agent-verifications/${id}/domain-verification`
    )
})
