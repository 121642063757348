import { WireTransferType } from '@percent/domain/bank-details'

type FieldsBase = {
  placeholder: string
  errorMessage: string
  hint: string
  label: string
  regex: string
  required: boolean
}

type MockDocumentsResponse = {
  totalResults: number
  object: string
  data: {
    documents: {
      id: string
      fileName: string
      mimeType: string
      url: string
      createdAt: string
      createdBy: string
    }[]
  }
}

type FieldWithLabel = {
  value: string
  label: string
}

type MockBankDetailsResponse = {
  totalResults: number
  data: {
    organisationId: string
    countryCode: string
    organisationName: string
    claimer: { email: string }
    status: string
    requestDate: string
    reference: string
    document: { url: string }
    bankValidationData?: {
      success: boolean
      validationErrors: {
        field: string
        message: string
      }[]
    }
    bankAccountDetails?: {
      holderName: string
      wireTransferDetails:
        | {
            type: WireTransferType.DOMESTIC
            bankCode: FieldWithLabel
            accountNumber: FieldWithLabel
          }
        | {
            type: WireTransferType.IBAN
            bic: FieldWithLabel
            iban: FieldWithLabel
          }
        | {
            type: WireTransferType.DOMESTIC_WITH_BIC
            accountNumber: FieldWithLabel
            bicCode: FieldWithLabel
          }
    }
    requiredBankAccountDetails?: {
      fields: {
        accountNumber: FieldsBase
        bankCode?: FieldsBase
        bic: FieldsBase
        branchCode?: FieldsBase
      }
      type: WireTransferType.DOMESTIC_WITH_BIC
    }
  }[]
}

export const validBankDetailsId = '99999995'
export const invalidBankDetailsId = '99999996'
export const invalidWithoutErrorsBankDetailsId = '99999997'
export const validIbanBankDetailsId = '99999998'

export const mockDomesticBankDetailsListResponse: MockBankDetailsResponse = {
  totalResults: 3,
  data: [
    {
      organisationId: '123',
      countryCode: 'NOR',
      organisationName: 'Test NOR',
      claimer: { email: 'test@nor.com' },
      status: 'pending',
      requestDate: '21 Dec 2022, 15:10',
      reference: 'AV73SD',
      document: { url: 'https://dyingwithdignity.ca/wp-content/uploads/2022/03/DWDC_Wire_Transfer_Information.pdf' },
      bankAccountDetails: {
        holderName: 'NOR Account Name',
        wireTransferDetails: {
          type: WireTransferType.DOMESTIC,
          bankCode: {
            value: '000',
            label: 'Bank Code'
          },
          accountNumber: {
            value: '000 000 000',
            label: 'Account number'
          }
        }
      },
      bankValidationData: undefined
    },
    {
      countryCode: 'DMA',
      organisationName: 'Test DMA',
      claimer: { email: 'test@dma.com' },
      status: 'pending',
      requestDate: '26 Dec 2022, 14:10',
      reference: '7S2MKR',
      organisationId: '910',
      document: {
        url: 'https://i.dailymail.co.uk/1s/2019/07/30/16/16680854-7301947-image-a-3_1564500371283.jpg'
      },
      bankAccountDetails: {
        holderName: 'DMA Account Name',
        wireTransferDetails: {
          type: WireTransferType.DOMESTIC,
          bankCode: {
            value: '222',
            label: 'Bank Code'
          },
          accountNumber: {
            value: '222 222',
            label: 'Account number'
          }
        }
      },
      bankValidationData: undefined
    },
    {
      countryCode: 'IRN',
      organisationName: 'Test IRN',
      claimer: { email: 'test@irn.com' },
      status: 'rejected',
      requestDate: '09 Dec 2022, 09:10',
      reference: 'KUH2ZD',
      organisationId: '678',
      document: { url: 'https://dyingwithdignity.ca/wp-content/uploads/2022/03/DWDC_Wire_Transfer_Information.pdf' },
      bankAccountDetails: {
        holderName: 'IRN Account Name',
        wireTransferDetails: {
          type: WireTransferType.IBAN,
          bic: {
            value: '333',
            label: 'BIC/SWIFT Code'
          },
          iban: {
            value: '333 333',
            label: 'IBAN'
          }
        }
      },
      bankValidationData: undefined
    }
  ]
}

export const mockIBANBankDetailsListResponse: MockBankDetailsResponse = {
  totalResults: 1,
  data: [
    {
      countryCode: 'CUB',
      organisationName: 'Test CUB',
      claimer: { email: 'test@cub.com' },
      status: 'approved',
      requestDate: '26 Dec 2022, 11:10',
      reference: '97sdm2',
      organisationId: '456',
      document: {
        url: 'https://i.dailymail.co.uk/1s/2019/07/30/16/16680854-7301947-image-a-3_1564500371283.jpg'
      },
      bankAccountDetails: {
        holderName: 'CUB Account Name',
        wireTransferDetails: {
          type: WireTransferType.IBAN,
          bic: {
            value: '111',
            label: 'BIC/SWIFT Code'
          },
          iban: {
            value: '111 111',
            label: 'IBAN'
          }
        }
      },
      bankValidationData: undefined
    }
  ]
}

export const mockBankDetailsWithValidationResponses = {
  valid: {
    ...mockDomesticBankDetailsListResponse.data[0],
    organisationName: 'Test ORG CAN',
    requestDate: '21 Dec 2024, 15:10',
    reference: 'BX33S1',
    organisationId: validBankDetailsId,
    bankValidationData: {
      success: true,
      validationErrors: [],
      branch: {
        bankName: 'TEST BANK NAME',
        address: {
          addressLine1: 'TEST BANK ADDRESS',
          addressLine2: ' NOT USING EXTERNAL SERVICE',
          country: 'CAN'
        }
      }
    }
  },
  invalidWithErrors: {
    ...mockDomesticBankDetailsListResponse.data[0],
    organisationName: 'Test ORG CAN INVALID',
    requestDate: '22 Dec 2024, 15:10',
    reference: 'BX33S2',
    organisationId: invalidBankDetailsId,
    bankValidationData: {
      success: false,
      validationErrors: [
        {
          field: 'bankCode',
          message: 'Bank code is invalid'
        }
      ],
      branch: {
        bankName: 'TEST BANK NAME',
        address: {
          addressLine1: 'TEST BANK ADDRESS',
          addressLine2: ' NOT USING EXTERNAL SERVICE',
          country: 'CAN'
        }
      }
    }
  },
  invalidWithoutErrors: {
    ...mockDomesticBankDetailsListResponse.data[0],
    organisationName: 'Test ORG CAN INVALID2',
    requestDate: '23 Dec 2024, 15:10',
    reference: 'BX33S3',
    organisationId: invalidWithoutErrorsBankDetailsId,
    bankValidationData: {
      success: false,
      validationErrors: [],
      branch: {}
    }
  },
  validIban: {
    ...mockIBANBankDetailsListResponse.data[0],
    organisationName: 'Test ORG CAN VALID IBAN',
    requestDate: '24 Dec 2024, 15:10',
    reference: 'BX33S4',
    status: 'pending',
    organisationId: validIbanBankDetailsId,
    bankValidationData: {
      success: true,
      validationErrors: [],
      branch: {
        bankName: 'TEST BANK NAME',
        address: {
          addressLine1: 'TEST BANK ADDRESS',
          addressLine2: ' NOT USING EXTERNAL SERVICE',
          country: 'CAN'
        }
      }
    }
  }
}

export const mockDomesticWithBICBankDetailsListResponse: MockBankDetailsResponse = {
  totalResults: 1,
  data: [
    {
      countryCode: 'CUB',
      organisationName: 'Test CUB',
      claimer: { email: 'test@cub.com' },
      status: 'pending',
      requestDate: '26 Dec 2022, 11:10',
      reference: 'z4MMk2',
      organisationId: '115599',
      document: {
        url: 'https://i.dailymail.co.uk/1s/2019/07/30/16/16680854-7301947-image-a-3_1564500371283.jpg'
      },
      requiredBankAccountDetails: {
        type: WireTransferType.DOMESTIC_WITH_BIC,
        fields: {
          accountNumber: {
            placeholder: 'e.g. "123456789012"',
            label: 'Account Number',
            regex: '/^[0-9A-Z]{1,17}$/',
            hint: 'This is your 1 to 17 numbers and letters bank account number',
            errorMessage: 'Please enter a valid code (1-17 characters, only characters and numbers).',
            required: true
          },
          bic: {
            placeholder: 'e.g. "DEUTDEFFXXX"',
            label: 'BIC',
            regex: '/^[0-9A-Z]{1,17}$/',
            hint: 'This is your 1 to 17 numbers and letters bank account number',
            errorMessage: 'Please enter a valid code (1-17 characters, only characters and numbers).',
            required: true
          },
          bankCode: {
            placeholder: 'e.g. "021000021"',
            label: 'Bank code',
            regex: '/^\\d{9}$/',
            hint: 'The ABA routing code is a nine-digit code that identifies the bank where your account is held',
            errorMessage: 'ABA routing code must be 9 digits long. Example: 123456789',
            required: false
          },
          branchCode: {
            placeholder: 'e.g. "021000021"',
            label: 'Branch code',
            regex: '/^\\d{9}$/',
            hint: 'The ABA routing code is a nine-digit code that identifies the bank where your account is held',
            errorMessage: 'ABA routing code must be 9 digits long. Example: 123456789',
            required: false
          }
        }
      },
      bankValidationData: undefined
    },
    {
      countryCode: 'IND',
      organisationName: 'Test IND',
      claimer: { email: 'test@ind.com' },
      status: 'pending',
      requestDate: '26 Dec 2022, 11:10',
      reference: 'z4ZMk2',
      organisationId: '116699',
      document: {
        url: 'https://i.dailymail.co.uk/1s/2019/07/30/16/16680854-7301947-image-a-3_1564500371283.jpg'
      },
      requiredBankAccountDetails: {
        type: WireTransferType.DOMESTIC_WITH_BIC,
        fields: {
          accountNumber: {
            placeholder: 'e.g. "123456789012"',
            label: 'Account Number',
            regex: '/^[0-9A-Za-z]{1,20}$/',
            hint: 'Please enter your 1 to 20 character bank account number',
            errorMessage: 'The account number must be 1 to 20 characters long',
            required: true
          },
          bic: {
            placeholder: 'e.g. "DEUTDEFFXXX"',
            label: 'BIC',
            regex: '/^[0-9A-Z]{1,17}$/',
            hint: 'This is your 1 to 17 numbers and letters bank account number',
            errorMessage: 'Please enter a valid code (1-17 characters, only characters and numbers).',
            required: true
          },
          branchCode: {
            placeholder: 'e.g. "SBIN0001234"',
            label: 'IFSC',
            regex: '/^[A-Za-z]{4}[a-zA-Z0-9]{7}$/',
            errorMessage: 'The IFSC must be an 11-character alphanumeric code',
            hint: 'Please enter your Indian Financial System Code (IFSC). This is an 11-character alphanumeric code identifying your bank branch',
            required: true
          }
        }
      },
      bankValidationData: undefined
    }
  ]
}

export const mockMultipleDocumentsResponse: MockDocumentsResponse = {
  totalResults: 2,
  object: 'admin_bank_details_document',
  data: {
    documents: [
      {
        id: 'bankdetailsdocument_000000CzYDGdVI0hfgx4foJpwrX4i',
        fileName: 'example.pdf',
        mimeType: 'application/pdf',
        url: 'https://dyingwithdignity.ca/wp-content/uploads/2022/03/DWDC_Wire_Transfer_Information.pdf',
        createdAt: '2024-04-28T07:48:42.673Z',
        createdBy: 'account_000000Cg8miv2Ng7pbmmqOWFrVpa4'
      },
      {
        id: 'bankdetailsdocument_000000CzYDIPIYHqwZZWRJYfoQKQq',
        fileName: 'example.jpg',
        mimeType: 'image/jpg',
        url: 'https://i.dailymail.co.uk/1s/2019/07/30/16/16680854-7301947-image-a-3_1564500371283.jpg',
        createdAt: '2024-04-29T07:48:42.673Z',
        createdBy: 'account_000000Cg8miv2Ng7pbmmqOWFrVpa4'
      },
      {
        id: 'bankdetailsdocument_000000CzYDJqVS8XLuhVkXJbS4E2C',
        fileName: 'example2.jpg',
        mimeType: 'image/jpg',
        url: 'https://i.dailymail.co.uk/1s/2019/07/30/16/16680854-7301947-image-a-3_1564500371283.jpg',
        createdAt: '2024-04-30T07:48:42.673Z',
        createdBy: 'account_000000Cg8miv2Ng7pbmmqOWFrVpa4'
      }
    ]
  }
}
