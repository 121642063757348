import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CardContent } from '@percent/lemonade'
import { ReactComponent as PencilSVG } from '@percent/admin-dashboard/common/assets/images/pencil.svg'
import { PaymentMethod } from '@percent/admin-dashboard/api/types'
import styles from './PaymentDetails.module.scss'
import { ListItemButton } from '@percent/admin-dashboard/common/components/listItemButton/ListItemButton'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { UpdatePaymentMethodModal } from './UpdatePaymentMethodModal'

export function PaymentTypeWithButton({
  title,
  text,
  paymentMethod,
  refresh,
  isUpdateAvailable = true
}: {
  title: string
  text: string
  refresh: VoidFunction
  paymentMethod?: PaymentMethod
  isUpdateAvailable?: boolean
}) {
  const [openUpdatePaymentMethodModal, setOpenUpdatePaymentMethodModal] = useState(false)
  const { t } = useTranslation()
  const { paymentMethodsSubmitFeatureFlag } = useFeatureFlag()

  const handleUpdatePaymentMethodModal = () => {
    setOpenUpdatePaymentMethodModal(prevState => !prevState)
  }

  const isValidMethod = paymentMethod?.method === 'card' || paymentMethod?.method === 'cheque'

  return (
    <>
      <CardContent title={title} testId="payment-method">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '24px'
          }}
        >
          {text}
          {paymentMethodsSubmitFeatureFlag && isUpdateAvailable && (
            <ListItemButton
              className={styles.editButton}
              title={
                isValidMethod
                  ? t('button.paymentDetails.updatePaymentMethod')
                  : t('button.paymentDetails.setPaymentMethod')
              }
              onClick={handleUpdatePaymentMethodModal}
              testId="payment-method-action"
              icon={<PencilSVG />}
            />
          )}
        </div>
      </CardContent>
      <UpdatePaymentMethodModal
        open={openUpdatePaymentMethodModal}
        onClose={handleUpdatePaymentMethodModal}
        refresh={refresh}
        paymentMethod={isValidMethod ? (paymentMethod as PaymentMethod & { method: 'card' | 'cheque' }) : undefined}
      />
    </>
  )
}
