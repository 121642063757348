import { useTranslation } from 'react-i18next'

import { AutoDomainFraudChecksAlert } from '../agentVerificationOrganisationDetails/autoDomainFraudChecksAlert/AutoDomainFraudChecksAlert'

import { getProperHref } from '@percent/admin-dashboard/common/utility/getProperHref'
import { CardContent, CardDashboard } from '@percent/lemonade'
import styles from './AgentVerificationDomainAnalysis.module.scss'
import { DomainAnalysisProps } from './AgentVerificationDomainAnalysis.types'

export function AgentVerificationDomainAnalysis({ index, name, failedAutoChecks }: DomainAnalysisProps) {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <CardDashboard title={t('autoFraudChecks.domainAnalysis.title', { index })}>
        <AutoDomainFraudChecksAlert failedAutoChecks={failedAutoChecks} />
        <CardContent title={t('table.website')}>
          <a href={getProperHref(name)} target="_blank" rel="noopener noreferrer" className={styles.websiteLink}>
            {name}
          </a>
        </CardContent>
      </CardDashboard>
    </div>
  )
}
