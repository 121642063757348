import { AutoApprovalListItem } from '@percent/admin-dashboard/api/actions/autoApprovalList/autoApprovalList.types'
import { PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'

export const mockAutoApprovalListResponse: PBPListResponse<AutoApprovalListItem> = {
  data: [
    {
      id: 'staging_autoapprovallist_000000CvowrZ88yZOzbIQzMO5a2aG',
      email: 'emailA@poweredbypercent.com',
      organisationId: 'orgA',
      createdAt: '2024-04-29T07:48:42.377Z',
      createdBy: 'creator'
    },
    {
      id: 'staging_autoapprovallist_000000CvmT0AQoOaJ8wi7U7XpYIWu',
      email: 'emailB@poweredbypercent.com',
      organisationId: 'orgB',
      createdAt: '2024-04-29T07:48:42.377Z',
      createdBy: 'creator'
    },
    {
      id: 'staging_autoapprovallist_000000CvmSzwiWjy1h4YXUPsdfJ4K',
      email: 'emailC@poweredbypercent.com',
      organisationId: 'orgC',
      createdAt: '2024-04-29T07:48:42.377Z',
      createdBy: 'creator'
    },
    {
      id: 'staging_autoapprovallist_000000CvcPIn1llUi4BoRCGMPOQXC',
      email: 'emailD@poweredbypercent.com',
      organisationId: 'orgD',
      createdAt: '2024-04-29T07:48:42.377Z',
      createdBy: 'creator'
    }
  ],
  totalResults: 4,
  pageSize: 4,
  object: 'agent_verification_admin_auto_approval_list_agent',
  _links: {
    current: 'https://staging-api.poweredbypercent.com/v1/admin/verifications-allowlist?pageSize=25'
  }
}
