import { Box, TableCell, TableRow } from '@material-ui/core'
import { useCallback, EventHandler, KeyboardEvent, useMemo, useState, ChangeEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AddOrganizationModal } from '../addOrganization/AddOrganizationModal'

import { Badge, Button, SearchInput, Spacer, useToast } from '@percent/lemonade'
import { Organisation, OrganisationAddressRow } from '@percent/admin-dashboard/api/types'
import { ErrorInfo, ErrorView, Table } from '@percent/admin-dashboard/common/components'
import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import styles from './OrganizationsTable.module.scss'
import { OrganizationsTableProps, UploadError } from './OrganizationsTable.types'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { UploadBatchOrganisationsUpdate } from './UploadBatchOrganisationsUpdate/UploadBatchOrganisationsUpdate'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'

export function OrganizationsTable({
  organisations,
  totalResults,
  nextPage,
  previousPage,
  isLoading,
  errorMessage,
  queryParams,
  setQueryParams,
  refresh
}: OrganizationsTableProps) {
  const { t } = useTranslation()
  const { push } = useHistory()
  const [dialogState, setDialogState] = useState(false)
  const [searchValue, setSearchValue] = useState<string>(queryParams.query || '')
  const { addOrgFeatureFlag, batchUpdateOrganisationsFeatureFlag } = useFeatureFlag()
  const { adminService } = useServices()
  const [uploadError, setUploadError] = useState<UploadError | undefined>()
  const { addToast } = useToast()

  const handleRegistriesDialog = () => {
    setDialogState(prevState => !prevState)
  }

  const organizationRowClick = (row: Organisation) => {
    push(`/organizations/${row.id}`)
  }

  const cells = [
    { id: 'registryId', isSortable: false, props: { width: '115px' } },
    { id: 'name', isSortable: false, props: { width: '300px' } },
    { id: 'country', isSortable: false },
    { id: 'claimed', isSortable: false }
  ]

  const handleKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      if (event.key === 'Enter') {
        setQueryParams({
          query: (event.target as HTMLInputElement).value,
          page: 1
        })
      }

      if (event.key === 'Escape') {
        setSearchValue('')
      }

      return null
    },
    [setQueryParams]
  )

  const handleClearValue = () => {
    setSearchValue('')
  }

  const [{ isLoading: isUploadInProgress }, { apiFunc: organisationsBatchUpdate }] = useMutation(
    adminService.commands.organisationsBatchUpdate,
    _response => {
      addToast(t('toast.organisationsBatchUpdateSuccess'), 'success')
      setQueryParams({
        page: undefined,
        query: undefined
      })
      refresh()
      setUploadError(undefined)
    },
    (e: any) => {
      if (e.response?.data?.error) {
        setUploadError(e.response?.data?.error)
      } else {
        addToast(t('toast.organisationsBatchUpdateError'), 'error')
      }
    }
  )

  const handleOrganisationsBatchUpdate = useCallback(
    async (payload: OrganisationAddressRow[]) => {
      await organisationsBatchUpdate({ payload })
    },
    [organisationsBatchUpdate]
  )

  const filters = useMemo(
    () => (
      <Box className={styles.wrapper}>
        <Box className={styles.filtersWrapper}>
          <SearchInput
            value={searchValue ?? ''}
            handleClearValue={handleClearValue}
            onKeyDown={handleKeyPress}
            onChange={handleKeyChange}
            placeholder={t('table.adminNonprofitsSearchPlaceholder')}
          />
          <Spacer size={4} axis="horizontal" />
          {addOrgFeatureFlag && (
            <Button variant="secondary" onPress={handleRegistriesDialog} data-testid="add-organisation-button">
              {t('button.addOrganization')}
            </Button>
          )}
          {batchUpdateOrganisationsFeatureFlag && (
            <>
              <Spacer size={4} axis="horizontal" />
              <UploadBatchOrganisationsUpdate
                isUploadInProgress={isUploadInProgress}
                handleOrganisationsBatchUpdate={handleOrganisationsBatchUpdate}
              />
            </>
          )}
        </Box>
        {uploadError ? (
          <ErrorInfo
            header={`${t('typography.organisationsBatchUpdateErrorTitle')} ${uploadError.message}`}
            reasons={uploadError.reasons}
            footer={t('typography.organisationsBatchUpdateErrorInstruction')}
          />
        ) : null}
      </Box>
    ),
    [searchValue, handleKeyPress, t, addOrgFeatureFlag, handleOrganisationsBatchUpdate, isUploadInProgress, uploadError]
  )

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <Box>
      <Table
        isLoading={isLoading}
        totalResults={totalResults}
        data={organisations}
        previousPage={previousPage}
        nextPage={nextPage}
        columns={cells}
        orderBy="name"
        emptyTableText={t('table.emptyNonprofits')}
        filtersContent={filters}
      >
        {organisations?.map(row => (
          <TableRow key={row.id} onClick={() => organizationRowClick(row)}>
            <TableCell>{row.registryId}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.countryCode}</TableCell>
            <TableCell>
              {row.claimedAt ? (
                <Badge variant="positive" icon="check">
                  {t('typography.claimed')}
                </Badge>
              ) : (
                <Badge variant="default" icon="reject">
                  {t('typography.unclaimed')}
                </Badge>
              )}
            </TableCell>
            <TableCell
              align="right"
              className={styles.rowIconForward}
              style={{ padding: '0' }}
              data-testid="organization-details-link"
            >
              <DropdownSVG className={styles.arrow} />
            </TableCell>
          </TableRow>
        ))}
      </Table>
      {addOrgFeatureFlag && (
        <AddOrganizationModal open={dialogState} onClose={handleRegistriesDialog} refresh={refresh} />
      )}
    </Box>
  )
}
