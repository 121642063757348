import { accountsHandlers } from './account'
import { activityChecksHandlers } from './activityChecks'
import { agentVerfifcationsHandlers } from './agentVerifications'
import { authHandlers } from './auth'
import { claimsHandlers } from './claims'
import { eligibilitySubscriptionsHandlers } from './eligibilitySubscriptions'
import { monitoringHandlers } from './monitoring'
import { organisationsHandlers } from './organisations'
import { registriesHandlers } from './registries/registries'
import { splitIOHandlers } from './splitIO'
import { validationRequestsHandlers } from './validationRequests'
import { vettingRequestsHandlers } from './vettingRequests'
import { bankDetailsHandlers } from './bankDetails'
import { organizationsHandlers } from './organizations'
import { activityHandlers } from './activity'
import { milestonesHandlers } from './milestones'
import { countriesHandlers } from './countries'
import { partnerHandlers } from './partner'
import { relatedOrganisationsHandlers } from './relatedOrganisations'
import { autoRejectionListHandlers } from './autoRejectionList'
import { validationSubmissionsHandlers } from './validationSubmissions'
import { donationMatchRequestHandlers } from './donationMatchRequest'
import { autoApprovalListHandlers } from './autoApprovalList'
import { vrBlockListHandlers } from './vrBlockList'

export const handlers = [
  ...accountsHandlers,
  ...activityChecksHandlers,
  ...agentVerfifcationsHandlers,
  ...authHandlers,
  ...claimsHandlers,
  ...eligibilitySubscriptionsHandlers,
  ...monitoringHandlers,
  ...organisationsHandlers,
  ...registriesHandlers,
  ...splitIOHandlers,
  ...vrBlockListHandlers,
  ...validationRequestsHandlers,
  ...vettingRequestsHandlers,
  ...bankDetailsHandlers,
  ...organizationsHandlers,
  ...activityHandlers,
  ...milestonesHandlers,
  ...countriesHandlers,
  ...partnerHandlers,
  ...relatedOrganisationsHandlers,
  ...autoRejectionListHandlers,
  ...autoApprovalListHandlers,
  ...validationSubmissionsHandlers,
  ...donationMatchRequestHandlers
]
