import { useContext } from 'react'

import { ActivityTagsContext } from '@percent/admin-dashboard/context/activityTags'

export const useActivityTagsState = () => {
  const context = useContext(ActivityTagsContext)

  if (context === undefined) {
    throw new Error('useActivityTagsState must be used within an ActivityTagsContextController')
  }

  return context
}
