import { useState } from 'react'

import { APIErrorHandler } from '../../library/APIErrorHandler'

import { UseCSVDownloaderPropsType } from './useCSVDownloader.types'
import { useToast } from '@percent/lemonade'

type DownloadError = {
  message: string
  reasons: string[]
}

export const useCSVDownloader = ({ service, fileName, successMessage, errorMessage }: UseCSVDownloaderPropsType) => {
  const [loading, setLoading] = useState(false)
  const [errorCSVMessage, setErrorCSVMessage] = useState('')
  const [rawError, setRawError] = useState<DownloadError | undefined>(undefined)
  const { addToast } = useToast()

  const downloadCsv = (data: string, fileN: string): void => {
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileN}.csv`)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  const downloadCSV = async (showToast = true) => {
    setLoading(true)
    setErrorCSVMessage('')
    setRawError(undefined)
    try {
      const csvData = await service()
      downloadCsv(csvData, fileName)
      addToast(successMessage, 'success')
    } catch (err: any) {
      setErrorCSVMessage(APIErrorHandler(err?.response?.data?.error))
      setRawError(err?.response?.data?.error)
      errorMessage && showToast && addToast(errorMessage, 'error')
    } finally {
      setLoading(false)
    }
  }

  return [
    {
      loading,
      errorCSVMessage,
      rawError
    },
    {
      downloadCSV
    }
  ]
}
