import { useTranslation } from 'react-i18next'

import { FailedDomainAutoChecks } from '../../../utils/getFailedDomainAutoChecks'

import { Alert, Flex } from '@percent/lemonade'

type AutoDomainFraudChecksAlertProps = {
  failedAutoChecks: FailedDomainAutoChecks
}

export function AutoDomainFraudChecksAlert({ failedAutoChecks }: AutoDomainFraudChecksAlertProps) {
  const { t } = useTranslation()

  if (failedAutoChecks.total === 0) return null

  return (
    <Flex mb={8} w="100%" direction="column" data-testid="domain-auto-fraud-checks">
      <Alert variant="warning" title={t('typography.domainFlags')}>
        <ul>
          {failedAutoChecks.advanced?.map(check => (
            <li key={check}>{t(`autoFraudChecks.advancedDomain.${check}`)}</li>
          ))}
          {failedAutoChecks.basic?.map(check => (
            <li key={check}>{t(`autoFraudChecks.basicDomain.${check}`)}</li>
          ))}
        </ul>
      </Alert>
    </Flex>
  )
}
