import {
  UpdateBankDetailsProps,
  BankDetailsMainView,
  BankDetailsServiceCapabilities,
  GetBankDetailProps,
  BankDetailsDocumentResponse,
  BankDetailsListView,
  getBankDetailsListProps,
  UpdateBankDetailsFormProps,
  RemoveBankDetailProps,
  PostBankDetailsProps,
  GetBankDetailByIdProps,
  AddBankDetailsNoteProps,
  UploadAdditionalBankDetailsDocuments,
  GetBankDetailsReviewRequestNote,
  BankDetailsReviewRequestLog,
  BankDetailsValidationPayload,
  BankDetailsValidationWithDocumentPayload
} from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'
import { PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'

export const bankDetailsService = ({ percentClient }: BankDetailsServiceCapabilities) => ({
  getBankDetailsList: (params: getBankDetailsListProps) =>
    percentClient.get<PBPListResponse<BankDetailsListView>>('v1/admin/bank-details-reviews', { params }),
  getBankDetails: ({ organisationId }: GetBankDetailProps) =>
    percentClient.get<BankDetailsMainView>(`v1/admin/organisations/${organisationId}/bank-details-review`),
  getBankDetailsById: ({ id }: GetBankDetailByIdProps) =>
    percentClient.get<BankDetailsMainView>(`v1/admin/bank-details-reviews/${id}`),
  getBankDetailsDocument: ({ organisationId }: GetBankDetailProps) =>
    percentClient.get<BankDetailsDocumentResponse>(
      `v1/admin/organisations/${organisationId}/bank-details-review/document`
    ),
  postBankDetails: ({ payload }: PostBankDetailsProps) =>
    percentClient.post('v1/bank-details-review', payload.formData),
  getBankDetailsDocumentByBankDetailsId: ({ id }: GetBankDetailByIdProps) =>
    percentClient.get<BankDetailsDocumentResponse>(`v1/admin/bank-details-reviews/${id}/document`),
  updateBankDetails: ({ status, organisationId }: UpdateBankDetailsProps) =>
    percentClient.post(`v1/admin/organisations/${organisationId}/bank-details-review`, { status }),
  updateBankDetailsForm: ({ payload, organisationId }: UpdateBankDetailsFormProps) =>
    percentClient.put(`v1/admin/organisations/${organisationId}/bank-details-review`, { ...payload }),
  removeBankDetails: ({ organisationId }: RemoveBankDetailProps) =>
    percentClient.delete(`v1/admin/organisations/${organisationId}/bank-details`),
  addBankDetailsNote: ({ reviewId, note }: AddBankDetailsNoteProps) =>
    percentClient.post(`v1/admin/bank-details-reviews/${reviewId}/notes`, { note }),
  getBankDetailsReviewRequestLogs: ({ reviewId }: GetBankDetailsReviewRequestNote) =>
    percentClient.get<{ data: BankDetailsReviewRequestLog[] }>(`v1/admin/bank-details-reviews/${reviewId}/logs`),
  getBankDetailsDocumentsByBankDetailsId: ({ id }: GetBankDetailByIdProps) =>
    percentClient.get<BankDetailsDocumentResponse>(`v1/admin/bank-details-reviews/${id}/documents`),
  uploadAdditionalDocuments: ({ reviewId, payload }: UploadAdditionalBankDetailsDocuments) =>
    percentClient.post(`v1/admin/bank-details-review/${reviewId}/documents`, payload.formData),
  fetchBankData: ({ organisationId, ...payload }: BankDetailsValidationPayload) =>
    percentClient.post(`v1/bank-details-reviews/${organisationId}/fetch-bank-data`, payload),
  validateBankDetailsWithDocument: async ({
    organisationId,
    file,
    ...payload
  }: BankDetailsValidationWithDocumentPayload) => {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('bankAccountDetails', JSON.stringify(payload.bankAccountDetails))

    return percentClient.post(`v1/bank-details-reviews/${organisationId}/validate-bank-details`, formData)
  }
})
