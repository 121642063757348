import { useTranslation } from 'react-i18next'

import { FraudulentFlagCode } from '@percent/admin-dashboard/api/types'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { Alert } from '@percent/lemonade'
import styles from './FraudFlagsAlert.module.scss'
import { FraudulentFlagsAlertProps } from './FraudFlagsAlert.types'

export function FraudFlagsAlert({
  title,
  flags,
  isApplicationLanguageSuspicious
}: Readonly<FraudulentFlagsAlertProps>) {
  const { agentVerificationManualFradulentRejectionFeatureFlag } = useFeatureFlag()
  const { t } = useTranslation()

  const getPrefixFrom = <K extends string>(value: string, prefixes: K[]) =>
    prefixes.find(prefix => value.startsWith(prefix))

  const getTagForCode = (code: FraudulentFlagCode): string | undefined => {
    const prefix = getPrefixFrom(code, ['organisation_email', 'organisation_website'])

    if (!prefix) return undefined

    return t(`fraudReason.prefix.${prefix}`)
  }

  if ((!agentVerificationManualFradulentRejectionFeatureFlag || !flags.length) && !isApplicationLanguageSuspicious)
    return null

  return (
    <div className={styles.fraudAlertWrapper}>
      <Alert title={title} variant="info">
        <ul>
          {flags?.map(flag => (
            <li key={flag.id}>
              {[t('typography.manual'), getTagForCode(flag.code)].join(' ')} {t(`fraudReason.label.${flag.code}`)}
            </li>
          ))}
          {isApplicationLanguageSuspicious && (
            <li>
              {t('typography.automation')} {t('typography.applicationLanguageSuspicious')}
            </li>
          )}
        </ul>
      </Alert>
    </div>
  )
}
