import { Icon } from '../icon'
import { SelectProps } from './select.types'
import { useSelect } from './hooks/useSelect'
import { Combobox, Listbox, Transition } from '@headlessui/react'
import {
  StyledSelect,
  StyledSelectTrigger,
  StyledSelectTriggerDropdown,
  StyledNonSearchable,
  StyledSearchable,
  StyledSelectList,
  StyledSelectOptions,
  StyledSelectAddOptionButton,
  StyledDropDownSearch,
} from './components'
import { StyledIconContainer } from './components/StyledDropDownSearch'
import styled from 'styled-components'
import { Spinner } from '../spinner'

const StyledPrefix = styled.div`
  margin-right: 0.5rem;
  max-height: 2rem;
  display: flex;
`

const SelectBody = ({
  open,
  selectOptions,
  selectProps,
  disabled,
  dataTestId,
  isLoading,
  size,
}: {
  open: boolean
  selectOptions: ReturnType<typeof useSelect>
  selectProps: SelectProps
  disabled: boolean
  dataTestId?: string
  isLoading?: boolean
  size?: 'small' | 'medium'
}) => {
  const {
    inputProps,
    renderElements,
    value,
    triggerProps,
    appendToSearch,
    afterLeave,
  } = selectOptions
  const {
    searchable,
    searchInDropDown,
    placeholder,
    addOption,
    status = 'default',
    orientation = 'down',
  } = selectProps

  const ListType = searchable ? Combobox : Listbox

  return (
    <StyledSelect>
      <ListType.Button
        style={{
          background: 'none',
          border: 'none',
          width: '100%',
          padding: '0',
          margin: '0',
        }}
        disabled={disabled}
        data-testid={dataTestId ? `${dataTestId}-toggle` : 'select-toggle'}
        onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
          if (e.key === ' ') {
            appendToSearch(' ')
            e.preventDefault()
          }
        }}
      >
        <StyledSelectTrigger
          {...triggerProps}
          $disabled={disabled}
          $status={status}
          $size={size}
        >
          {(!searchable || searchInDropDown) && (
            <StyledNonSearchable
              $disabled={disabled}
              $isPlaceholder={!value?.label}
            >
              {isLoading && <Spinner size={4} />}
              {selectProps.showPrefixForSelectedOption && (
                <ListType.Label>
                  <StyledPrefix>{value?.prefix}</StyledPrefix>
                </ListType.Label>
              )}
              <span>{value?.label || placeholder}</span>
            </StyledNonSearchable>
          )}

          {searchable && !searchInDropDown && (
            <StyledSearchable $disabled={disabled}>
              {selectProps.showPrefixForSelectedOption && value?.prefix && (
                <Combobox.Label>
                  <StyledPrefix>{value?.prefix}</StyledPrefix>
                </Combobox.Label>
              )}
              {isLoading ? (
                <Spinner size={4} />
              ) : (
                <Combobox.Input
                  {...inputProps}
                  data-testid={
                    dataTestId ? `${dataTestId}-input` : 'select-input'
                  }
                />
              )}
            </StyledSearchable>
          )}

          <StyledSelectTriggerDropdown
            style={{
              pointerEvents: disabled ? 'none' : 'auto',
              transform: open ? 'rotate(-180deg)' : '',
            }}
          >
            <Icon name="dropdown-arrow-down" size={8} color="gray600" />
          </StyledSelectTriggerDropdown>
          <Transition show={open} afterLeave={afterLeave}>
            <StyledSelectList id="select-list" $orientation={orientation}>
              {open && (
                <>
                  {searchable && searchInDropDown && (
                    <StyledDropDownSearch onClick={(e) => e.stopPropagation()}>
                      <StyledIconContainer>
                        <Icon name="search" size={6} color="gray300" />
                      </StyledIconContainer>
                      <Combobox.Input
                        {...inputProps}
                        data-testid={
                          dataTestId ? `${dataTestId}-input` : 'select-input'
                        }
                      />
                    </StyledDropDownSearch>
                  )}
                  <StyledSelectOptions>
                    <ListType.Options
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                      static
                    >
                      {renderElements}
                    </ListType.Options>
                  </StyledSelectOptions>
                  {addOption && (
                    <StyledSelectAddOptionButton onClick={addOption.onClick}>
                      <div style={{ marginRight: '0.5rem' }}>
                        <Icon name="add-circle-solid" size="m" />
                      </div>
                      <div>{addOption.label}</div>
                    </StyledSelectAddOptionButton>
                  )}
                </>
              )}
            </StyledSelectList>
          </Transition>
        </StyledSelectTrigger>
      </ListType.Button>
    </StyledSelect>
  )
}

export const Select: React.FC<SelectProps> = (props) => {
  const {
    placeholder,
    searchable = false,
    searchInDropDown = false,
    name,
    options,
    onChange,
    defaultValue,
    disabled,
    noResultsFoundText,
    'data-testid': dataTestId,
    showPrefixForSelectedOption = false,
    isLoading,
    size = 'small',
  } = props

  const select = useSelect({
    options,
    searchable,
    searchInDropDown,
    placeholder,
    defaultValue,
    noResultsFoundText,
    showPrefixForSelectedOption,
  })

  return searchable ? (
    <Combobox
      name={name}
      value={select.value}
      defaultValue={defaultValue}
      onChange={(e) => {
        select.setValue(e)
        onChange && onChange(e)
      }}
      disabled={disabled}
    >
      {({ open, disabled }) => (
        <SelectBody
          dataTestId={dataTestId}
          open={open}
          selectOptions={select}
          selectProps={props}
          disabled={disabled}
          isLoading={isLoading}
          size={size}
        />
      )}
    </Combobox>
  ) : (
    <Listbox
      name={name}
      value={select.value}
      defaultValue={defaultValue}
      onChange={(e) => {
        select.setValue(e)
        onChange && onChange(e)
      }}
      disabled={disabled}
    >
      {({ open, disabled }) => (
        <SelectBody
          dataTestId={dataTestId}
          open={open}
          selectOptions={select}
          selectProps={props}
          disabled={disabled}
          size={size}
        />
      )}
    </Listbox>
  )
}
