import React, { useRef } from 'react'
import styled, { css, CSSProp } from 'styled-components'
import { useButton } from '@react-aria/button'
import { ButtonProps, StyledButtonProps, ButtonVariant } from './button.types'
import { Spinner, SpinnerProps } from '../spinner'
import { styledVariant } from '../../util'
import { Theme } from '../../theme'
import { Icon } from '../icon'

const SpinnerColorForVariant: Record<ButtonVariant, SpinnerProps['color']> = {
  primary: 'light',
  secondary: 'dark',
  tertiary: 'dark',
  approve: 'light',
  reject: 'light',
}

export const colors: Record<
  ButtonVariant,
  (obj: { theme: Theme; $loading: boolean }) => CSSProp
> = {
  primary: ({ theme, $loading }) => css`
    background-color: ${theme.colors.primary400};
    color: ${theme.colors.white};
    &:hover {
      background-color: ${theme.colors.primary600};
    }
    &:disabled {
      background-color: ${theme.colors.gray80};
      color: ${theme.colors.gray500};
      ${$loading &&
      css`
        background-color: ${theme.colors.primary400};
      `}
    }
  `,
  secondary: ({ theme, $loading }) => css`
    background-color: ${$loading ? theme.colors.gray80 : theme.colors.gray70};
    color: ${theme.colors.primary400};
    &:hover {
      background-color: ${theme.colors.gray80};
    }
    &:disabled {
      background-color: ${theme.colors.gray80};
      color: ${theme.colors.gray500};
    }
  `,
  tertiary: ({ theme }) => css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.primary400};
    &:hover {
      text-decoration: underline;
    }
    &:disabled {
      color: ${theme.colors.gray500};
      text-decoration: none;
    }
  `,
  approve: ({ theme, $loading }) => css`
    background-color: ${theme.colors.gray70};
    color: ${theme.colors.success400};

    &:hover {
      background-color: ${theme.colors.gray80};
    }
    &:disabled {
      background-color: ${theme.colors.gray80};
      color: ${theme.colors.gray500};
      ${$loading &&
      css`
        background-color: ${theme.colors.success400};
      `}
    }
  `,
  reject: ({ theme, $loading }) => css`
    background-color: ${theme.colors.gray70};
    color: ${theme.colors.alert400};

    &:hover {
      background-color: ${theme.colors.gray80};
    }
    &:disabled {
      background-color: ${theme.colors.gray80};
      color: ${theme.colors.gray500};
      ${$loading &&
      css`
        background-color: ${theme.colors.alert400};
      `}
    }
  `,
}

const sizes: Record<string, (obj: { theme: Theme }) => string> = {
  mini: ({ theme }) => `
    padding: 0 ${theme.sizes.s};
    height: ${theme.sizes.m};
  `,
  small: ({ theme }) => `
    padding: 0 ${theme.sizes.m};
    height: ${theme.sizes.l};
  `,
  medium: ({ theme }) => `
    padding: 0 ${theme.sizes[12]};
    height: ${theme.sizes.xl};
  `,
  large: ({ theme }) => `
    padding: 0 ${theme.sizes[12]};
    height: ${theme.sizes[12]};
  `,
}

export const StyledButton = styled.button<StyledButtonProps>`
  ${styledVariant('$variant', colors)};
  ${styledVariant('$size', sizes)};

  ${({ theme }) =>
    `
    position: relative;
    border: none;
    border-radius: ${theme.borderRadii.medium};
    transition: ${theme.transitionTime} ease-in-out;
    cursor: pointer;

    font-weight: 500;
    font-size: ${theme.fontSizes.button};
    white-space: nowrap;
    `}

  &:disabled {
    pointer-events: none;
  }

  ${({ $stretch }) => $stretch && `width: 100%`}
`

export const Button = React.forwardRef<HTMLElement, ButtonProps>(
  // Ignoring any here because typing refs with react-aria is impossible
  // eslint-disable-next-line
  (props, forwardedRef: any) => {
    const {
      variant = 'primary',
      size = 'medium',
      loading = false,
      stretch,
      disabled,
      startIcon,
      endIcon,
      children,
      'data-testid': dataTestId,
      endIconSize,
      startIconSize,
    } = props
    const fallbackRef = useRef(null)
    const ref = forwardedRef || fallbackRef
    const { buttonProps } = useButton(
      {
        ...props,
        isDisabled: disabled || loading,
      },
      ref
    )

    return (
      <StyledButton
        {...buttonProps}
        ref={ref}
        $variant={variant}
        $size={size}
        $stretch={stretch}
        $loading={loading}
        data-testid={dataTestId}
      >
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: '0.125rem',
            opacity: loading ? 0 : 1,
          }}
        >
          {startIcon && (
            <Icon name={startIcon} color="inherit" size={startIconSize ?? 6} />
          )}
          <span style={{ opacity: loading ? 0 : 1 }}>{children}</span>
          {endIcon && (
            <Icon name={endIcon} color="inherit" size={endIconSize ?? 6} />
          )}
        </span>

        {loading && (
          <span
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            data-testid="button-spinner"
          >
            <Spinner color={SpinnerColorForVariant[variant]} size="m" />
          </span>
        )}
      </StyledButton>
    )
  }
)
