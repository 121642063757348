import { PBPGetResponse, PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'
import {
  AddToAutoRequestRejectionListProps,
  AutoRequestRejectionListServiceCapabilities,
  AutoRequestRejectionListItem,
  GetAutoRequestRejectionListProps
} from './autoRequestRejectionList.types'

const autoRequestRejectionListUrl = 'v1/admin/validation-requests/blocklist'

export const autoRequestRejectionListService = ({ percentClient }: AutoRequestRejectionListServiceCapabilities) => ({
  getAutoRequestRejectionList: (params: GetAutoRequestRejectionListProps) =>
    percentClient.get<PBPListResponse<AutoRequestRejectionListItem>>(autoRequestRejectionListUrl, { params }),
  addToAutoRejectonList: (params: AddToAutoRequestRejectionListProps) =>
    percentClient.post<PBPGetResponse<AutoRequestRejectionListItem>>(autoRequestRejectionListUrl, { ...params }),
  removeFromAutoRequestRejectionList: ({ id }: { id: string }) =>
    percentClient.delete(`${autoRequestRejectionListUrl}/${id}`)
})
