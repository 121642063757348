import { format, formatDistanceToNow } from 'date-fns'
import { useTranslation } from 'react-i18next'

import {
  ValidationSubmissionLog,
  ValidationSubmissionLogType
} from '@percent/admin-dashboard/api/actions/validationSubmissions/validationSubmissions.types'
import { UseValidationSubmissionTimelineProps } from './ValidationSubmissionTimeline.types'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'
import { Badge, TimelineElement } from '@percent/lemonade'

export const validationSubmissionLogsToSkip = [
  'fraud_check_advanced_domain_check_completed',
  'fraud_check_advanced_domain_check_requested',
  'fraud_check_application_check_completed',
  'fraud_check_application_check_requested',
  'fraud_check_basic_domain_check_completed',
  'fraud_check_basic_domain_check_requested'
] as ValidationSubmissionLogType[]

export const useValidationSubmissionTimeline = ({
  logs,
  validationSubmission
}: UseValidationSubmissionTimelineProps) => {
  const { t } = useTranslation()

  const formatStatus = (status?: string | null) => {
    switch (status) {
      case 'pass':
        return t('typography.passed')
      case 'fail':
        return t('typography.failed')
      case 'pending_review':
        return t('typography.pendingReview')
      case 'pending_user_verification':
        return t('typography.pendingUserVerification')
      default:
        // Some statuses are returned in correct format directly from BE
        return status
    }
  }

  const getTimelineElement = (log: ValidationSubmissionLog): TimelineElement => {
    switch (log.type) {
      case 'eligibility_result_change':
        return {
          title: t('typography.timeline.eligibilityResultChange.title', {
            result: formatStatus(log.data.updatedCheck?.result)
          }),
          description: log?.data?.updatedCheck?.result ? (
            <div style={{ display: 'flex', gap: 8, alignItems: 'center', flexWrap: 'wrap' }}>
              {t('typography.timeline.eligibilityResultChange.description')}
              {log?.data?.updatedCheck?.results?.confirmedActivitySubTags?.map(tag => (
                <Badge variant="default" key={tag.id}>
                  {tag.name}
                </Badge>
              ))}
            </div>
          ) : (
            t('status.cantDefine')
          )
        }
      case 'monitoring_subscription_result_change': {
        const results = log?.data?.updatedCheck?.results

        const flaggedMonitoringKeys =
          results &&
          Object.keys(results).filter(key => {
            const value = results?.[key as keyof typeof results]

            return value && typeof value === 'object' && 'status' in value && value.status === 'flag'
          })

        return {
          title: t('typography.timeline.monitoringSubscriptionResultChange.title', {
            result: formatStatus(log.data.updatedCheck?.result)
          }),
          description:
            flaggedMonitoringKeys?.length === 0
              ? t('typography.noFlags')
              : t('typography.flaggedChecks', {
                  flags: flaggedMonitoringKeys?.map(key => t(`form.${key}` as LocaleKey))?.join(', ')
                })
        }
      }
      case 'agent_verification_status_change':
        return {
          title: t('typography.timeline.agentVerificationStatusChange.title', {
            status: formatStatus(log.data.updatedCheck?.status)
          }),
          description:
            log.data.updatedCheck?.status === 'rejected' && log.data.updatedCheck.rejectionReasonCode
              ? t(`dropdown.reject.${log.data.updatedCheck.rejectionReasonCode}` as LocaleKey)
              : log.data.updatedCheck?.status === 'approved' && log.data.updatedCheck.approvalReasonCode
              ? t(`dropdown.approve.${log.data.updatedCheck.approvalReasonCode}` as LocaleKey)
              : undefined
        }
      case 'validation_request_accepted':
        return {
          title: t('typography.timeline.validationRequestAccepted.title')
        }
      case 'validation_request_rejected':
        return {
          title: t('typography.timeline.validationRequestRejected.title'),
          description: t(
            `typography.timeline.status.${validationSubmission.validationRequest?.rejectionReasonCode}` as LocaleKey
          )
        }
      case 'validation_submission_failed':
        return {
          title: t('typography.timeline.validationSubmissionFailed.title')
        }
      case 'validation_submission_failed_outcome_email_sent':
        return {
          title: t('typography.timeline.validationSubmissionFailedOutcomeEmail.title')
        }
      case 'validation_submission_succeeded':
        return {
          title: t('typography.timeline.validationSubmissionSucceeded.title')
        }
      case 'validation_submission_succeeded_outcome_email_sent':
        return {
          title: t('typography.timeline.validationSubmissionSucceededOutcomeEmail.title')
        }
      case 'validation_submission_processed':
        return {
          title: t('typography.timeline.validationSubmissionProcessed.title'),
          description: t('typography.timeline.validationSubmissionProcessed.description')
        }
      case 'validation_submission_pending':
        return {
          title: t('typography.timeline.validationSubmissionPending.title')
        }
      case 'fraud_check_completed':
        return {
          title: t('typography.timeline.fraudCheckCompleted.title'),
          description: t(`typography.timeline.fraudCheckCompleted.${log.data.fraudCheckResult}`)
        }
      case 'fraud_check_requested':
        return {
          title: t('typography.timeline.fraudCheckRequested.title')
        }
      default:
        return {
          title: t('typography.timeline.unknownStatus')
        }
    }
  }

  return logs
    ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    ?.filter(log => !validationSubmissionLogsToSkip.includes(log.type))
    ?.map(log => {
      const createdAtDate = new Date(log.createdAt)
      const oneHourAgo = new Date(Date.now() - 60 * 60 * 1000)

      return {
        ...getTimelineElement(log),
        additionalDescription:
          createdAtDate < oneHourAgo
            ? format(createdAtDate, 'yyyy-MM-dd HH:mm')
            : formatDistanceToNow(createdAtDate, { addSuffix: true })
      }
    })
}
