import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { AcknowledgeModal, ActionModal, Alert, Flex, FormField, Modal, TextInput } from '@percent/lemonade'
import { emailRegex } from '@percent/utility'
import { AddToAutoApprovalListModalProps } from './AddToAutoApprovalListModal.types'

const agentIsOnAllowListErrorCode = 'allow_list/agent_already_exists'

export function AddToAutoApprovalListModal({ isOpen, setIsOpen, refresh }: Readonly<AddToAutoApprovalListModalProps>) {
  const { t } = useTranslation()
  const { autoApprovalListService } = useServices()
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const [{ isLoading, error }, { apiFunc: addToAutoApprovalList }] = useMutation(
    autoApprovalListService.addToAutoApprovalList,
    () => {
      setShowConfirmationScreen(true)
      refresh()
    },
    () => setShowErrorMessage(true)
  )

  const { values, handleChange, errors, submitForm, isValid, dirty } = useFormik<{
    email: string
    organisationId: string
  }>({
    initialValues: {
      email: '',
      organisationId: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string()
          .required('Required')
          .matches(emailRegex, t('errorMessage.validEmail'))
          .max(255, t('errorMessage.shouldNotExceed255')),
        organisationId: Yup.string().required('Required')
      }),
    onSubmit: addToAutoApprovalList
  })

  const closeModal = () => {
    setIsOpen(false)
    setShowErrorMessage(false)
  }

  return (
    <Modal open={isOpen} onClose={closeModal}>
      {showConfirmationScreen && (
        <AcknowledgeModal
          handleClose={() => {
            closeModal()
            setShowConfirmationScreen(false)
          }}
          title={t('dialog.addToApprovalListConfirmation.title')}
          description={t('dialog.addToApprovalListConfirmation.description', { agentEmail: values.email })}
          result="positive"
          buttonText={t('button.done')}
        />
      )}

      {!showConfirmationScreen && (
        <ActionModal
          title={t('dialog.addToApprovalList.title')}
          primaryButtonText={t('button.addEmail')}
          primaryBtnTestId="add-to-approval-list"
          secondaryButtonText={t('button.cancel')}
          handleClose={closeModal}
          handleSubmit={submitForm}
          loading={isLoading}
          disabled={!isValid || !dirty}
        >
          {showErrorMessage && (
            <Alert variant="error">
              {error?.code?.includes(agentIsOnAllowListErrorCode)
                ? t('dialog.addToApprovalList.agentIsOnAllowList')
                : t('dialog.somethingWentWrong')}
            </Alert>
          )}

          <Flex direction="column" gap={8}>
            <FormField
              label={t('typography.agentsEmail')}
              statusMessage={errors.email}
              status={errors.email ? 'danger' : 'default'}
              data-testid="agent-email-input"
            >
              <TextInput name="email" onChange={handleChange} />
            </FormField>
          </Flex>

          <Flex direction="column" gap={8} mt={16}>
            <FormField
              label={t('typography.organisationId')}
              statusMessage={errors.organisationId}
              status={errors.organisationId ? 'danger' : 'default'}
              data-testid="agent-organisationId-input"
            >
              <TextInput name="organisationId" onChange={handleChange} />
            </FormField>
          </Flex>
        </ActionModal>
      )}
    </Modal>
  )
}
