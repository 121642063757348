import { Alert, Badge, CardDashboard, CardContent, Spacer } from '@percent/lemonade'
import { VerificationSummaryCardProps } from './VerificationSummaryCard.types'

import {
  formatMonitoringMessage,
  formatStatusBannerMessage,
  formatValidationRequestFailedMessage,
  mapAgentVerificationStatus,
  mapAlertProps,
  mapComplainceStatus,
  mapEligibilityStatus,
  mapOrganisationStatus,
  mapOverallStatus
} from './utils'
import { useTranslation } from 'react-i18next'
import styles from './VerificationSummaryCard.module.scss'
import { useMemo } from 'react'

import { OrganisationTypes, StatusType } from '../../types'
import { CheckActions } from './CheckActions/CheckActions'
import { StatusBadge } from './StatusBadge/StatusBadge'
import { VerificationConfigurationSidebar } from '../VerificationConfigurationSidebar/VerificationConfigurationSidebar'

export const VerificationSummaryCard = ({
  verification,
  organisation,
  hasNoEligibleOrganisationType,
  isLoadingConfiguration,
  cardTitle,
  checksLinks,
  configuration,
  activityTags
}: VerificationSummaryCardProps) => {
  const { t } = useTranslation()

  // Organisation
  const organisationName =
    'organisationName' in verification
      ? verification.organisationName ?? verification.validationRequest?.name
      : verification.validationRequest?.name
  const organisationStatus = useMemo(() => mapOrganisationStatus(verification), [verification])

  const validationFailedMessage = useMemo(
    () =>
      formatValidationRequestFailedMessage({
        hasNoEligibleOrganisationType,
        validationRequest: verification?.validationRequest,
        t
      }),
    [hasNoEligibleOrganisationType, verification?.validationRequest, t]
  )

  // Eligibility
  const eligibility = verification.eligibility
  const eligibilityStatus = useMemo(() => eligibility && mapEligibilityStatus(eligibility), [eligibility])
  const rejectedEligibilityTags = eligibility?.results?.rejectedActivitySubTags ?? []

  // Agent
  const agentVerification = verification.agentVerification
  const agentVerificationStatus = useMemo(
    () => agentVerification && mapAgentVerificationStatus(agentVerification?.status),
    [agentVerification]
  )
  const agentEmail = agentVerification?.email
  // Monitoring
  const monitoring = verification.monitoring
  const monitoringStatus = useMemo(() => monitoring && mapComplainceStatus(monitoring), [monitoring])
  const organisationIsInactive = monitoring?.results?.registration?.active === 'no'
  const flaggedMonitoringKeys =
    monitoring?.results &&
    Object.keys(monitoring?.results).filter(key => {
      const value = monitoring?.results?.[key as keyof typeof monitoring.results]
      return value && typeof value === 'object' && 'status' in value && value.status === 'flag'
    })

  const monitoringMessage = useMemo(
    () => formatMonitoringMessage(organisationIsInactive, flaggedMonitoringKeys, t),
    [organisationIsInactive, flaggedMonitoringKeys, t]
  )

  // Overall
  const overallStatus = useMemo(() => mapOverallStatus(verification.status), [verification.status])

  const alertProps = useMemo(() => mapAlertProps(overallStatus), [overallStatus])

  const bannerMessage = useMemo(
    () =>
      formatStatusBannerMessage({
        overallStatus,
        agentEmail,
        configurationName: verification.configuration?.name,
        t
      }),
    [overallStatus, agentEmail, verification.configuration?.name, t]
  )

  const getOrganisationTypes = (): OrganisationTypes[] => {
    const organisationTypes = organisation?.types

    if (Array.isArray(organisationTypes)) return organisationTypes
    if (typeof organisationTypes === 'object') {
      return Object.keys(organisationTypes).filter(
        key => organisationTypes[key as OrganisationTypes] === 'yes'
      ) as OrganisationTypes[]
    }
    return []
  }

  return (
    <CardDashboard
      title={cardTitle ?? organisationName ?? ''}
      action={
        !isLoadingConfiguration &&
        configuration && (
          <VerificationConfigurationSidebar
            configuration={configuration}
            activityTags={activityTags}
            verificationSubTags={verification.eligibility?.results?.confirmedActivitySubTags?.map(subTag => subTag.id)}
          />
        )
      }
    >
      <Alert variant={alertProps.variant} title={bannerMessage} />

      <Spacer axis="horizontal" size={2} />
      <CardContent title={t('typography.organisation')} testId="verification-summary-organisation">
        <div className={styles.statusCheckWrapper}>
          <StatusBadge statusType={organisationStatus} />
          {hasNoEligibleOrganisationType &&
            getOrganisationTypes()
              .filter(
                type => !verification.configuration?.allowedOrganisations?.types?.includes(type as OrganisationTypes)
              )
              .map(type => (
                <>
                  <Spacer axis="horizontal" size={2} />
                  <Badge key={type} variant="default" icon="categories">
                    {t(`organisationTypes.${type as OrganisationTypes}`)}
                  </Badge>
                </>
              ))}
          <CheckActions
            jiraLink={checksLinks?.organisation?.jiraLink}
            goToCheckLink={checksLinks?.organisation?.goToCheckLink}
          />
        </div>
        {organisationStatus === StatusType.Rejected && validationFailedMessage}
      </CardContent>
      {!verification?.validationRequest?.rejectedAt && (
        <>
          {eligibility && (
            <CardContent title={t('typography.activity')} testId="verification-eligibility">
              <div className={styles.rejectedEligibilityTagsWrapper}>
                {eligibilityStatus && <StatusBadge statusType={eligibilityStatus} />}
                {rejectedEligibilityTags?.map(tag => (
                  <Badge key={tag.id} variant="default">
                    {tag.name}
                  </Badge>
                ))}
                <CheckActions
                  jiraLink={checksLinks?.eligibility?.jiraLink}
                  goToCheckLink={checksLinks?.eligibility?.goToCheckLink}
                  isGoToCheckDisabled={!organisation}
                />
              </div>
              {eligibilityStatus === StatusType.Rejected && t('typography.tagsNotEligible')}
              {eligibilityStatus === StatusType.CannnotDefine && t('typography.cannotDefineEligibility')}
            </CardContent>
          )}

          {monitoring && (
            <CardContent title={t('typography.compliance')} testId="verification-compliance">
              {monitoringStatus && (
                <div className={styles.statusCheckWrapper}>
                  <StatusBadge statusType={monitoringStatus} />
                  <CheckActions
                    jiraLink={checksLinks?.monitoring?.jiraLink}
                    goToCheckLink={checksLinks?.monitoring?.goToCheckLink}
                    isGoToCheckDisabled={!organisation}
                  />
                </div>
              )}
              {monitoringMessage}
            </CardContent>
          )}

          {agentVerification && (
            <CardContent title={t('typography.agentVerification')} testId="verification-agent-verification">
              {agentVerificationStatus && (
                <div className={styles.statusCheckWrapper}>
                  <StatusBadge statusType={agentVerificationStatus} />
                  <CheckActions
                    jiraLink={checksLinks?.agentVerification?.jiraLink}
                    goToCheckLink={checksLinks?.agentVerification?.goToCheckLink}
                    isGoToCheckDisabled={!organisation}
                  />
                </div>
              )}
              {agentVerification.rejectionReasonCode &&
                t(`agentVerificationRejectionReasons.${agentVerification.rejectionReasonCode}`)}
            </CardContent>
          )}
        </>
      )}
    </CardDashboard>
  )
}
