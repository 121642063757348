import { ReportingServiceCapabilities } from './reportingService.types'

export const reportingService = ({ percentClient }: ReportingServiceCapabilities) => ({
  getDisbursementBatchTrusteeReport: (disbursementBatchId: string) => (): Promise<string> =>
    percentClient
      .get(`v1/admin/disbursement-batches/${disbursementBatchId}/trustee-report`, {
        responseType: 'blob'
      })
      .then(({ data }) => data),
  getDisbursementBatchPaymentReport: (disbursementBatchId: string) => (): Promise<string> =>
    percentClient
      .get(`v1/admin/disbursement-batches/${disbursementBatchId}/payment-instructions`)
      .then(({ data }) => data)
})
