import { ReactNode } from 'react'
import styled from 'styled-components'
import { Table as MantineTable, ScrollArea } from '@mantine/core'
import { IllustratedMessage, Loader, TableHeader } from '@percent/lemonade'
import { useTableContext } from './context/table-provider'

export const StyledTableContainer = styled(MantineTable)`
  ${({ theme }) => `
    body {
      background-color: white;
    }

    th {
      color: black;
      font-weight: 500;
      background-color: white;
      font-size: ${theme.fontSizes.bodySmall};
      border-bottom: 1px solid ${theme.colors.gray80};
      padding: 14px 8px;

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }

    td {
      color: black;
      font-size: ${theme.fontSizes.bodySmall};
      padding: 14px 8px;

      &:first-child {
        padding-left: 16px;
      }

      &:last-child {
        padding-right: 16px;
      }
    }

    tr {
      border-bottom: 1px solid ${theme.colors.gray80};
      padding: 16px 0;
    }
  `}
`

const StyledNoResultsWrapper = styled.div`
  ${({ theme }) =>
    `
      margin-top: ${theme.sizes.xxl};
      margin-bottom: ${theme.sizes.xxl};
      word-break: break-word;
      overflow-wrap: break-word;
      white-space: normal;
    `}
`

const StyledLoadingWrapper = styled.div`
  padding: 100px 0;
`

export function Table({ children }: { children: ReactNode }) {
  const {
    isLoading,
    totalResults,
    illustration,
    emptyTableTitle,
    emptyTableDescription,
    columns,
  } = useTableContext()

  if (isLoading) {
    return (
      <StyledTableContainer>
        <TableHeader />
        <MantineTable.Tbody>
          <MantineTable.Tr>
            <MantineTable.Td colSpan={columns.length}>
              <StyledLoadingWrapper>
                <Loader isFullScreen={false} />
              </StyledLoadingWrapper>
            </MantineTable.Td>
          </MantineTable.Tr>
        </MantineTable.Tbody>
      </StyledTableContainer>
    )
  }

  if (totalResults === 0) {
    return (
      <StyledTableContainer>
        <StyledNoResultsWrapper>
          <IllustratedMessage
            illustration={illustration}
            title={emptyTableTitle}
            description={emptyTableDescription}
          />
        </StyledNoResultsWrapper>
      </StyledTableContainer>
    )
  }

  return (
    <ScrollArea>
      <StyledTableContainer>
        <TableHeader />
        {children}
      </StyledTableContainer>
    </ScrollArea>
  )
}
