import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockVrBlockListResponse } from '@percent/admin-dashboard/fixtures/api/mockVrBlockListResponse'

export const vrBlockListHandlers = [
  rest.get(`${config.urls.api}v1/admin/validation-requests/(blocklist)`, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockVrBlockListResponse))
  }),
  rest.post(`${config.urls.api}v1/admin/validation-requests/(blocklist)`, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
  rest.delete(`${config.urls.api}v1/admin/validation-requests/(blocklist)/:id`, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  })
]
