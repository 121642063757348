import EmailIcon from '@material-ui/icons/Email'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { getFailedDomainAutoChecks } from '../../utils/getFailedDomainAutoChecks'
import { FraudFlagsAlert } from '../fraudFlagsAlert/FraudFlagsAlert'

import { ReactComponent as FlagSVG } from '@percent/admin-dashboard/common/assets/images/tableStatus/flag.svg'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { getProperHref } from '@percent/admin-dashboard/common/utility/getProperHref'
import { getRegion } from '@percent/admin-dashboard/common/utility/getRegion'
import { Alert, Button, ButtonText, CardContent, CardDashboard, FlagAvatar, Spacer } from '@percent/lemonade'
import styles from './AgentVerificationOrganisationDetails.module.scss'
import { OrganisationDetailsProps } from './AgentVerificationOrganisationDetails.types'
import { ApproveDomainModal } from './approveDomain/ApproveDomainModal'
import { AutoDomainFraudChecksAlert } from './autoDomainFraudChecksAlert/AutoDomainFraudChecksAlert'
import { UpdateEmailModal } from './updateEmailModal/UpdateEmailModal'
import { UpdateWebsiteModal } from './updateWebsiteModal/UpdateWebsiteModal'

export function AgentVerificationOrganisationDetails({
  organisation,
  refresh,
  agentVerificationWebsite,
  agentVerification,
  partnerId,
  fraudFlags,
  agentVerificationLogs,
  autoApprovedSources,
  fraudChecks,
  isAutoFraudCheckEnabled
}: OrganisationDetailsProps) {
  const { t } = useTranslation()
  const { push } = useHistory()
  const [isModalOpened, setModalOpened] = useState(false)
  const [isWebsiteModalOpened, setIsWebsiteModalOpened] = useState(false)
  const [isApproveDomainModalOpened, setIsApproveDomainModalOpened] = useState(false)
  const { updateEmailFeatureFlag } = useFeatureFlag()
  const { state } = useLocation()

  const getDisabled = () => {
    if (!organisation?.email) {
      return true
    }

    if (['pending', 'pending_review'].includes(agentVerification?.status as string)) {
      if (state === 'external_actionable') {
        return true
      }

      return false
    }

    return false
  }

  const associationEmailSent = !!agentVerificationLogs?.find(log => log.type === 'association_email_sent')
  const failedAutoChecks = getFailedDomainAutoChecks(fraudChecks?.fraudChecks)

  return (
    <div className={styles.container}>
      <CardDashboard
        title={t('typography.organisationSectionTitle')}
        action={
          <Button
            size="small"
            variant="secondary"
            onPress={() => push(`/organizations/${organisation?.id}`, ['agentVerification'])}
          >
            {t('button.goToOrganisationDetails')}
          </Button>
        }
      >
        {isAutoFraudCheckEnabled && <AutoDomainFraudChecksAlert failedAutoChecks={failedAutoChecks} />}
        <FraudFlagsAlert title={t('typography.flaggedOrganization', { count: fraudFlags.length })} flags={fraudFlags} />
        {autoApprovedSources && autoApprovedSources.length > 0 && (
          <>
            <Alert variant="info" title={t('typography.domainsAutoApproved')}>
              <ul style={{ margin: 0, paddingLeft: 16 }}>
                {autoApprovedSources
                  .filter(source => source !== 'applicant_email')
                  .map(source => (
                    <li key={source}>{t(`typography.${source}`)}</li>
                  ))}
              </ul>
            </Alert>
            <Spacer size={4} />
          </>
        )}
        <CardContent title={t('typography.name')}>
          <span>{organisation?.name ?? t('typography.nullValue')}</span>
        </CardContent>
        <CardContent title={t('typography.email')}>
          <div className={styles.emailFieldWrapper}>
            <span>
              {organisation?.email ?? (
                <div className={styles.noEmail}>
                  <FlagSVG />
                  {t('typography.emailNeeded')}
                </div>
              )}
            </span>
            {updateEmailFeatureFlag && (
              <ButtonText onPress={() => setModalOpened(true)} icon="edit">
                {t(`button.${organisation?.email ? 'editEmail' : 'addEmail'}`)}
              </ButtonText>
            )}
          </div>
          {associationEmailSent && (
            <div className={styles.greenBadge}>
              <EmailIcon /> {t('typography.emailSentToNonprofit')}
            </div>
          )}
        </CardContent>

        <CardContent title={t('typography.website')}>
          <div className={styles.organisationFieldWrapper}>
            <div>
              {organisation?.website && (
                <a
                  href={getProperHref(String(organisation?.website))}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.websiteLink}
                >
                  {organisation?.website}
                </a>
              )}
              {!organisation?.website && agentVerificationWebsite && (
                <div className={styles.noWebsite}>
                  {t('typography.noWebsite')}
                  <br />
                  <br />
                  {t('typography.agentHasSuggested', {
                    website: agentVerificationWebsite
                  })}
                </div>
              )}
            </div>
            <div className={styles.websiteActionsWrapper}>
              {!['approved', 'rejected', 'pending_user_verification'].includes(agentVerification?.status as string) && (
                <div className={styles.approveButtonWrapper}>
                  <ButtonText onPress={() => setIsApproveDomainModalOpened(true)} disabled={getDisabled()}>
                    {t('button.approveDomains')}
                  </ButtonText>
                </div>
              )}

              <ButtonText onPress={() => setIsWebsiteModalOpened(true)} icon="edit">
                {t(`button.${organisation?.website ? 'editWebsite' : 'addWebsite'}`)}
              </ButtonText>
            </div>
          </div>
        </CardContent>

        <CardContent title={t('typography.country')}>
          <span className={styles.countryFieldWrapper}>
            {organisation?.countryCode && <FlagAvatar code3={organisation?.countryCode} />}
            {organisation?.countryCode ? getRegion(organisation?.countryCode) : t('typography.nullValue')}
          </span>
        </CardContent>
        <CardContent title={t('typography.registryId')}>
          <span>{organisation?.registryId ?? t('typography.nullValue')}</span>
        </CardContent>
      </CardDashboard>

      {isModalOpened && (
        <UpdateEmailModal
          organisation={organisation}
          isModalOpened={isModalOpened}
          setModalOpened={setModalOpened}
          refresh={refresh}
          agentVerificationId={agentVerification?.id as string}
        />
      )}
      {isWebsiteModalOpened && (
        <UpdateWebsiteModal
          organisation={organisation}
          isModalOpened={isWebsiteModalOpened}
          setModalOpened={setIsWebsiteModalOpened}
          refresh={refresh}
          agentVerificationId={agentVerification?.id as string}
        />
      )}
      {isApproveDomainModalOpened && (
        <ApproveDomainModal
          isModalOpened={isApproveDomainModalOpened}
          setModalOpened={setIsApproveDomainModalOpened}
          refresh={refresh}
          agentVerificationId={agentVerification?.id as string}
          organisation={organisation}
          agentVerificationWebsite={agentVerificationWebsite}
          claimsFlowTempFixData={{ agentVerification, partnerId }}
        />
      )}
    </div>
  )
}
