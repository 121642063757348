import styled from 'styled-components'
import { Icon } from '../icon'
import { AsyncSelectProps } from './async-select.types'
import { Combobox } from '@headlessui/react'
import { Spinner } from '../spinner'
import { Spacer } from '../spacer'

import {
  StyledSelect,
  StyledSelectTrigger,
  StyledSelectTriggerDropdown,
  StyledSelectList,
  StyledSelectOptions,
  StyledSelectAddOptionButton,
} from './components'
import { SelectOption } from './option.types'
import { useAsyncSelect } from './hooks/useAsyncSelect'

const StyledSelectLoading = styled.div`
  ${({ theme }) => `
    padding: ${theme.sizes[3]};
    color: ${theme.colors.gray900};
    display: flex;
    border-top: 1px solid ${theme.colors.gray80};
    align-items: center;
  `}
`

const compareOptions = (
  a: SelectOption | null,
  b: SelectOption | null
): boolean => a?.value.toLowerCase() === b?.value.toLowerCase()

const SelectBody = ({
  open,
  selectOptions,
  selectProps,
  dataTestId,
  disabled,
}: {
  open: boolean
  selectOptions: ReturnType<typeof useAsyncSelect>
  selectProps: AsyncSelectProps
  activeOption?: SelectOption
  dataTestId?: string
  disabled?: boolean
}) => {
  const { inputProps, renderElements, onAddButton, triggerProps } =
    selectOptions
  const {
    addOption,
    status = 'default',
    loading,
    loadingText = "We're loading the information",
    orientation = 'down',
  } = selectProps

  return (
    <StyledSelect>
      <StyledSelectTrigger
        $disabled={disabled}
        {...triggerProps}
        $status={status}
      >
        <Combobox.Input
          {...inputProps}
          maxLength={selectProps.maxLength}
          style={{ paddingRight: 0, pointerEvents: disabled ? 'none' : 'auto' }}
          data-testid={
            dataTestId ? `${dataTestId}-input` : 'async-select-input'
          }
        />
        <Combobox.Button
          style={{
            background: 'none',
            border: 'none',
            padding: '0',
            margin: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: disabled ? 'none' : 'auto',
          }}
          data-testid={
            dataTestId ? `${dataTestId}-toggle` : 'async-select-toggle'
          }
        >
          <StyledSelectTriggerDropdown
            style={{
              transform: open ? 'rotate(-180deg)' : '',
              transition: '0.1s all',
              background: 'transparent',
            }}
          >
            <Icon name="dropdown-arrow-down" size={8} color="gray600" />
          </StyledSelectTriggerDropdown>
        </Combobox.Button>
        <StyledSelectList $orientation={orientation}>
          {loading ? (
            <StyledSelectOptions>
              <Combobox.Options
                style={{
                  margin: 0,
                  padding: 0,
                }}
                static
              >
                <StyledSelectLoading>
                  <Spinner size={4} />
                  <Spacer size={2} /> {loadingText}
                </StyledSelectLoading>
              </Combobox.Options>
            </StyledSelectOptions>
          ) : (
            open && (
              <>
                <StyledSelectOptions>
                  <Combobox.Options
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                    static
                  >
                    {renderElements}
                  </Combobox.Options>
                </StyledSelectOptions>
                {addOption && (
                  <StyledSelectAddOptionButton
                    onMouseDown={() => {
                      onAddButton()
                      addOption.onClick()
                    }}
                  >
                    {!addOption.hideIcon && (
                      <div style={{ marginRight: '0.5rem' }}>
                        <Icon name="add-circle-solid" size="m" />
                      </div>
                    )}
                    <div
                      style={{
                        marginLeft: addOption.hideIcon ? '0.25rem' : '0',
                      }}
                    >
                      {addOption.label}
                    </div>
                  </StyledSelectAddOptionButton>
                )}
              </>
            )
          )}
        </StyledSelectList>
      </StyledSelectTrigger>
    </StyledSelect>
  )
}

export const AsyncSelect: React.FC<AsyncSelectProps> = (props) => {
  const {
    disabled,
    placeholder,
    noResultsFoundText,
    name,
    options,
    onChange,
    defaultValue,
    query = '',
    setQuery,
    loading: _,
    loadingText: __,
    addOption,
    'data-testid': dataTestId,
  } = props

  const select = useAsyncSelect({
    options,
    placeholder,
    defaultValue,
    addOptionKeepQuery: addOption?.keepQuery,
    addOptionLabel: addOption?.label,
    query,
    setQuery,
    noResultsFoundText,
  })

  return (
    <Combobox
      disabled={disabled}
      name={name}
      nullable
      value={select.value}
      by={compareOptions}
      defaultValue={defaultValue}
      onChange={(e) => {
        select.setValue(e)
        onChange?.(e)
      }}
    >
      {({ open }) => (
        <SelectBody
          open={open}
          dataTestId={dataTestId}
          selectOptions={select}
          selectProps={props}
          disabled={disabled}
        />
      )}
    </Combobox>
  )
}
