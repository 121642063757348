import { TableCell, TableRow } from '@material-ui/core'
import { ChangeEvent, EventHandler, KeyboardEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AddToAutoApprovalListModal } from '../addToAutoApprovalListModal/AddToAutoApprovalListModal'
import { RemoveFromAutoApprovalListModal } from '../removeFromAutoApprovalListModal/RemoveFromAutoApprovalListModal'

import { AutoApprovalListItem } from '@percent/admin-dashboard/api/actions/autoApprovalList/autoApprovalList.types'
import { Table } from '@percent/admin-dashboard/common/components'
import { Button, ButtonText, SearchInput, Spacer } from '@percent/lemonade'
import { AutoApprovalListTableProps } from './AutoApprovalListTable.types'

export function AutoApprovalListTable({
  data,
  isLoading,
  nextPage,
  previousPage,
  totalResults,
  errorMessage,
  queryParams,
  setQueryParams,
  refresh
}: Readonly<AutoApprovalListTableProps>) {
  const { t } = useTranslation()

  const [isAddToApprovalListModalOpen, setIsAddToApprovalListModalOpen] = useState(false)
  const [selectedAutoApprovalEntry, setSelectedAutoApprovalEntry] = useState<AutoApprovalListItem>()
  const [searchValue, setSearchValue] = useState<string>(queryParams.email || '')

  const cells = [
    { id: 'email', isSortable: false },
    { id: 'organisationId', isSortable: false },
    { id: 'actions', isSortable: false }
  ]

  const handleKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      const searchTerm = (event.target as HTMLInputElement).value

      return event.key === 'Enter'
        ? searchTerm.trim() === ''
          ? setQueryParams({ email: undefined })
          : setQueryParams({
              email: searchTerm
            })
        : null
    },
    [setQueryParams]
  )

  const handleClearValue = useCallback(() => {
    setSearchValue('')
    setQueryParams({ email: undefined })
  }, [setQueryParams])

  const openAddToAutoApprovalListModal = () => setIsAddToApprovalListModalOpen(true)

  const filters = useMemo(
    () => (
      <>
        <SearchInput
          value={searchValue ?? ''}
          handleClearValue={handleClearValue}
          onKeyDown={handleKeyPress}
          onChange={handleKeyChange}
          placeholder={t('table.autoApprovalListSearchPlaceholder')}
        />
        <Spacer size={4} axis="horizontal" />
        <Button onPress={openAddToAutoApprovalListModal}>+ {t('button.addToAutoApprovalList')}</Button>
      </>
    ),
    [searchValue, handleClearValue, handleKeyPress, t]
  )

  return (
    <>
      <Table
        isLoading={isLoading}
        totalResults={errorMessage ? 0 : totalResults || 0}
        data={data}
        previousPage={previousPage}
        nextPage={nextPage}
        columns={cells}
        filtersContent={filters}
        emptyTableText={t('table.emptyAutoApprovalList')}
      >
        {data?.map(row => (
          <TableRow key={row.id}>
            <TableCell>{row.email}</TableCell>
            <TableCell>{row.organisationId}</TableCell>
            <TableCell>
              <ButtonText icon="reject" onPress={() => setSelectedAutoApprovalEntry(row)}>
                {t('button.remove')}
              </ButtonText>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <AddToAutoApprovalListModal
        isOpen={isAddToApprovalListModalOpen}
        setIsOpen={setIsAddToApprovalListModalOpen}
        refresh={refresh}
      />
      <RemoveFromAutoApprovalListModal
        autoApprovalEntry={selectedAutoApprovalEntry}
        setAutoApprovalEntry={setSelectedAutoApprovalEntry}
        refresh={refresh}
      />
    </>
  )
}
