import { createContext } from 'react'
import { Illustration } from '../illustrated-message/illustrated-message.types'

export type ColumnProps = {
  width: string
}

export type TableColumn = {
  id: string
  content?: React.ReactNode
  isSortable?: boolean
  props?: ColumnProps
}

export type TableColumnSortDirection = 'ASC' | 'DESC'

export type TableContextType = {
  columns: TableColumn[]
  totalResults: number | null
  isLoading: boolean
  illustration: Illustration
  emptyTableTitle: string
  emptyTableDescription: string
  tableHeadTestId?: string
  handleRequestSort?: (columnKey: string) => void
  sortDirection?: TableColumnSortDirection
  sortByColumn?: string
}

export const TableContext = createContext<TableContextType | undefined>(
  undefined
)

export type PaginationActionsProps = {
  totalResultsText?: string
  previousPage: (() => void) | null
  nextPage: (() => void) | null
}
