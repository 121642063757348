import { memo, useMemo } from 'react'
import isEqual from 'react-fast-compare'
import { useTranslation } from 'react-i18next'

import styles from '../ValidationSubmissionsTable.module.scss'
import { ValidationSubmissionFiltersProps } from '../ValidationSubmissionsTable.types'
import {
  useCountryFilter,
  usePartnerNameFilter,
  useProgramNameFilter,
  useStatusesFilter,
  useValidationSubmissionSearch
} from '../hooks'

import { Chip, FilterList, SearchFilterList, SearchInput } from '@percent/lemonade'

export const ValidationSubmissionsFilters = memo(
  ({ queryParams, setQueryParams }: ValidationSubmissionFiltersProps) => {
    const { t } = useTranslation()

    const { statusesOptions, onStatusChange, selectedStatuses } = useStatusesFilter({ queryParams, setQueryParams })
    const { searchValue, handleClearValue, handleKeyChange, handleKeyPress } = useValidationSubmissionSearch({
      queryParams,
      setQueryParams
    })
    const { selectedPartners, onPartnerChange, partnerNameOptions, isPartnerNameFilterLoading, partnerSearch } =
      usePartnerNameFilter({
        queryParams,
        setQueryParams
      })
    const { countryOptions, selectedCountries, onCountryChange, countrySearch } = useCountryFilter({
      queryParams,
      setQueryParams
    })
    const { programNameOptions, onProgramChange, selectedPrograms, isSelectedProgramsFilterDisabled } =
      useProgramNameFilter({
        queryParams,
        setQueryParams
      })

    const partnerNameChipValue = useMemo(
      () => partnerNameOptions?.find(option => option.value === queryParams.partnerId)?.label,
      [queryParams.partnerId, partnerNameOptions]
    )

    const countryChipValue = useMemo(
      () =>
        countryOptions
          .filter(option => queryParams.countryCodes?.includes(option.value))
          .map(option => option.label)
          .join(', '),
      [queryParams.countryCodes, countryOptions]
    )

    return (
      <div className={styles.filtersAndChipsContainer}>
        <div className={styles.filtersContainer}>
          <SearchInput
            value={searchValue ?? ''}
            handleClearValue={handleClearValue}
            onKeyDown={handleKeyPress}
            onChange={handleKeyChange}
            placeholder={t('table.searchByAgentsEmailOrSpecificCheck')}
          />
          <div className={styles.filtersWrapper}>
            <SearchFilterList
              options={countryOptions}
              selectedValues={selectedCountries}
              onChange={onCountryChange}
              searchValue={countrySearch.countrySearchValue}
              onSearchChange={countrySearch.onCountrySearchChange}
              handleClearValue={countrySearch.onClearCountrySearchValue}
              onKeyDown={countrySearch.handleKeyPress}
              onButtonClick={countrySearch.onCountrySearchButtonClick}
              testId="country-search-filter"
              buttonText={t('typography.search')}
              placeholder={t('typography.searchByCountry')}
              label={t('typography.country')}
              placement="bottom"
            />
            <SearchFilterList
              options={partnerNameOptions}
              isLoading={isPartnerNameFilterLoading}
              selectedValues={selectedPartners}
              onChange={onPartnerChange}
              searchValue={partnerSearch.partnerNameSearch}
              onSearchChange={partnerSearch.onPartnerSearchChange}
              handleClearValue={partnerSearch.onClearPartnerSearchValue}
              onKeyDown={partnerSearch.handleKeyPress}
              onButtonClick={partnerSearch.onPartnersSearchButtonClick}
              testId="partner-name-search-filter"
              buttonText={t('typography.search')}
              placeholder={t('typography.search')}
              label={t('typography.partner')}
              placement="bottom"
            />
            <FilterList
              label={t('typography.programName')}
              options={programNameOptions}
              selectedValues={selectedPrograms}
              onChange={onProgramChange}
              isDisabled={isSelectedProgramsFilterDisabled}
              tooltipContent={t('typography.programNameTooltip')}
              testId="program-name-filter"
            />
            <FilterList
              label={t('typography.overallResult')}
              options={statusesOptions}
              selectedValues={selectedStatuses}
              onChange={onStatusChange}
              testId="overall-result-filter"
            />
          </div>
        </div>
        <div className={styles.filtersChipContainer}>
          {queryParams.partnerId && (
            <Chip
              label={t('typography.partner')}
              value={partnerNameChipValue}
              onClick={() => onPartnerChange([])}
              testId="partner-filter-chip"
            />
          )}
          {!!queryParams.countryCodes?.length && (
            <Chip
              label={t('typography.country')}
              value={countryChipValue}
              onClick={() => {
                onCountryChange([])
                setQueryParams({ ...queryParams, countryCodes: [] })
              }}
              testId="country-filter-chip"
            />
          )}
        </div>
      </div>
    )
  },
  isEqual
)
