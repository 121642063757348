import type { AxiosInstance } from 'axios'
import type { Alpha3Code } from 'i18n-iso-countries'

import type {
  AgentVerificationState,
  AgentVerificationStatus
} from '@percent/admin-dashboard/api/actions/admin/admin.types'
import type { OrganisationTypes } from '@percent/admin-dashboard/constants/organisationTypes'
import { AgentVerificationStatus as BEAgentVerificationStatus } from '@percent/domain/verifications'

export type agentVerificationServiceCapabilities = {
  percentClient: AxiosInstance
}

export type activityCheckServiceCapabilities = {
  percentClient: AxiosInstance
}

export type AgentVerificationList = {
  id: string
  firstName: string
  lastName: string
  createdAt: string
  email: string
  status: AgentVerificationStatus
  userVerified: boolean
  partner: {
    name: string
  }
  validationRequestId?: string
  organisationId?: string
  title?: string
  rejectionReason?: string
  organisation: {
    name: string
    countryCode: Alpha3Code
  }
  validationRequest?: {
    name: string
  }
  emailVerifiedAt?: string
  reviewedBy?: string
  agentVerificationLogs?: AgentVerificationLog[]
}

export type GetAgentVerificationsProps = {
  page?: number
  pageSize?: number
  cursor?: string | null
  startDate?: string | null
  endDate?: string | null
  sort?: string | null
  status?: AgentVerificationStatus
  state?: AgentVerificationState
}

export type AgentVerification = {
  createdAt: string
  email: string
  emailVerifiedAt: string | null
  firstName: string
  id: string
  lastName: string
  configurationName: string | null
  configurationId: string
  language: string
  organisationId: string
  organisationWebsite: string | null
  partnerId: string
  rejectionReasonCode: AgentVerificationRejectionReasonDropdownCodes | null
  reviewedBy: string | null
  status: AgentVerificationStatus
  title: string
  updatedAt: string
  validationRequest: string | null
  validationRequestId: string
  validationSubmissionId: string | null
  externalActionRequired: boolean
  fraudulentFlags?: FraudulentFlag[]
  configuration?: {
    allowedOrganisations?: {
      types?: OrganisationTypes[]
    }
    isStatusDynamic?: boolean
  }
}

export enum AgentVerificationRejectionReasonDropdownCodes {
  UserFailedPercentReview = 'user_failed_percent_review',
  Reapply = 'reapply',
  FakeEmailUsed = 'fake_email_used',
  NoOnlinePresence = 'no_online_presence',
  InvalidDocumentation = 'invalid_documentation',
  BlankCorruptedDocumentation = 'blank_corrupted_documentation',
  MismatchedInformation = 'mismatched_information',
  FakeDocumentation = 'fake_documentation',
  SuspiciousApplication = 'suspicious_application',
  Other = 'other'
}

export enum AgentVerificationApprovalReasonDropdownCodes {
  AvDocumentAcceptable = 'av_document_acceptable',
  GoodstackUpdate = 'goodstack_update'
}

export enum AgentVerificationRejectionReasonBackendCodes {
  ValidationRequestFailed = 'validation_request_failed',
  UserVerificationExpired = 'user_verification_expired',
  NonprofitAssociationExpired = 'nonprofit_association_expired'
}

export type AgentVerificationRejectionReasonCodes =
  | AgentVerificationRejectionReasonDropdownCodes
  | AgentVerificationRejectionReasonBackendCodes

export type RejectAgentVerificationArgs = {
  id: string
  rejectionReasonCode: AgentVerificationRejectionReasonDropdownCodes
  fraudulentFlags?: string[]
}

export type ApproveAgentVerificationArgs = {
  id: string
  approvalReasonCode?: AgentVerificationApprovalReasonDropdownCodes
}

export type FraudulentFlagScope = 'organisation' | 'agent'
export type FraudulentFlagCode =
  | 'organisation_website_flagged_third_party_site'
  | 'organisation_website_suspicious_website_content'
  | 'organisation_website_flagged_registrar'
  | 'organisation_website_newly_created_domain'
  | 'organisation_website_suspicious_url'
  | 'organisation_email_newly_created_domain'
  | 'organisation_email_flagged_registrar'
  | 'agent_suspicious_document'
  | 'agent_suspicious_email'
  | 'agent_reused_email'
  | 'agent_newly_created_domain'
  | 'agent_unrelated_language'

export type FraudulentFlag = {
  id: string
  code: FraudulentFlagCode
  scope: FraudulentFlagScope
}

export type FraudulentFlagsResponse = {
  data: FraudulentFlag[]
}

export type AgentVerificationLogType =
  | 'association_checks_approved'
  | 'association_checks_rejected'
  | 'identity_checks_approved'
  | 'identity_checks_rejected'
  | 'manually_approved'
  | 'manually_rejected'
  | 'association_checks_expired'
  | 'identity_checks_expired'
  | 'organisation_domain_change'
  | 'organisation_email_change'
  | 'domain_verified'
  | 'domain_matched'
  | 'domain_not_matched'
  | 'email_domain_matched'
  | 'email_domain_not_matched'
  | 'agent_verification_created'
  | 'agent_email_common_domain_detected'
  | 'association_email_sent'
  | 'association_email_failed_to_send'
  | 'association_email_accepted'
  | 'association_email_rejected'
  | 'association_email_expired'
  | 'inbox_verification_email_sent'
  | 'inbox_verification_failed_to_send'
  | 'inbox_verification_confirmed'
  | 'domain_verification_automated_pass'
  | 'domain_verification_automation_failed'
  | 'domain_verification_flags_detected'
  | 'domain_verification_start_failed'
  | 'domain_verification_started'
  | 'validation_request_rejected'
  | 'validation_request_accepted'
  | 'found_email_previous_approval'
  | 'agent_verification_document_request_email_sent'
  | 'agent_verification_document_request_email_failed_to_send'
  | 'agent_verification_document_uploaded'
  | 'validation_submission_failed'
  | 'email_found_on_auto_reject_list'
  | 'domain_found_on_auto_reject_list'
  | 'domain_auto_verified'
  | 'domain_verification_automation_requested'
  // Dynamic AV logs
  | 'approve_rejected_dynamic_agent_verification'
  | 'reject_approved_dynamic_agent_verification'
  | 'claim_auto_rejected'

export type AgentVerificationLog<T extends Record<string, unknown> = Record<string, unknown>> = {
  id: string
  agentVerificationId: string
  type: AgentVerificationLogType
  createdAt: string
  data: T
}

export type AgentVerificationLogsResponse = AgentVerificationLog[]

export type AgentVerificationSimilar = {
  id: string
  email: string
  createdAt: string
  status: BEAgentVerificationStatus
  rejectionReasonCode: AgentVerificationRejectionReasonCodes | null
  organisationId: string | null
  organisationName: string | null
  partnerName: string | null
}

export type AgentVerificationSimilarResponse = AgentVerificationSimilar[]

export type FraudCheckResult = 'pass' | 'fail' | 'needs_review'
type AnalysedDomainSource = 'organisation_website' | 'applicant_email' | 'organisation_email' | 'suggested_website'

type AnalysedDomain = {
  domain: string
  source: AnalysedDomainSource | AnalysedDomainSource[]
}

export type ApplicationFraudCheck = {
  type: 'application_check'
  data:
    | {
        checkName: 'detect_minor_typos'
        emails: {
          agent: string
          organisation: string
          distance: number
        } | null
        websites: {
          agent: string
          organisation: string
          distance: number
        } | null
      }
    | {
        checkName: 'has_applied_for_different_countries'
        countries: string[]
        applicationCountry: string | undefined
      }
    | {
        checkName: 'has_too_many_applications_across_partners'
        count: number
      }
    | {
        checkName: 'language_match'
        analysis: string | null
        applicationLanguage: string | null
      }
}

export type BasicDomainFraudCheck = {
  type: 'basic_domain_check'
  data:
    | {
        checkName: 'disallowed_domain_list'
        analysedDomains: (AnalysedDomain & { isDisallowed: boolean })[]
      }
    | {
        checkName: 'has_website_hosting_provider_subdomain'
        analysedDomains: (AnalysedDomain & { hasWebsiteHostingProviderSubdomain: boolean })[]
      }
    | {
        checkName: 'can_website_be_opened'
        analysedDomains: (AnalysedDomain & { canBeOpened: boolean })[]
      }
    | {
        checkName: 'is_website_empty'
        analysedDomains: (AnalysedDomain & { isWebsiteEmpty: boolean })[]
      }
    | {
        checkName: 'has_website_placeholder_content'
        analysedDomains: (AnalysedDomain & { hasWebsitePlaceholderContent: boolean })[]
      }
    | {
        checkName: 'has_website_redirect'
        analysedDomains: (AnalysedDomain & { hasWebsiteRedirect: boolean })[]
      }
    | {
        checkName: 'is_ssl_certificate_valid'
        analysedDomains: (AnalysedDomain & { isSslCertificateValid: boolean })[]
      }
}

type GeolocationFlag = 'suspicious_asn' | 'geolocation_lookup_failed'
type WhoIsFlag = 'recently_registered_domain' | 'suspicious_registrar' | 'whois_lookup_failed'

export type AdvancedDomainFraudCheck = {
  type: 'advanced_domain_check'
  data:
    | {
        checkName: 'geolocation_check'
        analysedDomains: (AnalysedDomain & { flags: GeolocationFlag[]; asn?: number })[]
      }
    | {
        checkName: 'who_is_check'
        analysedDomains: (AnalysedDomain & { flags: WhoIsFlag[]; registrar?: string; registeredDate?: string })[]
      }
}

export type FraudCheck = {
  id: string
  result: 'pass' | 'fail'
  validationSubmissionId: string
} & (ApplicationFraudCheck | BasicDomainFraudCheck | AdvancedDomainFraudCheck)
export type FraudCheckType = FraudCheck['type']

export type FraudCheckResultsResponse = {
  result: FraudCheckResult | null
  checkResults: FraudCheck[]
  isMarkedAsSafe: boolean
}

type GeoLocationRecord = Partial<{
  ip: string
  isp: string
  location: {
    country: string
    region: string
    city: string
  }
  as: {
    asn: string
  }
}>

type WhoisRecord = Partial<{
  registrarName: string
  createdDate: string
  estimatedDomainAge: number
}>

export type DomainVerificationFlag =
  | 'whois_lookup_failed'
  | 'recently_registered_domain'
  | 'suspicious_registrar'
  | 'geolocation_lookup_failed'
  | 'suspicious_asn'
  | 'unknown_website_accessibility'
  | 'inaccessible_website'
  | 'ssl_certificate_issues'
  | 'suspicious_redirects'
  | 'empty_website'
  | 'placeholder_content'
  | 'hosting_provider_subdomain'

export type GlobalFlag =
  | 'multiple_applications_with_same_email'
  | 'language_does_not_match_country'
  | 'suspicious_values_detected_from_openai'
  | 'no_organisation_website'
  | 'applicant_email_common_domain'
  | 'organisation_email_common_domain'
  | 'domain_analysis_failed'

export type DomainSource = 'organisation_website' | 'applicant_email' | 'organisation_email' | 'suggested_website'

export type DomainVerification = {
  source: DomainSource
  domain?: string
  whoisData?: WhoisRecord | null
  geoData?: GeoLocationRecord | null
  flags?: DomainVerificationFlag[]
}

export type LanguageMatchResponse = {
  match: boolean
  analysis: string
}

export type FraudIndicatorsResponse = {
  suspicious: boolean
  suspiciousIndicators: string[]
  analysis: string
}

export type OpenAiAnalysis = {
  languageMatch: LanguageMatchResponse
  fraudIndicators: FraudIndicatorsResponse
}

export type DomainVerificationResult = {
  globalFlags: GlobalFlag[]
  domains: DomainVerification[]
  openAiAnalysis: OpenAiAnalysis
}

export type DomainVerificationResponse = Partial<{
  id: string
  agentVerificationId: string
  result: DomainVerificationResult
}>
