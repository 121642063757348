import React, { useState } from 'react'
import { Card, Divider, Modal, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import styles from './BankDetailsDialog.module.scss'
import { ReactComponent as CloseIcon } from '@percent/admin-dashboard/common/assets/images/close_gray.svg'
import { ErrorView, Loader, PercentButton } from '@percent/admin-dashboard/common/components'
import { BankDetailsDialogProps } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsDialog/BankDetailsDialog.types'
import { ReactComponent as SuccessIcon } from '@percent/admin-dashboard/common/assets/images/success.svg'
import { Button, Spacer } from '@percent/lemonade'

export function BankDetailsDialog({
  open,
  onClose,
  organisationName,
  organisationId,
  refresh,
  formStatus,
  refreshTimeline
}: BankDetailsDialogProps) {
  const { t } = useTranslation()
  const [isSuccess, setIsSuccess] = useState(false)
  const { bankDetailsService } = useServices()

  const [{ isLoading, errorMessage }, { apiFunc }] = useMutation(bankDetailsService.updateBankDetails, async () => {
    setIsSuccess(true)
  })

  const [
    { isLoading: isRemoveBankDetailsLoading, errorMessage: removeBankDetailsErrorMessage },
    { apiFunc: removeBankDetailsFunc }
  ] = useMutation(bankDetailsService.removeBankDetails, async () => {
    setIsSuccess(true)
  })

  const handleUpdate = async () => {
    if (formStatus === 'deleted') {
      await removeBankDetailsFunc({ organisationId })
    }

    if (formStatus === 'approved' || formStatus === 'rejected') {
      await apiFunc({ status: formStatus, organisationId })
    }
  }

  const handleCloseModal = () => {
    onClose()
    refresh()
    refreshTimeline()
    setIsSuccess(false)
  }

  const statusToDisplay = (status: 'rejected' | 'approved' | 'deleted' | null) =>
    status === 'deleted' ? 'removed' : status

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="bank-details-verification-approve-modal">
      <div className={styles.modal}>
        {isSuccess ? (
          <Card className={styles.wrapperSuccess}>
            <SuccessIcon className={styles.icon} />
            <Typography className={styles.successTitle}>
              {t('dialog.bankDetails.successHeader', { status: statusToDisplay(formStatus) })}
            </Typography>
            <Typography>
              {t('dialog.bankDetails.successDescription', { organisationName, status: statusToDisplay(formStatus) })}
            </Typography>
            <PercentButton
              title={t('button.close')}
              onClick={handleCloseModal}
              className={styles.button}
              testId="bank-details-dialog-success-close-button"
            />
          </Card>
        ) : (
          <Card className={styles.wrapper}>
            <div className={styles.titleWrapper}>
              <Typography className={styles.title}>
                {t('dialog.bankDetails.header', {
                  status: t(`dialog.bankDetails.${formStatus as 'rejected' | 'approved' | 'deleted'}`)
                })}
              </Typography>
              <CloseIcon className={styles.closeIcon} onClick={onClose} />
            </div>
            <Divider className={styles.divider} />
            <Typography className={styles.textWrapper}>
              {t('dialog.bankDetails.description', {
                organisationName,
                status: statusToDisplay(formStatus)
              })}
            </Typography>
            {(errorMessage || removeBankDetailsErrorMessage) && (
              <ErrorView errorMessage={errorMessage || removeBankDetailsErrorMessage} />
            )}
            <Spacer size={4} axis="vertical" />
            {isLoading || isRemoveBankDetailsLoading ? (
              <Loader />
            ) : (
              <div className={styles.buttonWrapper}>
                <Button variant="reject" onPress={onClose} data-testid="bank-details-dialog-cancel-button">
                  {t('button.cancel')}
                </Button>
                <Button type="submit" onPress={handleUpdate} data-testid="bank-details-dialog-confirm-button">
                  {t(`dialog.bankDetails.${formStatus as 'rejected' | 'approved' | 'deleted'}`)}
                </Button>
              </div>
            )}
          </Card>
        )}
      </div>
    </Modal>
  )
}
