import { Grid, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import styles from '../BankDetailsView.module.scss'

import { ListItemButton } from '@percent/admin-dashboard/common/components/listItemButton/ListItemButton'
import { ReactComponent as PencilSVG } from '@percent/admin-dashboard/common/assets/images/pencil.svg'
import { BankDetailsSectionProps } from './BankDetailsAccountSection.types'
import { BankDetailsValidationErrors, getBankDetailsFields, WireTransferType } from '@percent/domain/bank-details'
import { IsValidatedStatus } from './isValidatedStatus/isValidatedStatus'
import { Alert, Button } from '@percent/lemonade'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { BankDetailsValidationPayload } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'

export function BankDetailsAccountSection({
  data,
  enableEdit,
  disableApprove,
  bankDetailsSubmitFeatureFlag,
  reviewId,
  validationFeatureFlag,
  onEdit
}: BankDetailsSectionProps) {
  const { t } = useTranslation()
  const { bankDetailsService } = useServices()

  const wireTransferDetails = data?.bankAccountDetails?.wireTransferDetails
  const type = wireTransferDetails?.type
  const fields = wireTransferDetails ? getBankDetailsFields(wireTransferDetails) : []
  const isValidated = !!data.bankValidationData
  const isValid = !!data?.bankValidationData?.success
  const hasPaymentDetails = data.bankAccountDetails?.wireTransferDetails && data.bankAccountDetails?.holderName
  const hasValidationErrors =
    !!data?.bankValidationData?.validationErrors && data?.bankValidationData?.validationErrors?.length > 0

  const bankDataTranslations = useMemo(
    () => ({
      invalidDataTitle: t('bankDetails.validation.invalidDataTitle'),
      invalidDataDescription: t('bankDetails.validation.invalidDataDescription'),
      unknownValidationError: t('bankDetails.validation.unknownErrorReadOnly')
    }),
    [t]
  )

  function createPaymentDetails(): BankDetailsValidationPayload['paymentDetails'] {
    const payload = data.bankAccountDetails?.wireTransferDetails

    if (!payload) throw new Error('Missing wire transfer details')

    switch (payload.type) {
      case WireTransferType.IBAN:
        return {
          type: WireTransferType.IBAN,
          iban: payload.iban!.value,
          bic: payload.bic!.value
        }
      case WireTransferType.DOMESTIC_WITH_BIC:
        return {
          type: WireTransferType.DOMESTIC_WITH_BIC,
          accountNumber: payload.accountNumber!.value,
          bic: payload.bic!.value,
          bankCode: payload.bankCode!.value,
          branchCode: payload.branchCode!.value
        }
      case WireTransferType.DOMESTIC:
        return {
          type: WireTransferType.DOMESTIC,
          accountNumber: payload.accountNumber!.value,
          bankCode: payload.bankCode!.value
        }
      default:
        throw new Error('Invalid payment details type')
    }
  }

  const [{ isLoading: isValidationLoading }, { apiFunc: fetchBankData }] = useMutation(
    bankDetailsService.fetchBankData,
    async () => {
      window.location.reload()
    }
  )

  const validateButton = (
    <Button
      onPress={() =>
        fetchBankData({
          holderName: data.bankAccountDetails?.holderName ?? 'Default holder name',
          paymentDetails: createPaymentDetails(),
          organisationId: data.organisationId,
          bankDetailsReviewRequestId: reviewId
        })
      }
      type="button"
      variant="primary"
      loading={isValidationLoading}
      data-testid="validate-bank-details-button"
      size="small"
    >
      {t('typography.validate')}
    </Button>
  )

  return (
    <Grid
      container
      spacing={0}
      component={Paper}
      elevation={0}
      className={styles.listContainer}
      id="bankDetailsSection"
    >
      <Grid item xs={12} className={styles.title}>
        <div className={styles.titleContainer}>
          <Typography variant="h6">{t('typography.bankDetails')}</Typography>
        </div>
        {enableEdit && bankDetailsSubmitFeatureFlag && (
          <ListItemButton
            className={styles.editButton}
            title={disableApprove ? t(`button.addDetails`) : t(`button.edit`)}
            onClick={onEdit}
            icon={<PencilSVG />}
            testId="bank-details-edit-button"
          />
        )}
      </Grid>
      {validationFeatureFlag && !isValid && isValidated && hasValidationErrors && (
        <Grid item xs={12} className={styles.errorBox}>
          <BankDetailsValidationErrors
            bankData={data.bankValidationData}
            fields={fields.map(f => ({ field: f.key, label: f.label }))}
            translations={bankDataTranslations}
            variant="body2"
          />
        </Grid>
      )}
      {validationFeatureFlag && !isValid && isValidated && !hasValidationErrors && (
        <Grid item xs={12} className={styles.errorBox}>
          <Alert
            variant="error"
            title={t('bankDetails.validation.invalidDataTitle')}
            descriptionVariant="div"
            actions={hasPaymentDetails ? validateButton : undefined}
          >
            <div className={styles.alertContent}>
              <Typography variant="body2" color="inherit">
                {t('bankDetails.validation.unknownErrorReadOnly')}
              </Typography>
            </div>
          </Alert>
        </Grid>
      )}
      {validationFeatureFlag && !isValidated && (
        <Grid item xs={12} className={styles.errorBox}>
          <Alert
            variant="error"
            title={t('bankDetails.validation.missingValidation')}
            descriptionVariant="div"
            actions={hasPaymentDetails ? validateButton : undefined}
          >
            <div className={styles.alertContent}>
              <Typography variant="body2" color="inherit">
                {t('bankDetails.status.allNotValidated')}
              </Typography>
            </div>
          </Alert>
        </Grid>
      )}
      <ul className={styles.bankDetailsList}>
        <li>
          <p>{t('typography.accountHolderName')}</p>
          <span data-testid="holder-value">{data?.bankAccountDetails?.holderName || t('typography.infoNotAdded')}</span>
        </li>
        {(type === WireTransferType.DOMESTIC || type === WireTransferType.IBAN) &&
          fields.map(field => (
            <li>
              <p>{field.label}</p>
              <span data-testid={`${field.key}-value`}>{field.value}</span>

              {validationFeatureFlag && (
                <>
                  {field.key === 'bankCode' && (
                    <IsValidatedStatus data={data} field={field.label} fieldKey={field.key} />
                  )}
                  {field.key === 'bic' && <IsValidatedStatus data={data} field={field.label} fieldKey={field.key} />}
                </>
              )}
            </li>
          ))}
        {(data?.requiredBankAccountDetails?.type || type) === WireTransferType.DOMESTIC_WITH_BIC && (
          <>
            <li>
              <p>
                {data?.bankAccountDetails?.wireTransferDetails?.accountNumber?.label || t('typography.accountNumber')}
              </p>
              <span data-testid="accountNumber-value">
                {data?.bankAccountDetails?.wireTransferDetails?.accountNumber?.value || t('typography.infoNotAdded')}
              </span>
            </li>
            <li>
              <p>{data?.bankAccountDetails?.wireTransferDetails?.bic?.label || t('typography.bicSwiftCode')}</p>
              <span data-testid="bic-value">
                {data?.bankAccountDetails?.wireTransferDetails?.bic?.value || t('typography.infoNotAdded')}
              </span>
              {validationFeatureFlag && data?.bankAccountDetails?.wireTransferDetails?.bic?.value && (
                <IsValidatedStatus
                  data={data}
                  field={data?.bankAccountDetails?.wireTransferDetails?.bic?.label}
                  fieldKey="bic"
                />
              )}
            </li>
            {data?.bankAccountDetails?.wireTransferDetails?.bankCode && (
              <li>
                <p>{data?.bankAccountDetails?.wireTransferDetails?.bankCode?.label}</p>
                <span data-testid="bankCode-value">
                  {data?.bankAccountDetails?.wireTransferDetails?.bankCode?.value}
                </span>
                {validationFeatureFlag && !!data?.addBankDetailsFormLabels?.fields?.bankCode?.required && (
                  <IsValidatedStatus
                    data={data}
                    field={data?.bankAccountDetails?.wireTransferDetails?.bankCode?.label}
                    fieldKey="bankCode"
                  />
                )}
              </li>
            )}
            {data?.bankAccountDetails?.wireTransferDetails?.branchCode && (
              <li>
                <p>{data?.bankAccountDetails?.wireTransferDetails?.branchCode?.label}</p>
                <span data-testid="branchCode-value">
                  {data?.bankAccountDetails?.wireTransferDetails?.branchCode?.value}
                </span>

                {validationFeatureFlag && !!data?.addBankDetailsFormLabels?.fields?.branchCode?.required && (
                  <IsValidatedStatus
                    data={data}
                    field={data?.bankAccountDetails?.wireTransferDetails?.branchCode?.label}
                    fieldKey="branchCode"
                  />
                )}
              </li>
            )}
          </>
        )}
      </ul>
    </Grid>
  )
}
