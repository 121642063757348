import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { AddToAutoRequestRejectionListProps } from '@percent/admin-dashboard/api/actions/autoRequestRejectionList/autoRequestRejectionList.types'
import { RegistriesType } from '@percent/admin-dashboard/api/types'
import { useCountries, useMutation, useQuery } from '@percent/admin-dashboard/common/hooks'
import { mapCountriesToSelectOptions } from '@percent/admin-dashboard/common/utility/mapCountriesToSelectOptions'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { AcknowledgeModal, ActionModal, Alert, Flex, FormField, Modal, Select, TextInput } from '@percent/lemonade'
import { sanitizeStringToRegex } from '@percent/utility'
import { AddToAutoRequestRejectionListModalProps } from './AddToAutoRequestRejectionListModal.types'

const mapRegistries = (registries?: RegistriesType[] | null) => {
  return registries
    ? registries?.map(registry => {
        return {
          label: registry.englishName || registry.name,
          value: registry.id
        }
      })
    : []
}

const defaultSelectValue = { label: '', value: '' }

export function AddToAutoRequestRejectionListModal({
  isOpen,
  setIsOpen,
  refresh
}: Readonly<AddToAutoRequestRejectionListModalProps>) {
  const { t } = useTranslation()
  const { autoRequestRejectionListService, registriesService } = useServices()
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const countries = useCountries()
  const countriesOptions = mapCountriesToSelectOptions(countries)

  const [{ isLoading }, { apiFunc: addToAutoRequestRejectionList }] = useMutation(
    autoRequestRejectionListService.addToAutoRejectonList,
    () => {
      setShowConfirmationScreen(true)
      refresh()
    },
    () => setShowErrorMessage(true)
  )

  const { values, isValid, errors, touched, dirty, submitForm, setFieldValue, handleBlur, handleChange, resetForm } =
    useFormik<AddToAutoRequestRejectionListProps & { registryName: string; countryCode: string }>({
      initialValues: {
        organisationIdInRegistry: '',
        registryName: '',
        registryId: '',
        countryCode: ''
      },
      validationSchema: () =>
        Yup.lazy(value => {
          const newRegex =
            value.registryName &&
            registriesList.find(registry => registry.name === value?.registryName)?.registryIdFormatRegex

          return Yup.object().shape({
            registryId: Yup.string().required('Required'),
            countryCode: Yup.string().required(),
            registryName: Yup.string().required(t('errorMessage.required')),
            organisationIdInRegistry: Yup.string()
              .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Registry ID' }))
              .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Registry ID' }))
              .trim()
              .required(t('errorMessage.required'))
              .matches(sanitizeStringToRegex(newRegex || ''), t('errorMessage.wrongFormatRegistryId'))
          })
        }),
      onSubmit: ({ registryId, organisationIdInRegistry }) => {
        addToAutoRequestRejectionList({ registryId, organisationIdInRegistry })
      }
    })

  const [{ data: registriesList }, { query: fetchRegistriesList }] = useQuery(
    registriesService.getRegistriesList,
    { countryCode: values.countryCode },
    { skip: !values.countryCode }
  )
  const registriesOptions = mapRegistries(registriesList)

  const closeModal = () => {
    resetForm()
    setIsOpen(false)
    setShowErrorMessage(false)
  }

  const selectedRegistry = { label: values.registryName, value: values.registryId }

  return (
    <Modal open={isOpen} onClose={closeModal}>
      {showConfirmationScreen && (
        <AcknowledgeModal
          handleClose={() => {
            closeModal()
            setShowConfirmationScreen(false)
          }}
          title={t('dialog.addToRequestRejectionListConfirmation.title')}
          description={t('dialog.addToRequestRejectionListConfirmation.description', {
            registryName: values.registryName,
            registryId: values.organisationIdInRegistry
          })}
          result="positive"
          buttonText={t('button.done')}
        />
      )}

      {!showConfirmationScreen && (
        <ActionModal
          title={t('dialog.addToRequestRejectionList.title')}
          primaryButtonText={t('button.addOrganization')}
          primaryBtnTestId="add-to-request-rejection-list"
          secondaryButtonText={t('button.cancel')}
          handleClose={closeModal}
          handleSubmit={submitForm}
          loading={isLoading}
          disabled={!isValid || !dirty}
        >
          <Flex direction="column" gap={16}>
            <FormField
              label={t('typography.country')}
              status={errors.countryCode && touched.countryCode ? 'danger' : 'default'}
              statusMessage={errors.countryCode}
              data-testid="selectCountry"
              necessity="required"
            >
              <Select
                placeholder={t('dialog.addOrganization.form.country.placeholder')}
                searchable={false}
                options={countriesOptions}
                defaultValue={countriesOptions.find(({ value }) => value === values?.countryCode) ?? defaultSelectValue}
                onChange={event => {
                  setFieldValue('countryCode', event.value)
                  fetchRegistriesList({ countryCode: event.value })
                }}
              />
            </FormField>

            <FormField
              label={t('dialog.approveValdiationRequest.selectRegistry')}
              status={errors.registryName && touched.registryName ? 'danger' : 'default'}
              statusMessage={errors.registryName}
              data-testid="selectRegistry"
              necessity="required"
            >
              <Select
                placeholder={t('dialog.approveValdiationRequest.selectRegistry')}
                onChange={e => {
                  setFieldValue('registryName', e.label)
                  setFieldValue('registryId', e.value)
                }}
                options={registriesOptions}
                defaultValue={selectedRegistry}
              />
            </FormField>

            <FormField
              label={t('typography.registryId')}
              data-testid="organisationIdInRegistry"
              status={errors.organisationIdInRegistry && touched.organisationIdInRegistry ? 'danger' : 'default'}
              statusMessage={errors.organisationIdInRegistry}
              necessity="required"
            >
              <TextInput
                name="organisationIdInRegistry"
                value={values.organisationIdInRegistry}
                placeholder={t('dialog.addOrganization.form.registryId.placeholder')}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormField>
            {showErrorMessage && <Alert variant="error">{t('dialog.somethingWentWrong')}</Alert>}
          </Flex>
        </ActionModal>
      )}
    </Modal>
  )
}
