import React from 'react'
import styled from 'styled-components'
import {
  FeedbackProps,
  FeedbackVariant,
  StyledFeedbackProps,
} from './feedback.types'
import { Icon } from '../icon'
import { Color } from '../../theme'

const IconColorMap: Record<FeedbackVariant, Color> = {
  default: 'gray600',
  informative: 'info400',
  positive: 'success400',
  warning: 'warning400',
  critical: 'alert400',
  light: 'neutral300',
}

export const StyledFeedback = styled.span<StyledFeedbackProps>`
  ${({ theme, $variant, $align }) =>
    `
    display: inline-flex;
    justify-content: ${$align};
    align-items: center;
    gap: ${theme.sizes[1]};

    font-size: ${theme.fontSizes.bodySmall};
    font-weight: ${theme.fontWeights.regular};
    color: ${theme.colors[IconColorMap[$variant]]};
    text-align: left;
    `}
`

export const Feedback = ({
  variant = 'default',
  align = 'center',
  icon,
  children,
}: FeedbackProps) => (
  <StyledFeedback $variant={variant} $icon={icon} $align={align}>
    {icon ? <Icon name={icon} size={5} color={IconColorMap[variant]} /> : null}
    <span>{children}</span>
  </StyledFeedback>
)
