import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { FraudFlagsAlert } from '../fraudFlagsAlert/FraudFlagsAlert'
import { GroupedFraudChecks } from '../../utils/getGroupedFraudChecks'

import { AgentVerificationStatus } from '@percent/admin-dashboard/api/types'
import { ErrorView } from '@percent/admin-dashboard/common/components'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import { Alert, ButtonText, CardContent, CardDashboard, Flex, Spacer } from '@percent/lemonade'
import styles from './AgentDetails.module.scss'
import { AgentDetailsProps } from './AgentDetails.types'

const getFailedAutoChecks = (fraudChecks: GroupedFraudChecks['agent'] | undefined) => {
  return fraudChecks?.filter(f => f.result === 'fail').map(f => f.data)
}

export function AgentDetails({
  agentVerification,
  agentVerificationStatus,
  fraudFlags,
  isAgentEmailAutoApproved,
  isApplicationLanguageSuspicious,
  fraudChecks,
  isAutoFraudCheckEnabled
}: Readonly<AgentDetailsProps>) {
  const { t } = useTranslation()
  const { agentVerificationService } = useServices()
  const { id } = useParams<{ id: string }>()
  const addToast = useToast()

  const [{ errorMessage, success }, { apiFunc: resendEmailFunc }] = useMutation(
    agentVerificationService.resendAgentVerificationEmail
  )

  useEffect(() => {
    if (success) {
      addToast(t('typography.agentVerificationEmailSent'), 'success')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  const failedAutoChecks = getFailedAutoChecks(fraudChecks)
  const shouldShowAutoChecks = isAutoFraudCheckEnabled && failedAutoChecks && failedAutoChecks?.length > 0

  const isPending =
    agentVerificationStatus &&
    [AgentVerificationStatus.PENDING_USER_VERIFICATION, AgentVerificationStatus.PENDING].includes(
      agentVerificationStatus
    )

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <CardDashboard title={t('typography.agent')}>
      {shouldShowAutoChecks && (
        <Flex mb={8} w="100%" direction="column">
          <Alert variant="warning" title={t('fraudReason.scope.agent')}>
            <ul>
              {failedAutoChecks.map(check => (
                <li key={check.checkName}>{t(`autoFraudChecks.agent.${check.checkName}`)}</li>
              ))}
            </ul>
          </Alert>
        </Flex>
      )}
      <FraudFlagsAlert
        title={t('typography.flagsForAgent')}
        flags={fraudFlags}
        isApplicationLanguageSuspicious={isApplicationLanguageSuspicious}
      />
      {isAgentEmailAutoApproved && (
        <>
          <Alert title={t('typography.applicantEmailAutoApproved')} variant="info" />
          <Spacer size={4} />
        </>
      )}

      <CardContent title={t('typography.name')}>
        <span>{`${agentVerification?.firstName} ${agentVerification?.lastName}`}</span>
      </CardContent>
      <CardContent title={t('typography.email')}>
        <div className={styles.emailWrapper}>
          <span>{agentVerification?.email}</span>
          {isPending && (
            <ButtonText onPress={() => resendEmailFunc({ id })} icon="recurring">
              {t('typography.resendAgentVerificationEmail')}
            </ButtonText>
          )}
        </div>
      </CardContent>
      <CardContent title={t('typography.language')}>
        <span>{`${agentVerification?.language}`}</span>
      </CardContent>
    </CardDashboard>
  )
}
