export const mockPartnerDetail = {
  data: {
    agentVerificationRedirectUrl: null,
    allowedActivitySubTags: [],
    createdAt: '2023-11-17T10:36:53.273Z',
    currencyCode: 'AUD',
    deletedAt: null,
    id: 'staging_partner_000000CjaEDvxVBvj5UJ9CvcKovGC',
    legalName: null,
    logo: 'https://static.poweredbypercent.com/partnerlogo/partnerlogo_082c1387-fd55-4428-b28e-0b7f90449da6.png',
    name: 'Goodstack Workplace Giving',
    primaryColor: null,
    programWebsite: null,
    publicKey: 'staging_pk_d9234539-3352-4749-8808-46c97e220475',
    redactPii: false
  },
  object: 'partner'
}

export const mockPartnerList = {
  data: [
    {
      id: 'staging_partner_000000D1ncGTRHRghUqeB6vbHSq5g',
      name: 'Generic Rubber Ball 48ef303d-a961-44dd-93c8-5e8da88aaf43',
      currencyCode: 'GBP',
      legalName: 'nm_automated_ri48ef303d-a961-44dd-93c8-5e8da88aaf43',
      publicKey: 'staging_pk_2505519b-67d8-458d-af91-2f3795131699',
      createdAt: '2025-02-13T10:18:24.789Z',
      deletedAt: null,
      logo: null,
      banner: null,
      agentVerificationRedirectUrl: null,
      redactPii: false,
      primaryColor: null,
      programWebsite: null
    },
    {
      id: 'staging_partner_000000D1ncF2ENb0I9iertAfdowUL',
      name: 'Licensed Metal Bike dd26e0d8-3d27-4706-bad0-8d4c73062325',
      currencyCode: 'GBP',
      legalName: 'nm_automated_ridd26e0d8-3d27-4706-bad0-8d4c73062325',
      publicKey: 'staging_pk_262864d6-3e52-4329-963a-b65d2b4b3dd1',
      createdAt: '2025-02-13T10:18:11.220Z',
      deletedAt: null,
      logo: null,
      banner: null,
      agentVerificationRedirectUrl: null,
      redactPii: false,
      primaryColor: null,
      programWebsite: null
    },
    {
      id: 'staging_partner_000000D1ncD9ZfB245w3cI6X2HqYH',
      name: 'Fantastic Fresh Pizza 604fd847-4e30-417d-bfcf-640c94d70f6b',
      currencyCode: 'GBP',
      legalName: 'nm_automated_ri604fd847-4e30-417d-bfcf-640c94d70f6b',
      publicKey: 'staging_pk_f58eb492-f348-417e-ac99-9beec5bbbb56',
      createdAt: '2025-02-13T10:17:54.861Z',
      deletedAt: null,
      logo: null,
      banner: null,
      agentVerificationRedirectUrl: null,
      redactPii: false,
      primaryColor: null,
      programWebsite: null
    }
  ],
  totalResults: 3,
  object: 'partner',
  _links: {
    current: 'https://staging-api.poweredbypercent.com/v1/admin/partners?pageSize=25'
  }
}
