import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { AgentVerificationModal } from '../../AgentVerificationDetails.types'
import { useAgentVerificationDecisionContext } from '../../useAgentVerificationDecisionContext/useAgentVerificationDecisionContext'

import { AgentVerificationStatus, FraudCheckResultsResponse } from '@percent/admin-dashboard/api/types'
import { PercentButton } from '@percent/admin-dashboard/common/components'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { environment } from '@percent/admin-dashboard/config'
import { environments } from '@percent/admin-dashboard/constants/environments'
import { Alert, AlertVariant, Button, CardDashboard, Flex, Tabs, Tooltip } from '@percent/lemonade'
import { TabProps } from 'libs/shared/ui-lemonade/src/components/tabs'
import styles from './AgentVerificationDecision.module.scss'
import { AgentVerificationDecisionProps } from './AgentVerificationDecision.types'
import { AgentVerificationApproveEmailModal } from './agentVerificationApproveEmailModal/AgentVerificationApproveEmailModal'
import { AgentVerificationApproveModalContainer } from './agentVerificationApproveModal/AgentVerificationApproveModalContainer'
import { AgentVerificationMarkAsSafeModal } from './agentVerificationMarkAsSafeModal/AgentVerificationMarkAsSafeModal'
import { AgentVerificationRejectModalContainer } from './agentVerificationRejectModal/AgentVerificationRejectModalContainer'
import { AgentVerificationUpdateStatusModal } from './agentVerificationUpdateStatusModal/AgentVerificationUpdateStatusModal'

const getAlertVariant = (
  fraudCheckResults: FraudCheckResultsResponse | null,
  isMarkedAsSafe: boolean | null
): AlertVariant => {
  if (isMarkedAsSafe) return 'success'

  switch (fraudCheckResults?.result) {
    case 'pass':
      return 'success'
    case 'fail':
      return 'error'
    case 'needs_review':
      return 'warning'
    default:
      return 'warning'
  }
}

export function AgentVerificationDecision({
  agentFullName,
  organisationName,
  isLoading,
  errorMessage,
  aproveEmail,
  refresh,
  agentStatus,
  fraudFlagsLength,
  allowedOrganisations,
  partnerName,
  globalFlags,
  isStatusDynamic,
  activeTab,
  setActiveTab,
  similarApplicationsAmount,
  fraudCheckResults,
  isAutoFraudCheckEnabled
}: Readonly<AgentVerificationDecisionProps>) {
  const { t } = useTranslation()
  const { setOpenedModal } = useAgentVerificationDecisionContext()
  const { id } = useParams<{ id: string }>()
  const location = useLocation()
  const {
    agentVerificationManualFradulentRejectionFeatureFlag,
    domainVerificationFeatureFlag,
    dynamicAgentVerificationFeatureFlag
  } = useFeatureFlag()
  const [isMarkedAsSafe, setIsMarkedAsSafe] = useState<boolean | null>(fraudCheckResults?.isMarkedAsSafe ?? null)

  useEffect(() => {
    if (fraudCheckResults) setIsMarkedAsSafe(fraudCheckResults.isMarkedAsSafe)
  }, [fraudCheckResults])

  useEffect(() => {
    if (location) {
      setActiveTab(`${location.pathname}`)
    }
  }, [location, setActiveTab])

  const triggerEmailVerificationButton = () => {
    aproveEmail({ id })
    setOpenedModal(AgentVerificationModal.APPROVE_EMAIL)
  }

  const tabs: TabProps['items'] = [
    { key: `/agent-verification/${id}`, label: t('typography.applicationDetails') },
    {
      key: `/agent-verification/${id}/other`,
      label: t('typography.otherApplications', { amount: similarApplicationsAmount })
    },
    { key: `/agent-verification/${id}/timeline`, label: t('typography.applicationTimeline') }
  ]

  const alertVariant = getAlertVariant(fraudCheckResults, isMarkedAsSafe)
  const failingAutoChecksCount = fraudCheckResults?.checkResults.filter(c => c.result === 'fail').length ?? 0
  const shouldShowFraudCheckNeedsReview = !(
    [AgentVerificationStatus.APPROVED, AgentVerificationStatus.REJECTED].includes(
      agentStatus as AgentVerificationStatus
    ) && fraudCheckResults?.result === 'needs_review'
  )

  const handleMarkAsSafe = () => setOpenedModal(AgentVerificationModal.MARK_AS_SAFE)

  return (
    <>
      <CardDashboard className={styles.decisionContainer}>
        {agentVerificationManualFradulentRejectionFeatureFlag && fraudFlagsLength > 0 && (
          <div className={styles.fraudAlertWrapper}>
            <Alert title={t('typography.flaggedAsSuspicious', { count: fraudFlagsLength })} variant="info" />
          </div>
        )}

        {domainVerificationFeatureFlag && globalFlags && globalFlags.length > 0 && (
          <div className={styles.fraudAlertWrapper} data-testid="domain-verification-global-flags">
            <Alert variant="warning" title={t('typography.globalFlagsFound')}>
              <ul className={styles.globalFlagsList}>
                {globalFlags?.map(flag => (
                  <Tooltip content={t(`domainVerification.flag.${flag}.description`)} key={flag}>
                    <li>{t(`domainVerification.flag.${flag}`)}</li>
                  </Tooltip>
                ))}
              </ul>
            </Alert>
          </div>
        )}

        <Flex direction="column" gap={8}>
          {isAutoFraudCheckEnabled && fraudCheckResults && shouldShowFraudCheckNeedsReview && (
            <Alert
              variant={alertVariant}
              title={t(`dialog.autoFraud.${isMarkedAsSafe ? 'markedAsSafe' : fraudCheckResults.result}`)}
            >
              {failingAutoChecksCount > 0
                ? t('dialog.autoFraud.description', { amount: failingAutoChecksCount })
                : t('dialog.autoFraud.description_empty')}
            </Alert>
          )}
          <div className={styles.contentWrapper}>
            <div className={styles.organisationInfoWrapper}>
              <span className={styles.text}>{agentFullName}</span>
              <span className={styles.subtext}>{organisationName}</span>
            </div>
            {dynamicAgentVerificationFeatureFlag &&
              isStatusDynamic &&
              (agentStatus === AgentVerificationStatus.APPROVED ||
                agentStatus === AgentVerificationStatus.REJECTED) && (
                <Button variant="secondary" onPress={() => setOpenedModal(AgentVerificationModal.UPDATE_STATUS)}>
                  {t('dialog.updateAvStatus.title')}
                </Button>
              )}
            {(agentStatus === AgentVerificationStatus.PENDING ||
              agentStatus === AgentVerificationStatus.PENDING_REVIEW ||
              agentStatus === 'pending_user_verification') && (
              <div className={styles.buttonWrapper}>
                {environment === environments.SANDBOX && (
                  <PercentButton
                    title={t('button.triggerEmailVerification')}
                    onClick={triggerEmailVerificationButton}
                    className={styles.triggerEmailVerificationButton}
                  />
                )}
                {agentStatus !== 'pending_user_verification' && (
                  <>
                    <PercentButton
                      title={t('button.reject')}
                      onClick={() => setOpenedModal(AgentVerificationModal.REJECT)}
                      className={styles.rejectButton}
                      testId="open-rejection-modal"
                    />
                    {isAutoFraudCheckEnabled && fraudCheckResults?.result === 'needs_review' && !isMarkedAsSafe ? (
                      <Button
                        size="small"
                        onPress={handleMarkAsSafe}
                        variant="reject"
                        disabled={isMarkedAsSafe === null}
                      >
                        {t('button.markAsSafe')}
                      </Button>
                    ) : (
                      <PercentButton
                        title={t('button.approve')}
                        onClick={() => setOpenedModal(AgentVerificationModal.APPROVE)}
                        className={styles.approveButton}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </Flex>
        <Tabs activeTab={activeTab} onChangeTab={setActiveTab} items={tabs} />
      </CardDashboard>
      <AgentVerificationApproveModalContainer
        agentFullName={agentFullName}
        organisationName={organisationName}
        refresh={refresh}
      />
      <AgentVerificationRejectModalContainer
        agentFullName={agentFullName}
        organisationName={organisationName}
        refresh={refresh}
        allowedOrganisations={allowedOrganisations}
        partnerName={partnerName}
      />
      <AgentVerificationApproveEmailModal isLoading={isLoading} errorMessage={errorMessage} refresh={refresh} />
      {dynamicAgentVerificationFeatureFlag && (
        <AgentVerificationUpdateStatusModal
          agentFullName={agentFullName}
          organisationName={organisationName}
          agentVerificationStatus={agentStatus}
          refresh={refresh}
          allowedOrganisations={allowedOrganisations}
          partnerName={partnerName}
        />
      )}
      {isAutoFraudCheckEnabled && (
        <AgentVerificationMarkAsSafeModal
          id={id}
          fullName={agentFullName}
          organisationName={organisationName}
          setIsMarkedAsSafe={setIsMarkedAsSafe}
        />
      )}
    </>
  )
}
