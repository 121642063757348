import React from 'react'
import { useTranslation } from 'react-i18next'

import { Badge, CardContent } from '@percent/lemonade'
import { BankDetailsMainView } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'
import { PaymentMethod } from '@percent/admin-dashboard/api/types'
import { PaymentTypeWithButton } from './PaymentTypeWithButton'
import { BankDetailStatus, getBankDetailsFields } from '@percent/domain/bank-details'

const apiBankDetailsReviewStatusToDisplayName = {
  pending: 'Pending',
  approved: 'Approved'
}

export function BankDetailsView({
  paymentMethod,
  paymentMethodRefresh,
  bankDetailsData
}: {
  paymentMethod?: PaymentMethod
  paymentMethodRefresh: VoidFunction
  bankDetailsData: BankDetailsMainView
}) {
  const { t } = useTranslation()

  const reviewUrl = `/bank-details-reviews/${bankDetailsData?.id}`

  const wireTransferDetails = bankDetailsData.bankAccountDetails?.wireTransferDetails
  const fields = wireTransferDetails ? getBankDetailsFields(wireTransferDetails) : []

  const bankTransferPaymentMethod = (
    <CardContent
      title={
        paymentMethod?.method === 'card' || paymentMethod?.method === 'cheque'
          ? t('typography.newPaymentMethod')
          : t('typography.paymentMethod')
      }
      testId="bank-details-payment-method"
    >
      {t('typography.bankTransfer')}
    </CardContent>
  )

  return bankDetailsData?.status === BankDetailStatus.APPROVED ||
    bankDetailsData?.status === BankDetailStatus.PENDING ? (
    <>
      {bankTransferPaymentMethod}
      <CardContent title={t('typography.status')} testId="bank-details-status">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Badge
            variant={bankDetailsData?.status === BankDetailStatus.APPROVED ? 'positive' : 'default'}
            icon={bankDetailsData?.status === BankDetailStatus.APPROVED ? 'approve' : 'clock'}
            testId="bank-details-status-badge"
          >
            {apiBankDetailsReviewStatusToDisplayName[bankDetailsData?.status as 'approved' | 'pending']}
          </Badge>
        </div>
      </CardContent>
      <CardContent title={t('typography.accountHolderName')} testId="bank-details-holder">
        {bankDetailsData?.bankAccountDetails?.holderName || t('typography.notAvailable')}
      </CardContent>
      {fields.map(field => (
        <CardContent title={field.label} testId={`bank-details-${field.key}`}>
          {field?.value || t('typography.notAvailable')}
        </CardContent>
      ))}
      <CardContent title={t('typography.seeBankDetailsReview')}>
        <a data-testId="go-to-review-page-button" href={reviewUrl} title={t('button.seeReview')}>
          {t('button.seeReview')}
        </a>
      </CardContent>
    </>
  ) : paymentMethod?.method === 'bank_transfer' ? (
    <>
      {bankTransferPaymentMethod}
      <CardContent title={t('typography.noneSupplied')} />
    </>
  ) : (
    <PaymentTypeWithButton
      title={t('typography.paymentMethod')}
      text={t('typography.noneSupplied')}
      refresh={paymentMethodRefresh}
    />
  )
}
