import React, { ChangeEvent, Children, useId, useState } from 'react'
import styled from 'styled-components'
import { FormFieldProps } from './form-field.types'
import { Label } from '../label'
import { Feedback } from '../feedback'
import { StrenghtMeter } from '../strenght-meter'

export const StyledFormField = styled.div`
  ${({ theme }) =>
    `
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    line-height: 24px;
    gap: ${theme.sizes[2]};
    `}
`

export const FormField = ({
  status,
  description,
  disabled,
  label,
  necessity,
  showStrenghtMeter,
  children,
  statusMessage,
  'data-testid': dataTestid,
  descriptionVariant,
  hideCharacterCount,
}: FormFieldProps) => {
  const child = Children.only(children)

  const fieldId = useId()
  const hintId = useId()
  const [trackedValue, setTrackedValue] = useState(child.props.value)

  const showStatus = status === 'danger' && statusMessage
  const isPasswordField = child.props.type === 'password'
  const chars = trackedValue?.length || 0

  if (child.props.maxLength && description) {
    throw new Error('You cannot use maxLength and description together')
  }

  if (!isPasswordField && showStrenghtMeter) {
    throw new Error('Strength meter can only be used with a password field')
  }

  const renderHint = () => {
    if (showStatus) {
      return (
        <Feedback id={hintId} variant="critical">
          {statusMessage}
        </Feedback>
      )
    }

    if (child.props.maxLength && !hideCharacterCount) {
      return (
        <Feedback
          id={hintId}
          variant={chars === child.props.maxLength ? 'critical' : 'default'}
        >
          You have {child.props.maxLength - chars} characters left to use
        </Feedback>
      )
    }
    if (description) {
      return (
        <Feedback id={hintId} variant={descriptionVariant || 'default'}>
          {description}
        </Feedback>
      )
    }

    return null
  }

  return (
    <StyledFormField>
      {label && (
        <Label htmlFor={fieldId} necessity={necessity}>
          {label}
        </Label>
      )}
      {React.cloneElement(children, {
        id: fieldId,
        status: showStatus ? 'danger' : 'default',
        disabled: disabled,
        'aria-describedby': hintId,
        'data-testid': dataTestid,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          if (e?.target?.value || e?.type === 'change') {
            setTrackedValue(e.target.value)
          }
          child.props.onChange?.(e)
        },
      })}
      {disabled ? null : renderHint()}
      {isPasswordField && showStrenghtMeter && trackedValue ? (
        <StrenghtMeter text={trackedValue} />
      ) : null}
    </StyledFormField>
  )
}
