import React, { Suspense } from 'react'
import styled from 'styled-components'
import { Skeleton } from '@mantine/core'

import { IllustratedMessageProps } from './illustrated-message.types'
import { Heading } from '../heading'
import { Text } from '../text'
import { Button } from '../button'

export const illustrationRegistry = {
  '404': React.lazy(() =>
    import('./illustrations/404.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  'expired-session': React.lazy(() =>
    import('./illustrations/expired-session.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  'general-error': React.lazy(() =>
    import('./illustrations/general-error.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  'invalid-link': React.lazy(() =>
    import('./illustrations/invalid-link.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  'invalid-session': React.lazy(() =>
    import('./illustrations/invalid-session.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  'no-access': React.lazy(() =>
    import('./illustrations/no-access.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  'no-donations': React.lazy(() =>
    import('./illustrations/no-donations.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  'no-milestones': React.lazy(() =>
    import('./illustrations/no-milestones.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  'no-payouts': React.lazy(() =>
    import('./illustrations/no-payouts.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  'no-results': React.lazy(() =>
    import('./illustrations/no-results.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  'nothing-to-see': React.lazy(() =>
    import('./illustrations/nothing-to-see.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  'maintenance-frame': React.lazy(() =>
    import('./illustrations/maintenance_frame.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  'opportunities-frame': React.lazy(() =>
    import('./illustrations/opportunities.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  'no-grant-programs': React.lazy(() =>
    import('./illustrations/no-grant-programs.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
  'no-grant-candidates': React.lazy(() =>
    import('./illustrations/no-grant-candidates.svg').then((module) => ({
      default: module.ReactComponent,
    }))
  ),
}

const StyledIllustratedMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${({ theme }) => theme.sizes.s};
`

const IllustrationWrapper = styled.div`
  width: 240px;
  height: 240px;
  gap: ${({ theme }) => theme.sizes.xs};
`

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.sizes.xs};
  text-align: center;
`

export const IllustratedMessage = ({
  illustration,
  title,
  description,
  cta,
}: IllustratedMessageProps) => {
  const IllustrationSVG = illustrationRegistry[illustration]

  if (!IllustrationSVG) {
    throw new Error(`Illustration type "${illustration}" is invalid`)
  }

  return (
    <StyledIllustratedMessage data-testid="illustrated-message">
      <IllustrationWrapper>
        <Suspense fallback={<Skeleton height={240} width={240} radius="sm" />}>
          <IllustrationSVG />
        </Suspense>
      </IllustrationWrapper>

      <ContentSection>
        {title && (
          <Heading
            level="h2"
            align="center"
            data-testid="illustrated-message-title"
          >
            {title}
          </Heading>
        )}
        {description && (
          <Text size="medium" dataTestId="illustrated-message-description">
            {description}
          </Text>
        )}
      </ContentSection>

      {cta && (
        <Button size="small" onPress={cta.onPress}>
          {cta.label}
        </Button>
      )}
    </StyledIllustratedMessage>
  )
}
