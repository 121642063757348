import styled from 'styled-components'
import { forwardRef } from 'react'
import { HeadingProps, StyledHeadingProps } from './heading.types'

export const StyledHeading = styled.div<StyledHeadingProps>`
  ${({ theme, $level, align }) => `
    margin: 0;
    font-size: ${theme.fontSizes[$level]};
    font-weight: 600;
    color: ${theme.colors.gray900};
    text-align: ${align};
  `}
`

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ level, align = 'left', as, children, ...props }, ref) => {
    return (
      <StyledHeading
        ref={ref}
        as={as || level}
        $level={level}
        align={align}
        {...props}
      >
        {children}
      </StyledHeading>
    )
  }
)
