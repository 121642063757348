import { Divider, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import countries from 'i18n-iso-countries'

import { Alert } from '@percent/lemonade'
import { BankDetailsValidationBox } from './bankDetailsValidationBox/BankDetailsValidationBox'
import { BankDataViewProps } from './BankDataView.types'
import styled from 'styled-components'
import {
  BankDetailsValidationEntry,
  BankDetailsValidationError,
} from '../../types/bankDetails.types'
import { BankDetailsValidationErrors } from './bankDetailsValidationErrors/BankDetailsValidationErrors'

const StyledInfoBoxDivider = styled(Divider)`
  margin-bottom: 18px;
  margin-top: 18px;
  background: #eceff3;
`

const StyledDescription = styled(Typography)`
  margin-top: 18px;
`

export function BankDataView({
  setFieldError,
  setFieldTouched,
  bankData,
  isValidated,
  fields,
  translations,
  variant = 'body2',
}: BankDataViewProps) {
  const [bankDataEntries, setBankDataEntries] = useState<
    BankDetailsValidationEntry[]
  >([])

  useEffect(() => {
    async function setValidationError(
      errList: BankDetailsValidationError[],
      apiKey: string,
      fieldKey: string
    ) {
      const error = errList.find((err) => err.field === apiKey)

      if (error) {
        await setFieldError(fieldKey, error.message)
        await setFieldTouched(fieldKey, true, false)
      }
    }

    const { title, description, noDataFound, foundBankData, ...labels } =
      translations

    if (bankData) {
      let entries: BankDetailsValidationEntry[] = []
      const foundData = {
        ...(bankData.branch || {}),
        ...(bankData.branch?.address || {}),
      } as Record<string, string>

      Object.keys(foundData).forEach((key) => {
        const label = labels[key as keyof typeof labels]

        if (key === 'country' && label) {
          const countryName = countries.getName(
            foundData[key] as countries.Alpha3Code,
            'en'
          )

          entries = [
            ...entries,
            {
              key: label,
              value: countryName ?? foundData[key],
            },
          ]
        } else if (label) {
          entries = [...entries, { key: label, value: foundData[key] }]
        }
      })

      setBankDataEntries(entries)

      setValidationError(
        bankData.validationErrors,
        'accountNumber',
        'accountNumber'
      )
      setValidationError(bankData.validationErrors, 'iban', 'iban')
      setValidationError(bankData.validationErrors, 'bic', 'bic')
      setValidationError(bankData.validationErrors, 'bankCode', 'bankCode')
      setValidationError(bankData.validationErrors, 'branchCode', 'branchCode')
    }
  }, [
    bankData,
    isValidated,
    setFieldError,
    setFieldTouched,
    setBankDataEntries,
    translations,
  ])

  return (
    <div>
      {isValidated && (
        <>
          <BankDetailsValidationBox
            entries={bankDataEntries}
            translations={translations}
            variant={variant}
          />

          {bankDataEntries.length > 0 && <StyledInfoBoxDivider />}
        </>
      )}
      {!isValidated && (
        <StyledDescription>{translations.description}</StyledDescription>
      )}
      {isValidated && bankData && bankData.success && (
        <Alert
          variant="success"
          title={translations.validDataTitle}
          descriptionVariant="div"
        />
      )}
      {isValidated &&
        (!bankData?.success ||
          (bankData && bankData.validationErrors.length > 0)) && (
          <BankDetailsValidationErrors
            bankData={bankData}
            fields={[
              ...fields,
              {
                field: 'uploadDocument',
                label: translations.proofOfBankAccountLabel,
              },
            ]}
            translations={translations}
            variant={variant}
          />
        )}
    </div>
  )
}
