export const moduleTitles = {
  DONATION_API: 'Donation API',
  ORGANISATION_API: 'Organisation API',
  DONATION_SESSION_API: 'Donation Session API',
  VALIDATION_REQUEST_API: 'Validations API',
  WEBHOOK_SUBSCRIPTION_API: 'Webhook Subscription API',
  AGENT_VERIFICATION_API: 'Agent Verification API',
  ACTIVITY_CHECK_API: 'Activity Check API',
  MONITORING_SUBSCRIPTION_API: 'Monitoring Subscription API',
  ELIGIBILITY_SUBSCRIPTION_API: 'Eligibility Subscription API',
  VALIDATION_INVITE_API: 'Validation Invite API',
  CODE_REDEMPTION_API: 'Code Redemption API',
  SSO: 'Single sign-on',
  SSO_NOT_STRICT: 'Single sign-on (Not Strict)',
  SINGLE_VALIDATION_SUBMISSION: 'Single Validation Submission API',
  DONATION_LINK: 'Donation Links',
  DONATION_MATCHING: 'Donation Matching API',
  WORKPLACE_GIVING: 'Workplace Giving',
  DONATION_WALLET: 'Donation Wallet',
  VOLUNTEERING: 'Volunteering',
  SLACK: 'Slack',
  GAMIFICATION: 'Gamification',
  SCIM_API: 'SCIM API',
  GRANTS: 'Grants'
}

const baseUrl = 'https://www.poweredbypercent.com/modules/'

export const moduleUrls = {
  CARBON_OFFSETTING_API_URL: `${baseUrl}carbon-offsetting`,
  CARD_DONATIONS_SDK_URL: `${baseUrl}card-donations-sdk`,
  CHARITIES_API_URL: `${baseUrl}charities`,
  COMMUNITY_CAUSE_API_URL: `${baseUrl}community-causes`,
  CROWDFUNDING_API_URL: `${baseUrl}crowdfunding`,
  DONATION_API_URL: `${baseUrl}donations`,
  FUNDRAISER_API_URL: `${baseUrl}fundraisers`,
  KYCH_SCORE_API_URL: `${baseUrl}kych-score`,
  RETAIL_API_URL: `${baseUrl}retail`
}

export const donationText = {
  DONATION_API: 'Donation API',
  ACTIVATE_DONATION_API: 'Activate Donation API',
  DEACTIVATE_DONATION_API: 'Deactivate Donation API'
}
