import { ValidationSubmissionConfigurationResponse } from '@percent/admin-dashboard/api/actions/validationSubmissions/validationSubmissions.types'
import { OrganisationTypes } from '@percent/domain/verifications'

export const mockValidationSubmissionConfigurationResponse: { data: ValidationSubmissionConfigurationResponse } = {
  data: {
    id: 'staging_hostedconfiguration_000000CnnnZuI6tueF5Y1GSvAzshc',
    name: 'Blue 77',
    partnerId: 'staging_partner_000000CD0C5roWbKzL58LDdI8HArA',
    primaryColor: '#FF6672',
    terms: null,
    logo: null,
    shouldSendOutcomeEmail: false,
    acceptanceEmailBody: null,
    rejectionEmailBody: null,
    partnerFields: [],
    configuration: {
      checks: [
        {
          type: 'agent_verification',
          identityChecks: [
            {
              type: 'inbox_verification'
            }
          ],
          isStatusDynamic: false,
          associationChecks: [
            {
              type: 'domain_matching'
            },
            {
              type: 'association_emails'
            },
            {
              type: 'manual'
            }
          ]
        },
        {
          type: 'fraud_check',
          checks: [{ type: 'advanced_domain_check' }, { type: 'basic_domain_check' }, { type: 'application_check' }]
        },
        {
          type: 'eligibility',
          rules: [
            {
              name: 'allows Pre-primary schools in Mexico, Brazil, Belgium, Italy, Japan, New Zealand, France, Germany, Indonesia, Israel, Poland, and Turkey',
              event: {
                tags: ['staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZP'],
                action: 'add-tags'
              },
              conditions: {
                fact: 'country',
                value: 'USA',
                operator: 'equal'
              }
            },
            {
              name: 'Remove Music in Canada',
              event: {
                tags: ['staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZT'],
                action: 'remove-tags'
              },
              conditions: {
                fact: 'country',
                value: 'CAN',
                operator: 'equal'
              }
            },
            {
              event: {
                tags: ['staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZV'],
                action: 'replace-tags'
              },
              conditions: {
                fact: 'organisationTypes',
                value: 'social_impact',
                operator: 'contains'
              }
            }
          ]
        },
        {
          type: 'compliance',
          flags: ['sanction', 'warning', 'adverseMedia', 'hateSpeech'],
          failingFlags: ['sanction', 'warning', 'hateSpeech']
        },
        {
          type: 'validation_request'
        }
      ],
      isStatusDynamic: false,
      allowedOrganisations: {
        types: [OrganisationTypes.Nonprofit, OrganisationTypes.SocialImpact]
      },
      disallowedOrganisations: {
        relationships: []
      }
    },
    programWebsite: null,
    activitySubTags: [
      {
        id: 'staging_confactivitysubtags_000000Chk8qhVVNntXE7d4ZxlS123',
        configurationId: 'staging_hostedconfiguration_000000CnnnZuI6tueF5Y1GSvAzshc',
        activitySubTagId: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZT',
        type: 'allow',
        createdAt: '2023-10-02T13:58:16.909Z',
        deletedAt: null
      },
      {
        id: 'staging_confactivitysubtags_000000Chk8qhVVNntXE7d4ZxlS001',
        configurationId: 'staging_hostedconfiguration_000000CnnnZuI6tueF5Y1GSvAzshc',
        activitySubTagId: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZH',
        type: 'allow',
        createdAt: '2025-01-10T13:09:34.406Z',
        deletedAt: null
      },
      {
        id: 'staging_confactivitysubtags_000000Chk8qhVVNntXE7d4ZxlS002',
        configurationId: 'staging_hostedconfiguration_000000CnnnZuI6tueF5Y1GSvAzshc',
        activitySubTagId: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZI',
        type: 'allow',
        createdAt: '2025-01-10T13:16:43.994Z',
        deletedAt: null
      },
      {
        id: 'staging_confactivitysubtags_000000Chk8qhVVNntXE7d4ZxlS003',
        configurationId: 'staging_hostedconfiguration_000000CnnnZuI6tueF5Y1GSvAzshc',
        activitySubTagId: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZK',
        type: 'allow',
        createdAt: '2025-01-10T13:16:43.994Z',
        deletedAt: null
      }
    ],
    allowedActivitySubTags: [
      {
        id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZH',
        tagId: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ2',
        name: 'Farming & Agriculture',
        description:
          'Organisations undertaking farming, making land available for farming, focused on breeding animals or focused on food/plant cultivation',
        createdAt: '2022-02-10T10:33:40.152Z',
        updatedAt: null
      },
      {
        id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZI',
        tagId: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ2',
        name: 'Environmental education',
        description: 'Organisations promoting and teaching conservation, sustainability or environmental issues',
        createdAt: '2022-02-10T10:33:40.152Z',
        updatedAt: null
      },
      {
        id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZK',
        tagId: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ2',
        name: 'Climate Change',
        description: 'Organisations focused on climate change issues',
        createdAt: '2022-02-10T10:33:40.152Z',
        updatedAt: null
      },
      {
        id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZL',
        tagId: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ2',
        name: 'Parks',
        description: 'Organisations that provide outdoor spaces for community recreation',
        createdAt: '2022-02-10T10:33:40.152Z',
        updatedAt: null
      }
    ]
  }
}
