import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'

import styles from '../../BankDetailsView.module.scss'

import { IsValidatedStatusProps } from './isValidatedStatus.types'
import { Icon } from '@percent/lemonade'

export function IsValidatedStatus({ data, field, fieldKey }: IsValidatedStatusProps) {
  const { t } = useTranslation()

  const isValidated = !!data.bankValidationData
  const isValid = !!data?.bankValidationData?.success

  const validationErrors = data?.bankValidationData?.validationErrors

  const hasValidationError = validationErrors?.find(f => f.field === fieldKey) !== undefined
  const hasAnyValidationErrors = validationErrors ? validationErrors.length > 0 : false

  return (
    <>
      {isValid && isValidated && (
        <div className={styles.validationStatus}>
          <Icon name="approve" size={4} color="success400" />
          <Typography variant="body2">{t('bankDetails.status.validData', { bankDetailsKey: field })}</Typography>
        </div>
      )}
      {!isValid && isValidated && hasValidationError && (
        <div className={styles.validationStatus}>
          <Icon name="reject" size={4} color="alert400" />
          <Typography variant="body2">{t('bankDetails.status.invalidData', { bankDetailsKey: field })}</Typography>
        </div>
      )}
      {(!isValidated || (isValidated && !isValid && !hasValidationError && !hasAnyValidationErrors)) && (
        <div className={styles.validationStatus}>
          <Icon name="info" size={4} color="warning400" />
          <Typography variant="body2">{t('bankDetails.status.notValidated', { bankDetailsKey: field })}</Typography>
        </div>
      )}
      {isValidated && !isValid && !hasValidationError && hasAnyValidationErrors && (
        <div className={styles.validationStatus}>
          <Icon name="info" size={4} color="warning400" />
          <Typography variant="body2">
            {t('bankDetails.status.notValidatedWithOtherErrors', { bankDetailsKey: field })}
          </Typography>
        </div>
      )}
    </>
  )
}
