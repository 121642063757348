import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { Modal, ConfirmationModal } from '@percent/lemonade'
import { RemoveFromAutoRequestRejectionListProps } from './RemoveFromAutoRequestRejectionListModal.types'

export function RemoveFromAutoRequestRejectionListModal({
  autoRequestRejectionEntry,
  setAutoRequestRejectionEntry,
  refresh
}: Readonly<RemoveFromAutoRequestRejectionListProps>) {
  const { t } = useTranslation()
  const { autoRequestRejectionListService } = useServices()

  const [{ isLoading }, { apiFunc }] = useMutation(
    autoRequestRejectionListService.removeFromAutoRequestRejectionList,
    () => {
      refresh()
      setAutoRequestRejectionEntry(undefined)
    }
  )

  const removeFromAutoRequestRejectionList = () => apiFunc({ id: autoRequestRejectionEntry?.id || '' })

  return (
    <Modal open={!!autoRequestRejectionEntry?.id}>
      <ConfirmationModal
        title={t('dialog.removeFromRequestRejectionList.title')}
        loading={isLoading}
        primaryButtonText={t('button.remove')}
        primaryBtnTestId="remove-from-rejection-list"
        secondaryButtonText={t('button.cancel')}
        handleSubmit={removeFromAutoRequestRejectionList}
        handleClose={() => setAutoRequestRejectionEntry(undefined)}
      >
        {t('dialog.removeFromRequestRejectionList.description', {
          organisationId: autoRequestRejectionEntry?.organisationIdInRegistry
        })}
      </ConfirmationModal>
    </Modal>
  )
}
