import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Alert, CardDashboard, IllustratedMessage, Loader, Spacer, Timeline } from '@percent/lemonade'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { ValidationSubmissionTimelineProps } from './ValidationSubmissionTimeline.types'
import { useValidationSubmissionTimeline } from './useValidationSubmissionTimeline'
import {
  ValidationSubmissionLog,
  ValidationSubmissionLogType
} from '@percent/admin-dashboard/api/actions/validationSubmissions/validationSubmissions.types'

const determineValidationSubmissionStatus = (logs: ValidationSubmissionLog[]): 'approved' | 'failed' | 'unknown' => {
  // For dynamic VS we need the latest log in order to determine the overall status
  const statusTypes: ValidationSubmissionLogType[] = [
    'agent_verification_status_change',
    'validation_submission_succeeded',
    'validation_submission_failed',
    'validation_submission_pending'
  ]

  const latestLog = logs
    ?.filter(log => statusTypes.includes(log.type))
    ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0]

  const latestLogType = latestLog?.type

  if (latestLogType === 'validation_submission_succeeded') {
    return 'approved'
  }

  if (latestLogType === 'validation_submission_failed') {
    return 'failed'
  }

  if (latestLogType === 'agent_verification_status_change') {
    if (latestLog.data.updatedCheck?.status === 'approved') return 'approved'

    if (latestLog.data.updatedCheck?.status === 'rejected') return 'failed'
  }

  return 'unknown'
}

export function ValidationSubmissionTimeline({ validationSubmission }: Readonly<ValidationSubmissionTimelineProps>) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { validationSubmissionsService } = useServices()

  const [{ data: validationSubmissionLogs, isLoading: isValidationSubmissionLogsLoading }] = useQuery(
    validationSubmissionsService.getValidationSubmissionLogs,
    { id }
  )

  const timeline = useValidationSubmissionTimeline({ logs: validationSubmissionLogs, validationSubmission })

  if (isValidationSubmissionLogsLoading || !timeline) return <Loader />

  const validationSubmissionStatus = determineValidationSubmissionStatus(validationSubmissionLogs)

  if (validationSubmissionLogs.length === 0) {
    return (
      <CardDashboard data-testid="validation-submission-empty-timeline">
        <IllustratedMessage illustration="no-results" description={t('typography.noActivity')} />
      </CardDashboard>
    )
  }

  return (
    <CardDashboard title={t('typography.allActivity')} data-testid="validation-submission-timeline">
      {validationSubmissionStatus === 'approved' && (
        <Alert title={t('typography.application.approved.shortened')} variant="success" />
      )}
      {validationSubmissionStatus === 'failed' && (
        <Alert title={t('typography.application.rejected.shortened')} variant="error" />
      )}
      {validationSubmissionStatus !== 'unknown' && <Spacer axis="vertical" size={8} />}
      <Timeline timeline={timeline} />
    </CardDashboard>
  )
}
