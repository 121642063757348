import { TableCell, TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import { Table } from '@percent/admin-dashboard/common/components'
import { CountryCodeFlag } from '@percent/admin-dashboard/common/components/countryCodeFlag/CountryCodeFlag'
import { getDate } from '@percent/admin-dashboard/common/utility'
import { statusMap } from '@percent/admin-dashboard/constants/statusMap'
import { Badge } from '@percent/lemonade'
import styles from './ValidationSubmissionsTable.module.scss'
import { ValidationSubmissionsTableProps } from './ValidationSubmissionsTable.types'
import { ValidationSubmissionsFilters } from './validationSubmissionsFilters/ValidationSubmissionsFilters'

export function ValidationSubmissionsTable({
  data,
  isLoading,
  nextPage,
  previousPage,
  totalResults,
  errorMessage,
  queryParams,
  setQueryParams
}: Readonly<ValidationSubmissionsTableProps>) {
  const { t } = useTranslation()
  const { push } = useHistory()

  const cells = [
    { id: 'country', isSortable: false },
    { id: 'organization', isSortable: false },
    { id: 'agentEmail', isSortable: false },
    { id: 'partner', isSortable: false },
    { id: 'status', isSortable: false },
    { id: 'applicationDate', isSortable: false }
  ]

  const handleRowClick = (id: string) => {
    return push(`/validation-submissions/${id}`)
  }

  return (
    <Table
      isLoading={isLoading}
      totalResults={errorMessage ? 0 : totalResults || 0}
      data={data}
      previousPage={previousPage}
      nextPage={nextPage}
      columns={cells}
      filtersContent={<ValidationSubmissionsFilters queryParams={queryParams} setQueryParams={setQueryParams} />}
      emptyTableText={t('typography.noSearchResults')}
    >
      {data?.map(row => (
        <TableRow className={styles.row} key={row.id} onClick={() => handleRowClick(row.id)}>
          <CountryCodeFlag countryCode={row.organisation.countryCode ?? row.validationRequest.countryCode} />
          <TableCell className={styles.shortenColumn}>{row.organisation.name ?? row.validationRequest.name}</TableCell>
          <TableCell className={styles.shortenColumn}>{row.agentVerification.email || t('status.na')}</TableCell>
          <TableCell>{row.partner.name}</TableCell>
          <TableCell>
            <Badge variant={statusMap[row.status].variant} icon={statusMap[row.status].icon}>
              {t(statusMap[row.status].text)}
            </Badge>
          </TableCell>
          <TableCell>{getDate(row.createdAt)}</TableCell>
          <TableCell>
            <DropdownSVG className={styles.arrow} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
