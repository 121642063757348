import { Styles } from '@percent/admin-dashboard/theme/theme.types'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const EditorStyles: Styles = {
  width: '100%',

  '*': {
    fontFamily: 'Inter, sans-serif !important'
  },

  '[class^="mantine"], [class^="m_"]': {
    borderColor: lemonadeTheme.colors.gray80
  },

  '.mantine-RichTextEditor-root': {
    width: '100%',
    minHeight: '220px'
  },

  'p.is-editor-empty:first-of-type::before': {
    color: `${lemonadeTheme.colors.neutral300} !important`,
    lineHeight: `${lemonadeTheme.sizes[6]} !important`,
    float: 'none !important'
  },

  'p.is-editor-empty .ProseMirror-trailingBreak': {
    display: 'none !important'
  },

  '.mantine-RichTextEditor-content p': {
    fontSize: lemonadeTheme.fontSizes.bodySmall
  },

  '.mantine-RichTextEditor-root:hover': {
    outline: `${lemonadeTheme.sizes[1]} solid ${lemonadeTheme.colors.gray80}`,
    borderColor: lemonadeTheme.colors.black
  },

  '.mantine-RichTextEditor-root[aria-invalid="true"]:hover': {
    outline: `${lemonadeTheme.sizes[1]} solid ${lemonadeTheme.colors.alert100}`,
    borderColor: lemonadeTheme.colors.alert400
  },

  '.mantine-RichTextEditor-root:focus-within': {
    borderColor: lemonadeTheme.colors.black
  },

  '.mantine-RichTextEditor-root[aria-invalid="true"], .mantine-RichTextEditor-root[aria-invalid="true"]:focus': {
    borderColor: lemonadeTheme.colors.alert400
  }
}
