import React from 'react'
import { useTranslation } from 'react-i18next'

import { CardContent } from '@percent/lemonade'
import { PaymentMethod } from '@percent/admin-dashboard/api/types'
import { getFormattedDate } from '@percent/admin-dashboard/common/library/utility/date'

export function CreationDetails({ paymentMethod }: { paymentMethod: PaymentMethod }) {
  const { t } = useTranslation()

  return (
    <>
      <CardContent
        title={paymentMethod.method === 'bank_transfer' ? t('typography.approvedBy') : t('typography.updatedBy')}
      >
        {paymentMethod?.createdBy.fullName || paymentMethod?.createdBy.email || t('typography.notAvailable')}
      </CardContent>
      <CardContent
        title={paymentMethod.method === 'bank_transfer' ? t('typography.approvedAt') : t('typography.updatedAt')}
      >
        {(paymentMethod?.createdAt && getFormattedDate(paymentMethod.createdAt)) || t('typography.notAvailable')}
      </CardContent>
    </>
  )
}
