import React, { useMemo } from 'react'
import { Menu as ToggleMenu } from '@mantine/core'
import styled, { css } from 'styled-components'
import { Icon } from '../icon'
import { MenuProps } from './menu.types'
import { Tooltip } from '../tooltip'
import { ButtonTextV2 } from '../button-text-v2'

const StyledMenuWrapper = styled(ToggleMenu.Dropdown)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    border-radius: ${theme.borderRadii.medium};
    background: ${theme.colors.white};
    box-shadow: 0px 8px 32px rgba(33, 37, 53, 0.16);
    margin: ${theme.sizes.xxs} 0;
    padding: 0 ${theme.sizes.xs};
    outline: none;
    position: absolute;
    z-index: 1;
    background-color: white;
    right: 0;
    width: max-content;
    border: none;

    & > button: {
      padding: 0;
    }
  `}
`

const StyledSection = styled.div<{ $hasSectionDivider: boolean }>`
  ${({ theme, $hasSectionDivider }) => css`
    outline: none;
    padding: ${theme.sizes.xs} 0;

    ${$hasSectionDivider &&
    css`
      border-top: 1px solid ${theme.colors.gray80};
    `}
  `}
`

const StyledMenuItem = styled.button`
  ${({ theme }) => css`
    all: unset;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: ${theme.sizes.xs};
    width: 100%;
    height: ${theme.sizes.l};
    padding: 0 ${theme.sizes.xs};
    border-radius: ${theme.borderRadii.small};
    transition: all ${theme.transitionTime} ease;
    font-size: ${theme.fontSizes.bodySmall};
    outline: none;

    &:hover,
    &:active {
      background: ${theme.colors.gray70};
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  `}
`

export const Menu = ({
  title,
  disabled,
  disabledTooltipMessage,
  sections,
  onSelect,
  customTriggerComponent,
}: MenuProps) => {
  const triggerComponent = useMemo(() => {
    if (customTriggerComponent) {
      return customTriggerComponent
    }

    return <ButtonTextV2 disabled={disabled}>{title}</ButtonTextV2>
  }, [customTriggerComponent, disabled, title])

  return (
    <ToggleMenu position="bottom-end" offset={2}>
      <ToggleMenu.Target>
        {disabled && disabledTooltipMessage ? (
          <Tooltip content={disabledTooltipMessage} placement="bottom-end">
            <span>{triggerComponent}</span>
          </Tooltip>
        ) : (
          triggerComponent
        )}
      </ToggleMenu.Target>

      <StyledMenuWrapper>
        {sections.map((section, sectionIndex) => (
          <StyledSection
            $hasSectionDivider={sectionIndex > 0}
            key={`${section}-${sectionIndex}`}
          >
            {section.items.map(
              ({ key, label, icon, shouldBeDisabled, disabledTooltipInfo }) => (
                <ToggleMenu.Item
                  component="div"
                  style={{ padding: 0 }}
                  key={key}
                  disabled={shouldBeDisabled}
                >
                  {shouldBeDisabled && disabledTooltipInfo ? (
                    <Tooltip content={disabledTooltipInfo}>
                      <StyledMenuItem
                        disabled={shouldBeDisabled}
                        onClick={() => onSelect(key)}
                      >
                        {icon ? (
                          <Icon name={icon} size={5} color="gray400" />
                        ) : null}
                        {label}
                      </StyledMenuItem>
                    </Tooltip>
                  ) : (
                    <StyledMenuItem
                      disabled={shouldBeDisabled}
                      onClick={() => onSelect(key)}
                    >
                      {icon ? (
                        <Icon name={icon} size={5} color="gray400" />
                      ) : null}
                      {label}
                    </StyledMenuItem>
                  )}
                </ToggleMenu.Item>
              )
            )}
          </StyledSection>
        ))}
      </StyledMenuWrapper>
    </ToggleMenu>
  )
}
