import styled from 'styled-components'
import { StyledTypographyProps, TypographyProps } from './typography.types'

const StyledH1 = styled.h1<StyledTypographyProps>(
  ({ theme, $color = 'gray900' }) => ({
    fontSize: theme.fontSizes.h1,
    lineHeight: theme.sizes[12],
    fontWeight: theme.fontWeights.semiBold,
    color: theme.colors[$color],
  })
)
const StyledH2 = styled.h2<StyledTypographyProps>(
  ({ theme, $color = 'gray900' }) => ({
    fontSize: theme.fontSizes.h2,
    lineHeight: theme.sizes[8],
    fontWeight: theme.fontWeights.semiBold,
    color: theme.colors[$color],
  })
)
const StyledH3 = styled.h3<StyledTypographyProps>(
  ({ theme, $color = 'gray900' }) => ({
    fontSize: theme.fontSizes.h3,
    lineHeight: theme.sizes[6],
    fontWeight: theme.fontWeights.semiBold,
    color: theme.colors[$color],
  })
)
const StyledH4 = styled.h4<StyledTypographyProps>(
  ({ theme, $color = 'gray900' }) => ({
    fontSize: theme.fontSizes.h4,
    lineHeight: theme.sizes[6],
    fontWeight: theme.fontWeights.semiBold,
    color: theme.colors[$color],
  })
)
const StyledH5 = styled.h5<StyledTypographyProps>(
  ({ theme, $color = 'gray900' }) => ({
    fontSize: theme.fontSizes.h5,
    lineHeight: theme.sizes[5],
    fontWeight: theme.fontWeights.semiBold,
    color: theme.colors[$color],
  })
)
const StyledH6 = styled.h6<StyledTypographyProps>(
  ({ theme, $color = 'gray900' }) => ({
    fontSize: theme.fontSizes.h6,
    lineHeight: theme.sizes[5],
    fontWeight: theme.fontWeights.semiBold,
    color: theme.colors[$color],
  })
)

const StyledDefault = styled.p<StyledTypographyProps>(
  ({ theme, $color = 'gray900' }) => ({
    margin: 0,
    fontSize: theme.fontSizes.default,
    lineHeight: theme.sizes[5],
    fontWeight: theme.fontWeights.regular,
    color: theme.colors[$color],
  })
)
const StyledDefaultMedium = styled(StyledDefault)(({ theme }) => ({
  fontWeight: theme.fontWeights.medium,
}))
const StyledDefaultBold = styled(StyledDefault)(({ theme }) => ({
  fontWeight: theme.fontWeights.semiBold,
}))
const StyledLarge = styled.p<StyledTypographyProps>(
  ({ theme, $color = 'gray900' }) => ({
    margin: 0,
    fontSize: theme.fontSizes.large,
    lineHeight: theme.sizes[5],
    fontWeight: theme.fontWeights.regular,
    color: theme.colors[$color],
  })
)
const StyledLargeBold = styled(StyledLarge)(({ theme }) => ({
  fontWeight: theme.fontWeights.semiBold,
}))

const VARIANTS = {
  h1: StyledH1,
  h2: StyledH2,
  h3: StyledH3,
  h4: StyledH4,
  h5: StyledH5,
  h6: StyledH6,
  default: StyledDefault,
  'default-medium': StyledDefaultMedium,
  'default-bold': StyledDefaultBold,
  large: StyledLarge,
  'large-bold': StyledLargeBold,
} as const
export type TypographyVariant = keyof typeof VARIANTS

export const Typography = ({
  children,
  variant = 'default',
  ...restProps
}: TypographyProps<TypographyVariant>) => {
  const Component = VARIANTS[variant]

  return <Component {...restProps}>{children}</Component>
}
