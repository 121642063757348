import React from 'react'
import styled from 'styled-components'
import { ModalContentProps } from './modal-content.types'
import { Text } from '../text'

export const StyledModalFooter = styled.div<ModalContentProps>`
  ${({ theme }) =>
    `
    max-height: ${(props: { maxHeight: string }) => props.maxHeight};

    padding: ${theme.sizes[4]} ${theme.sizes[6]};
    `}
`

export const ModalContent = ({ children, maxHeight }: ModalContentProps) => {
  return (
    <StyledModalFooter maxHeight={maxHeight || '28rem'}>
      <Text>{children}</Text>
    </StyledModalFooter>
  )
}
