import { Typography } from '@material-ui/core'

import { Alert } from '@percent/lemonade'
import { BankDetailsValidationErrorsProps } from './BankDetailsValidationErrors.props'
import { Fragment } from 'react'

export function BankDetailsValidationErrors({
  bankData,
  fields,
  translations,
  variant,
}: BankDetailsValidationErrorsProps) {
  const errors = bankData?.validationErrors || []

  const { Others, ...rest } = errors.reduce((acc, error) => {
    const foundField = fields.find((field) => field.field === error.field)
    let key = 'Others'

    if (foundField) {
      key = foundField.label
    }

    if (acc[key]) {
      acc[key].push(error.message)
    } else {
      acc[key] = [error.message]
    }
    return acc
  }, {} as Record<string, string[]>)

  const groupedErrors: Record<string, string[]> = {
    ...rest,
    Others: [...new Set(Others)],
  }

  return (
    <Alert
      variant="error"
      title={translations.invalidDataTitle}
      descriptionVariant="div"
    >
      {errors.length > 0 ? (
        <>
          <Typography variant={variant} color="inherit">
            {translations.invalidDataDescription}
          </Typography>
          <dl>
            {Object.keys(groupedErrors).map(
              (fieldKey) =>
                groupedErrors[fieldKey].length > 0 && (
                  <Fragment key={fieldKey}>
                    <dt key={fieldKey}>{fieldKey}</dt>
                    {groupedErrors[fieldKey].map((error: string) => (
                      <dd key={error}>- {error}</dd>
                    ))}
                  </Fragment>
                )
            )}
          </dl>
        </>
      ) : (
        <Typography variant={variant} color="inherit" component="span">
          {translations.unknownValidationError}
        </Typography>
      )}
    </Alert>
  )
}
