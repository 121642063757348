import { useDisclosure } from '@mantine/hooks'
import { Drawer as MantineDrawer } from '@mantine/core'
import { ReactNode } from 'react'
import { Button } from '../button'
import { Heading } from '../heading'
import styled from 'styled-components'

const StyledDrawer = styled(MantineDrawer)`
  .mantine-Drawer-overlay {
    z-index: 1200;
  }

  .mantine-Drawer-inner {
    z-index: 1200;
    margin: 8px 0 8px 0;
  }

  .mantine-Drawer-content {
    border-radius: 8px;
    margin-right: 8px;
  }
`

export function Drawer({
  children,
  title,
  buttonText,
  position = 'right',
  initialOpened = false,
}: Readonly<{
  children: ReactNode
  title?: string
  initialOpened?: boolean
  buttonText?: string
  position?: 'right' | 'left'
}>) {
  const [opened, { open, close }] = useDisclosure(initialOpened)

  return (
    <>
      <StyledDrawer
        opened={opened}
        onClose={close}
        title={
          title && (
            <Heading level="h3" data-testid="drawer-heading">
              {title}
            </Heading>
          )
        }
        position={position}
        closeButtonProps={{
          'aria-label': 'close-drawer',
        }}
      >
        {children}
      </StyledDrawer>

      <Button variant="secondary" onPress={open}>
        {buttonText}
      </Button>
    </>
  )
}
