import { Card, Divider, Typography, Modal } from '@material-ui/core'
import React, { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { BankDetailsFormDialogProps } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsFormDialog/BankDetailsFormDialog.types'
import styles from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsDialog/BankDetailsDialog.module.scss'
import { PercentButton } from '@percent/admin-dashboard/common/components'
import { ReactComponent as SuccessIcon } from '@percent/admin-dashboard/common/assets/images/success.svg'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { ReactComponent as CloseIcon } from '@percent/admin-dashboard/common/assets/images/close_gray.svg'
import { Button, FormField, Spacer, Text, TextInput } from '@percent/lemonade'
import { sanitizeStringToRegex } from '@percent/utility'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { WireTransferType } from '@percent/domain/bank-details'

export function BankDetailsFormDialog({
  open,
  onClose,
  refresh,
  organisationId,
  organisationName,
  bankDetailsData,
  refreshTimeline
}: BankDetailsFormDialogProps) {
  const { t } = useTranslation()
  const { bankDetailsService } = useServices()
  const [isSuccess, setIsSuccess] = useState(false)

  const [{ isLoading }, { apiFunc }] = useMutation(bankDetailsService.updateBankDetailsForm, () => setIsSuccess(true))

  const wireTransferDetails = bankDetailsData.bankAccountDetails?.wireTransferDetails
  const addBankDetailsFormLabels =
    bankDetailsData?.addBankDetailsFormLabels || bankDetailsData?.requiredBankAccountDetails

  const bankDetailsHolderName = bankDetailsData.bankAccountDetails?.holderName || ''
  const defaultHolderName = organisationName.length <= 50 ? organisationName : ''

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      holderName: bankDetailsHolderName || defaultHolderName,
      accountNumber: wireTransferDetails?.accountNumber?.value || '',
      bic: wireTransferDetails?.bic?.value || '',
      bankCode: wireTransferDetails?.bankCode?.value || '',
      branchCode: wireTransferDetails?.branchCode?.value || ''
    },
    validationSchema: () => {
      const bankCodeValidation = addBankDetailsFormLabels?.fields?.bankCode?.regex
        ? Yup.string()
            .matches(
              sanitizeStringToRegex(addBankDetailsFormLabels?.fields?.bankCode?.regex),
              addBankDetailsFormLabels?.fields?.bankCode?.errorMessage
            )
            .uppercase()
        : undefined

      const branchCodeValidation = addBankDetailsFormLabels?.fields?.branchCode?.regex
        ? Yup.string()
            .matches(
              sanitizeStringToRegex(addBankDetailsFormLabels?.fields?.branchCode?.regex),
              addBankDetailsFormLabels?.fields?.branchCode?.errorMessage
            )
            .uppercase()
        : undefined

      return Yup.object().shape({
        holderName: Yup.string()
          .max(50, t('errorMessage.holderName'))
          .min(2, t('errorMessage.holderName'))
          .required(t('errorMessage.holderNameRequired')),
        accountNumber: Yup.string()
          .matches(
            sanitizeStringToRegex(addBankDetailsFormLabels?.fields?.accountNumber?.regex),
            addBankDetailsFormLabels?.fields?.accountNumber?.errorMessage
          )
          .required(t('errorMessage.required')),
        bic: Yup.string()
          .matches(
            sanitizeStringToRegex(addBankDetailsFormLabels?.fields?.bic?.regex),
            addBankDetailsFormLabels?.fields?.bic?.errorMessage
          )
          .uppercase()
          .required(t('errorMessage.required')),
        ...(bankCodeValidation && {
          bankCode: addBankDetailsFormLabels?.fields.bankCode.required
            ? bankCodeValidation.required(t('errorMessage.required'))
            : bankCodeValidation
        }),
        ...(branchCodeValidation && {
          branchCode: addBankDetailsFormLabels?.fields.branchCode.required
            ? branchCodeValidation.required(t('errorMessage.required'))
            : branchCodeValidation
        })
      })
    },
    onSubmit: async ({ holderName, bic, bankCode, accountNumber, branchCode }) => {
      await apiFunc({
        organisationId,
        payload: {
          holderName,
          paymentDetails: {
            type: WireTransferType.DOMESTIC_WITH_BIC,
            accountNumber,
            bic,
            ...(branchCode !== '' && { branchCode }),
            ...(bankCode !== '' && { bankCode })
          }
        }
      })
    }
  })

  const { errors, values, touched, dirty, handleChange, setFieldValue, handleBlur, handleSubmit, isValid, resetForm } =
    formik

  const handleCloseModal = () => {
    onClose()
    refresh()
    refreshTimeline()
    resetForm()
    setIsSuccess(false)
  }

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="bank-details-form-modal">
      <div className={styles.modal}>
        {isSuccess ? (
          <Card className={styles.wrapperSuccess}>
            <SuccessIcon className={styles.icon} />
            <Typography className={styles.successTitle}>{t('dialog.bankDetails.successFormHeader')}</Typography>
            <Typography>{t('dialog.bankDetails.successFormDescription', { organisationName })}</Typography>
            <PercentButton title={t('button.close')} onClick={handleCloseModal} className={styles.button} />
          </Card>
        ) : (
          <Card className={styles.wrapper}>
            <div className={styles.titleWrapper}>
              <Typography className={styles.title}>{t('dialog.bankDetails.headerFrom')}</Typography>
              <CloseIcon className={styles.closeIcon} onClick={onClose} />
            </div>
            <Divider className={styles.divider} />
            <Text size="medium">{t('dialog.bankDetails.descriptionForm', { organisationName })}</Text>
            <form onSubmit={handleSubmit}>
              <FormikProvider value={formik}>
                <Spacer size={4} axis="vertical" />
                <>
                  <FormField
                    label={t('typography.accountHolderName')}
                    status={touched.holderName && errors.holderName ? 'danger' : 'default'}
                    statusMessage={errors.holderName}
                    data-testid="holderName"
                    necessity="required"
                  >
                    <TextInput
                      name="holderName"
                      value={values.holderName}
                      placeholder={
                        Number(bankDetailsData.organisationName?.length) <= 50
                          ? bankDetailsData.organisationName
                          : t('dialog.addBankDetails.form.accountHolderName.placeholder')
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      data-testid="accountHolderInput"
                    />
                  </FormField>
                  <Spacer size={4} axis="vertical" />
                  <FormField
                    label={addBankDetailsFormLabels?.fields.accountNumber?.label}
                    status={touched.accountNumber && errors.accountNumber ? 'danger' : 'default'}
                    statusMessage={errors.accountNumber}
                    data-testid="accountNumber"
                    necessity="required"
                  >
                    <TextInput
                      name="accountNumber"
                      value={values.accountNumber}
                      placeholder={addBankDetailsFormLabels?.fields.accountNumber?.placeholder}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      data-testid="accountNumberInput"
                    />
                  </FormField>
                  <Spacer size={4} axis="vertical" />
                  <FormField
                    label={addBankDetailsFormLabels?.fields.bic?.label}
                    status={touched.bic && errors.bic ? 'danger' : 'default'}
                    statusMessage={errors.bic}
                    data-testid="bic"
                    necessity="required"
                  >
                    <TextInput
                      name="bic"
                      value={values.bic.toUpperCase()}
                      placeholder={addBankDetailsFormLabels?.fields.bic?.placeholder}
                      onBlur={handleBlur}
                      data-testid="bicInput"
                      onChange={e => {
                        const value = e.target.value || ''
                        setFieldValue('bic', value.toUpperCase())
                      }}
                    />
                  </FormField>
                  {addBankDetailsFormLabels?.fields.bankCode?.label && (
                    <>
                      <Spacer size={4} axis="vertical" />
                      <FormField
                        label={addBankDetailsFormLabels?.fields.bankCode?.label}
                        status={touched.bankCode && errors.bankCode ? 'danger' : 'default'}
                        statusMessage={errors.bankCode}
                        data-testid="bankCode"
                        necessity={addBankDetailsFormLabels?.fields.bankCode?.required ? 'required' : 'optional'}
                      >
                        <TextInput
                          name="bankCode"
                          value={values.bankCode.toUpperCase()}
                          placeholder={addBankDetailsFormLabels?.fields.bankCode?.placeholder}
                          onBlur={handleBlur}
                          data-testid="bankCodeInput"
                          onChange={e => {
                            const value = e.target.value || ''
                            setFieldValue('bankCode', value.toUpperCase())
                          }}
                        />
                      </FormField>
                    </>
                  )}
                  {addBankDetailsFormLabels?.fields.branchCode?.label && (
                    <>
                      <Spacer size={4} axis="vertical" />
                      <FormField
                        label={addBankDetailsFormLabels?.fields.branchCode?.label}
                        status={touched.branchCode && errors.branchCode ? 'danger' : 'default'}
                        statusMessage={errors.branchCode}
                        data-testid="branchCode"
                        necessity={addBankDetailsFormLabels?.fields.branchCode?.required ? 'required' : 'optional'}
                      >
                        <TextInput
                          name="branchCode"
                          value={values.branchCode.toUpperCase()}
                          placeholder={addBankDetailsFormLabels?.fields.branchCode?.placeholder}
                          onBlur={handleBlur}
                          data-testid="branchCodeInput"
                          onChange={e => {
                            const value = e.target.value || ''
                            setFieldValue('branchCode', value.toUpperCase())
                          }}
                        />
                      </FormField>
                    </>
                  )}
                </>
              </FormikProvider>
              <Spacer axis="vertical" size={5} />
              <div className={styles.buttonWrapper}>
                <Button
                  type="submit"
                  variant={isValid && dirty ? 'primary' : 'secondary'}
                  disabled={!(isValid && dirty && !isLoading)}
                  data-testid="submit-bank-details-button"
                >
                  {t('button.addDetails')}
                </Button>
                <Button variant="reject" onPress={handleCloseModal} data-testid="discard-bank-details-button">
                  {t('button.cancel')}
                </Button>
              </div>
            </form>
          </Card>
        )}
      </div>
    </Modal>
  )
}
