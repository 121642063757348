import React, { useCallback } from 'react'
import dayjs from 'dayjs'

import { Badge } from '@percent/lemonade'
import { TimeLeftProps } from './TimeLeft.types'

export function TimeLeft({ requestedDate }: TimeLeftProps) {
  const daysInMilliseconds = dayjs(requestedDate).add(3, 'day').diff(dayjs())

  const diffThreeDays = useCallback(() => {
    const getDays = dayjs(requestedDate).add(3, 'day').diff(dayjs(), 'd')
    const getHours = Math.floor(
      (dayjs(requestedDate).add(3, 'day').diff(dayjs()) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + getDays * 24
    )
    const getMinutes = Math.floor((dayjs(requestedDate).add(3, 'day').diff(dayjs()) % (1000 * 60 * 60)) / (1000 * 60))

    if (Math.sign(daysInMilliseconds) === -1) {
      return `${Math.abs(getHours)}h ${Math.abs(getMinutes)}m past`
    }

    return `${Math.floor(getHours)}h ${getMinutes.toString().padStart(2, '0')}m`
  }, [requestedDate, daysInMilliseconds])

  return (
    <>
      {daysInMilliseconds > 129600000 ? (
        <Badge icon="clock" variant="positive">
          {diffThreeDays()}
        </Badge>
      ) : daysInMilliseconds <= 129600000 && daysInMilliseconds >= 86460000 ? (
        <Badge icon="clock" variant="warning">
          {diffThreeDays()}
        </Badge>
      ) : (
        <Badge icon="clock" variant="critical">
          {diffThreeDays()}
        </Badge>
      )}
    </>
  )
}
