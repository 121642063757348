import { FraudCheckResultsResponse } from '@percent/admin-dashboard/api/types'

export const mockFraudCheckResultsResponse: FraudCheckResultsResponse = {
  isMarkedAsSafe: false,
  result: 'needs_review',
  checkResults: [
    {
      id: 'staging_fraudcheckresult_000000D2eFFMAKTLYgPuQG3hJqCiu',
      validationSubmissionId: 'staging_validationsubmission_000000D2eFEnr3lEmmb8zmvBbyhnc',
      result: 'pass',
      type: 'application_check',
      data: {
        analysis:
          'English (en) is the official language of Great Britain (GBR) and is widely spoken throughout the country.',
        checkName: 'language_match',
        applicationLanguage: 'en'
      }
    },
    {
      id: 'staging_fraudcheckresult_000000D2eFFMAKTLYgPuQG3hJqCiv',
      validationSubmissionId: 'staging_validationsubmission_000000D2eFEnr3lEmmb8zmvBbyhnc',
      result: 'pass',
      type: 'application_check',
      data: {
        emails: null,
        websites: {
          agent: 'http://example.com',
          distance: 29,
          organisation: 'https://www.actionagainsthunger.org.uk/'
        },
        checkName: 'detect_minor_typos'
      }
    },
    {
      id: 'staging_fraudcheckresult_000000D2eFFMAKTLYgPuQG3hJqCix',
      validationSubmissionId: 'staging_validationsubmission_000000D2eFEnr3lEmmb8zmvBbyhnc',
      result: 'fail',
      type: 'application_check',
      data: {
        count: 5,
        checkName: 'has_too_many_applications_across_partners'
      }
    },
    {
      id: 'staging_fraudcheckresult_000000D2eFFMAKTLYgPuQG3hJqCiw',
      validationSubmissionId: 'staging_validationsubmission_000000D2eFEnr3lEmmb8zmvBbyhnc',
      result: 'pass',
      type: 'application_check',
      data: {
        checkName: 'has_applied_for_different_countries',
        countries: ['GBR'],
        applicationCountry: 'GBR'
      }
    },
    {
      id: 'staging_fraudcheckresult_000000D2eFEuiVu3jxlITskULx0O9',
      validationSubmissionId: 'staging_validationsubmission_000000D2eFEnr3lEmmb8zmvBbyhnc',
      result: 'pass',
      type: 'advanced_domain_check',
      data: {
        checkName: 'who_is_check',
        analysedDomains: [
          {
            flags: [],
            domain: 'www.actionagainsthunger.org.uk',
            source: ['organisation_website'],
            registrar: 'Gandi [Tag = GANDI]',
            registeredDate: '05-Aug-2007'
          },
          {
            flags: [],
            domain: 'example.com',
            source: ['suggested_website'],
            registrar: 'RESERVED-Internet Assigned Numbers Authority',
            registeredDate: '1995-08-14T04:00:00Z'
          },
          {
            flags: [],
            domain: 'thefarmanimalsanctuary.co.uk',
            source: ['applicant_email'],
            registrar: 'One.com A/S [Tag = TJNF-DK]',
            registeredDate: '11-Oct-2006'
          }
        ]
      }
    },
    {
      id: 'staging_fraudcheckresult_000000D2eFEuiVu3jxlITskULx0OA',
      validationSubmissionId: 'staging_validationsubmission_000000D2eFEnr3lEmmb8zmvBbyhnc',
      result: 'pass',
      type: 'advanced_domain_check',
      data: {
        checkName: 'geolocation_check',
        analysedDomains: [
          {
            asn: 209242,
            flags: [],
            domain: 'www.actionagainsthunger.org.uk',
            source: ['organisation_website']
          },
          {
            asn: 20940,
            flags: [],
            domain: 'example.com',
            source: ['suggested_website']
          },
          {
            asn: 14061,
            flags: [],
            domain: 'thefarmanimalsanctuary.co.uk',
            source: ['applicant_email']
          }
        ]
      }
    },
    {
      id: 'staging_fraudcheckresult_000000D2eFEuiVu3jxlITskULx0Nu',
      validationSubmissionId: 'staging_validationsubmission_000000D2eFEnr3lEmmb8zmvBbyhnc',
      result: 'fail',
      type: 'basic_domain_check',
      data: {
        checkName: 'is_website_empty',
        analysedDomains: [
          {
            domain: 'www.actionagainsthunger.org.uk',
            source: 'organisation_website',
            isWebsiteEmpty: false
          },
          {
            domain: 'example.com',
            source: 'suggested_website',
            isWebsiteEmpty: true
          }
        ]
      }
    },
    {
      id: 'staging_fraudcheckresult_000000D2eFEuiVu3jxlITskULx0Nx',
      validationSubmissionId: 'staging_validationsubmission_000000D2eFEnr3lEmmb8zmvBbyhnc',
      result: 'pass',
      type: 'basic_domain_check',
      data: {
        checkName: 'is_ssl_certificate_valid',
        analysedDomains: [
          {
            domain: 'www.actionagainsthunger.org.uk',
            source: 'organisation_website',
            isSslCertificateValid: true
          },
          {
            domain: 'example.com',
            source: 'suggested_website',
            isSslCertificateValid: true
          }
        ]
      }
    },
    {
      id: 'staging_fraudcheckresult_000000D2eFEuiVu3jxlITskULx0Nv',
      validationSubmissionId: 'staging_validationsubmission_000000D2eFEnr3lEmmb8zmvBbyhnc',
      result: 'pass',
      type: 'basic_domain_check',
      data: {
        checkName: 'has_website_placeholder_content',
        analysedDomains: [
          {
            domain: 'www.actionagainsthunger.org.uk',
            source: 'organisation_website',
            hasWebsitePlaceholderContent: false
          },
          {
            domain: 'example.com',
            source: 'suggested_website',
            hasWebsitePlaceholderContent: false
          }
        ]
      }
    },
    {
      id: 'staging_fraudcheckresult_000000D2eFEuiVu3jxlITskULx0Nw',
      validationSubmissionId: 'staging_validationsubmission_000000D2eFEnr3lEmmb8zmvBbyhnc',
      result: 'pass',
      type: 'basic_domain_check',
      data: {
        checkName: 'has_website_redirect',
        analysedDomains: [
          {
            domain: 'www.actionagainsthunger.org.uk',
            source: 'organisation_website',
            hasWebsiteRedirect: false
          },
          {
            domain: 'example.com',
            source: 'suggested_website',
            hasWebsiteRedirect: false
          }
        ]
      }
    },
    {
      id: 'staging_fraudcheckresult_000000D2eFEuiVu3jxlITskULx0Nt',
      validationSubmissionId: 'staging_validationsubmission_000000D2eFEnr3lEmmb8zmvBbyhnc',
      result: 'pass',
      type: 'basic_domain_check',
      data: {
        checkName: 'can_website_be_opened',
        analysedDomains: [
          {
            domain: 'www.actionagainsthunger.org.uk',
            source: 'organisation_website',
            canBeOpened: true
          },
          {
            domain: 'example.com',
            source: 'suggested_website',
            canBeOpened: true
          }
        ]
      }
    },
    {
      id: 'staging_fraudcheckresult_000000D2eFEuiVu3jxlITskULx0Ny',
      validationSubmissionId: 'staging_validationsubmission_000000D2eFEnr3lEmmb8zmvBbyhnc',
      result: 'pass',
      type: 'basic_domain_check',
      data: {
        checkName: 'has_website_hosting_provider_subdomain',
        analysedDomains: [
          {
            domain: 'www.actionagainsthunger.org.uk',
            source: 'organisation_website',
            hasWebsiteHostingProviderSubdomain: false
          },
          {
            domain: 'example.com',
            source: 'suggested_website',
            hasWebsiteHostingProviderSubdomain: false
          }
        ]
      }
    },
    {
      id: 'staging_fraudcheckresult_000000D2eFEuiVu3jxlITskULx0Ns',
      validationSubmissionId: 'staging_validationsubmission_000000D2eFEnr3lEmmb8zmvBbyhnc',
      result: 'fail',
      type: 'basic_domain_check',
      data: {
        checkName: 'disallowed_domain_list',
        analysedDomains: [
          {
            domain: 'www.actionagainsthunger.org.uk',
            source: 'organisation_website',
            isDisallowed: false
          },
          {
            domain: 'example.com',
            source: 'suggested_website',
            isDisallowed: true
          },
          {
            domain: 'thefarmanimalsanctuary.co.uk',
            source: 'applicant_email',
            isDisallowed: false
          }
        ]
      }
    }
  ]
}
