import { BankDetailsReadViewProps } from './BankDetailsReadView.types'
import { UploadDocument } from '../../uploadDocument/UploadDocument'
import { WireTransferType } from '../../../types/bankDetails.types'
import styled from 'styled-components'
import { FormField, TextInput } from '@percent/lemonade'
import { getBankDetailsFields } from '../../../utils'

const StyledUploadDocumentContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

export function BankDetailsReadView({
  bankAccountDetails,
  translations,
  fileFieldText,
}: BankDetailsReadViewProps) {
  const { type: transferType } = bankAccountDetails.wireTransferDetails
  const fields = getBankDetailsFields(bankAccountDetails.wireTransferDetails)

  return (
    <StyledUploadDocumentContainer>
      {transferType && Object.values(WireTransferType).includes(transferType) && (
        <>
          {bankAccountDetails.holderName && (
            <FormField
              label={translations.accountHolderName}
              disabled
              data-testid="holderName"
              necessity="required"
            >
              <TextInput
                name="holderName"
                value={bankAccountDetails.holderName}
              />
            </FormField>
          )}
          {fields.map((field) => (
            <FormField
              label={field.label}
              disabled
              data-testid={field.key}
              necessity="required"
            >
              <TextInput name={field.key} value={field.value} />
            </FormField>
          ))}
        </>
      )}
      <UploadDocument
        helperText={translations.helperBankAccountText}
        helperLinkText={translations.helperBankAccountLinkText}
        uploadDocumentPlaceholder={translations.uploadDocumentPlaceholder}
        labelText={translations.proofBankAccount}
        uploadButton={translations.uploadDocument}
        fileFieldText={fileFieldText}
        setSelectedFile={() => undefined}
        buttonFlag={false}
        disableUploadBtn={true}
        inputDisableStyle={true}
      />
    </StyledUploadDocumentContainer>
  )
}
