import { format, formatDistanceToNow } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { AgentVerificationLog } from '@percent/admin-dashboard/api/types'

export const useAgentVerificationTimeline = (logs: AgentVerificationLog[]) => {
  const { t } = useTranslation()

  return logs
    ?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    ?.map(log => {
      const createdAtDate = new Date(log.createdAt)
      const oneHourAgo = new Date(Date.now() - 60 * 60 * 1000)

      return {
        title: t(`typography.agentVerificationTimeline.${log.type}`),
        description:
          typeof log.data.accountEmail === 'string'
            ? t('typography.by', { author: String(log.data.accountEmail) })
            : undefined,
        additionalDescription:
          createdAtDate < oneHourAgo
            ? format(createdAtDate, 'yyyy-MM-dd HH:mm')
            : formatDistanceToNow(createdAtDate, { addSuffix: true })
      }
    })
}
