import { useActivityTagsState } from '@percent/admin-dashboard/common/hooks'
import { EligibilitySettings } from './EligibilitySettings'
import { EligibilitySettingsContainerProps } from './EligibilitySettingsContainer.types'

export function EligibilitySettingsContainer({ partner, refreshPartnerApi }: EligibilitySettingsContainerProps) {
  const { activityTags: data, isLoading, errorMessage, refresh } = useActivityTagsState()

  return (
    <EligibilitySettings
      tagsData={data}
      isLoading={isLoading}
      errorMessage={errorMessage}
      partner={partner}
      refresh={refresh}
      refreshPartnerApi={refreshPartnerApi}
    />
  )
}
