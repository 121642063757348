export const mockActivityTags = {
  data: [
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ2',
      name: 'Animal, Environment & Conservation',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZH',
          name: 'Farming & Agriculture'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZI',
          name: 'Environmental education'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZJ',
          name: 'Conservation'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZK',
          name: 'Climate Change'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZL',
          name: 'Parks'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZM',
          name: 'Animal protection & welfare'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ3',
      name: 'Arts, Cultural & Historic',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZN',
          name: 'Museum'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZO',
          name: 'Public library'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZP',
          name: 'Library'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZQ',
          name: 'Theatre'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZR',
          name: 'Historical Society & Preservation'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZS',
          name: 'Broadcasting'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZT',
          name: 'Music'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZU',
          name: 'Performing Arts'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZV',
          name: 'Arts'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZW',
          name: 'Publishing'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ4',
      name: 'Business, Employment & Professional Associations',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZX',
          name: 'Financial Services'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZY',
          name: 'Business Development'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZZ',
          name: 'Professional Organisations & Societies'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZa',
          name: 'Employment'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZb',
          name: 'Job training'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZc',
          name: 'Mutual organisation'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZd',
          name: 'Fraternities or sororities'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZe',
          name: 'Employee/Membership Benefit Organisation'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ5',
      name: 'Development, Housing & Hunger',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZf',
          name: 'Community Development'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZg',
          name: 'Economic Development'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZh',
          name: 'Housing & Shelter'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZi',
          name: 'Poverty'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZj',
          name: 'Homelessness'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZk',
          name: 'Hunger'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ6',
      name: 'Disaster Relief & Public Safety',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZl',
          name: 'Emergency aid'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZm',
          name: 'Disaster relief'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZn',
          name: 'Public Safety'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZp',
          name: 'First Aid'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ7',
      name: 'Health & Medicine',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZq',
          name: 'Public Hospital'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZr',
          name: 'Private Hospital'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZs',
          name: 'Mental Health'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZt',
          name: 'Speciality Hospitals'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZu',
          name: 'Medical Research'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZv',
          name: 'Disability'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZw',
          name: 'Health Clinics'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZx',
          name: 'Rehabilitation'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZy',
          name: 'Nursing home'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZz',
          name: 'Hospital auxiliaries'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja0',
          name: 'Health insurance'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja1',
          name: 'Group health plans'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja2',
          name: 'NHS Charity Trust'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ8',
      name: 'International & Foreign Affairs',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja3',
          name: 'International Development'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja4',
          name: 'International Aid/Relief'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja5',
          name: 'Foreign Policy'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja6',
          name: 'United Nations'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja7',
          name: 'National Security'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ9',
      name: 'Law, Advocacy & Politics',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja8',
          name: 'Human & Civil Rights'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja9',
          name: 'Social Action'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaA',
          name: 'Advocacy'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaB',
          name: 'Legislation'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaC',
          name: 'Political Activity'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaD',
          name: 'Legal Services/Aid'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaE',
          name: 'Governmental'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZA',
      name: 'Military & Veterans',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaF',
          name: 'Veterans'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaG',
          name: 'Patriotic activities'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZB',
      name: 'Philanthropy, Volunteering & Grantmaking',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaH',
          name: 'Grantmaking'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZC',
      name: 'Religion',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaO',
          name: 'Place of worship'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZD',
      name: 'School, College & Education',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaU',
          isEducationStage: true,
          name: 'Primary, Elementary & K-12 School'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaV',
          isEducationStage: true,
          name: 'Preschool, Nursery & Kindergarten'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaW',
          isEducationStage: true,
          name: 'Secondary & High School'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaX',
          name: 'Higher Education'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaY',
          name: 'Vocational and Technical School'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaZ',
          name: 'Alumni group'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaa',
          name: 'Parent Teacher Association'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjab',
          name: 'Scholarships'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjac',
          name: 'Student welfare'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjad',
          name: 'Special Education'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjae',
          name: 'Graduate & Professional School'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaf',
          name: 'University'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjag',
          name: 'Student services'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjah',
          name: 'Undergraduate college'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjai',
          name: 'Charter school'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZE',
      name: 'Science, Research & Technology',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaj',
          name: 'Scientific Research'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjak',
          name: 'Engineering & Technology'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjal',
          name: 'General science'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjam',
          name: 'Social science'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjan',
          name: 'Computer Science'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZF',
      name: 'Social Services',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjao',
          name: 'Social Services'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjap',
          name: 'Community Development'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaq',
          name: 'Family/Child Services'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjar',
          name: 'Social Welfare'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZG',
      name: 'Sports, Recreation & Social',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjas',
          name: 'Professional Sports'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjat',
          name: 'Amateur Sports'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjau',
          name: 'Recreational facilities'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjav',
          name: 'Sports Associations'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaw',
          name: 'Social Clubs'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjax',
          name: 'Parks & Playgrounds'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjay',
          name: 'Youth Centre & Clubs'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaz',
          name: 'Youth services'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjb0',
          name: 'Social Enterprise'
        },
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjb1',
          name: 'Community Interest Company'
        }
      ],
      updatedAt: null
    }
  ],
  object: 'activity_tag'
}
