import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  AgentVerificationRejectionReasonDropdownCodes,
  type AgentVerificationSimilar
} from '@percent/admin-dashboard/api/types'
import { getFormattedDate } from '@percent/admin-dashboard/common/library/utility/date'
import { AgentVerificationStatus } from '@percent/domain/verifications'
import {
  Alert,
  Badge,
  ButtonText,
  CardDashboard,
  type TableColumn,
  Heading,
  IllustratedMessage,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableProvider,
  TableRow,
  Tooltip,
  Typography
} from '@percent/lemonade'
import styles from './AgentVerificationSimilar.module.scss'
import { AgentVerificationSimilarProps, AgentVerificationStatusMap } from './AgentVerificationSimilar.types'

const StyledHeading = styled(Heading)`
  margin-bottom: 1rem;
`

const statusMap: AgentVerificationStatusMap = {
  rejected: {
    variant: 'critical',
    message: 'status.rejected',
    icon: 'reject'
  },
  approved: {
    variant: 'positive',
    message: 'status.approved',
    icon: 'check-badge'
  },
  pending: {
    variant: 'default',
    message: 'status.pending',
    icon: 'clock'
  }
}

const getAVStatus = (status?: AgentVerificationStatus): keyof typeof statusMap => {
  if (
    [
      AgentVerificationStatus.PENDING,
      AgentVerificationStatus.PENDING_REVIEW,
      AgentVerificationStatus.PENDING_USER_VERIFICATION
    ].includes(status as AgentVerificationStatus)
  ) {
    return 'pending'
  }

  if (status === 'approved') {
    return 'approved'
  }

  return 'rejected'
}

function StatusBadge({ status }: Pick<AgentVerificationSimilar, 'status'>) {
  const aVStatus = getAVStatus(status)
  const { t } = useTranslation()

  return (
    <Badge variant={statusMap[aVStatus].variant} icon={statusMap[aVStatus].icon}>
      {t(statusMap[aVStatus].message)}
    </Badge>
  )
}

function RejectionBadge({ rejectionReasonCode }: Pick<AgentVerificationSimilar, 'rejectionReasonCode'>) {
  const { t } = useTranslation()

  if (!rejectionReasonCode) return <Typography>{t('status.na')}</Typography>

  const maxCharacterCount = 30
  const content = t(`dropdown.reject.${rejectionReasonCode}`).trim()
  const slicedContent = content.slice(0, maxCharacterCount).trim()
  const fakeElipsisContent = content.length > maxCharacterCount ? `${slicedContent}...` : slicedContent

  if (rejectionReasonCode === AgentVerificationRejectionReasonDropdownCodes.Other)
    return (
      <Tooltip content={content}>
        <div className={styles.rejectionBadgeWrapper}>
          <Badge variant="default">{fakeElipsisContent}</Badge>
        </div>
      </Tooltip>
    )

  return (
    <Tooltip content={content}>
      <div className={styles.rejectionBadgeWrapper}>
        <Badge variant="warning" icon="warning">
          {fakeElipsisContent}
        </Badge>
      </div>
    </Tooltip>
  )
}

export function AgentVerificationSimilar({
  isLoadingSimilarApplications,
  similarApplications
}: AgentVerificationSimilarProps) {
  const { t } = useTranslation()
  const columns: TableColumn[] = [
    { id: t('table.organisationName') },
    { id: t('table.agentEmail') },
    {
      id: t('table.partner'),
      props: {
        width: '160px'
      }
    },
    {
      id: t('table.rejectionReason'),
      props: {
        width: '240px'
      }
    },
    {
      id: t('table.status'),
      props: {
        width: '160px'
      }
    },
    {
      id: t('table.date'),
      props: {
        width: '160px'
      }
    }
  ]

  if (isLoadingSimilarApplications) return <Loader />

  if (similarApplications?.length === 0) {
    return (
      <CardDashboard data-testid="agent-verification-empty-similar">
        <IllustratedMessage illustration="no-results" description={t('typography.noSimilarApplications')} />
      </CardDashboard>
    )
  }

  return (
    <CardDashboard data-testid="agent-verification-similar">
      <StyledHeading level="h2">{t('typography.relatedApplications')}</StyledHeading>
      <Alert variant="info">{t('typography.agentSimilarApplications', { amount: similarApplications?.length })}</Alert>
      <TableProvider
        columns={columns}
        illustration="no-results"
        emptyTableDescription={t('typography.noSimilarApplications')}
        isLoading={isLoadingSimilarApplications}
        totalResults={null}
        emptyTableTitle={t('typography.noSimilarApplications')}
      >
        <Table>
          <TableBody>
            {similarApplications?.map(application => (
              <TableRow key={application.id}>
                <TableCell>{application.organisationName}</TableCell>
                <TableCell>{application.email}</TableCell>
                <TableCell>{application.partnerName}</TableCell>
                <TableCell>
                  <RejectionBadge rejectionReasonCode={application.rejectionReasonCode} />
                </TableCell>
                <TableCell>
                  <StatusBadge status={application.status} />
                </TableCell>
                <TableCell>{getFormattedDate(application.createdAt)}</TableCell>
                <TableCell>
                  {application.organisationId ? (
                    <a href={`/agent-verification/${application.id}`} target="_blank" rel="noreferrer">
                      <ButtonText icon="expand" />
                    </a>
                  ) : (
                    <ButtonText icon="expand" disabled />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableProvider>
    </CardDashboard>
  )
}
