import React from 'react'
import { useTranslation } from 'react-i18next'

import { CardContent } from '@percent/lemonade'
import { PaymentMethod } from '@percent/admin-dashboard/api/types'
import { ExternalLink } from '@percent/admin-dashboard/common/components/externalLink/ExternalLink'
import { PaymentTypeWithButton } from './PaymentTypeWithButton'

export function CardView({ paymentMethod, refresh }: { paymentMethod: PaymentMethod; refresh: VoidFunction }) {
  const { t } = useTranslation()

  return (
    <>
      <PaymentTypeWithButton
        title={t('typography.paymentMethod')}
        text={t('typography.card')}
        paymentMethod={paymentMethod}
        refresh={refresh}
      />
      <CardContent title={t('typography.paymentUrl')} testId="card-details-payment-url">
        {(paymentMethod.paymentUrl && (
          <ExternalLink
            height="24px"
            width="24px"
            url={paymentMethod.paymentUrl}
            text={paymentMethod.paymentUrl.replace(/^https?:\/\//, '')}
          />
        )) ||
          t('typography.notAvailable')}
      </CardContent>
    </>
  )
}
