import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockAutoApprovalListResponse } from '@percent/admin-dashboard/fixtures/api/mockAutoApprovalListResponse'

export const autoApprovalListHandlers = [
  rest.get(`${config.urls.api}v1/admin/verifications-allowlist`, async (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockAutoApprovalListResponse))
  }),
  rest.post(`${config.urls.api}v1/admin/verifications-allowlist`, async (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
  rest.delete(`${config.urls.api}v1/admin/verifications-allowlist/:id`, async (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  })
]
