import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAgentVerificationDecisionContext } from '../../../useAgentVerificationDecisionContext/useAgentVerificationDecisionContext'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { AcknowledgeModal, Checkbox, ConfirmationModal, Modal, Typography } from '@percent/lemonade'
import { AgentVerificationMarkAsSafeModalProps } from './AgentVerificationMarkAsSafeModal.types'

export function AgentVerificationMarkAsSafeModal({
  id,
  fullName,
  organisationName,
  setIsMarkedAsSafe
}: AgentVerificationMarkAsSafeModalProps) {
  const { t } = useTranslation()
  const { setOpenedModal, isMarkAsSafeModalOpened } = useAgentVerificationDecisionContext()

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [status, setStatus] = useState<'success' | 'error' | null>(null)
  const { agentVerificationService } = useServices()

  const handleClose = () => {
    setIsSubmitDisabled(true)
    setOpenedModal(undefined)
    setStatus(null)
  }

  const handleOnSuccess = () => {
    setIsMarkedAsSafe(true)
    setStatus('success')
  }

  const handleOnError = () => {
    setStatus('error')
  }

  const handleCheckboxChange = (e: FormEvent<HTMLInputElement>) => setIsSubmitDisabled(!e.currentTarget.checked)

  const [{ isLoading }, { apiFunc: requestMarkAsSafe }] = useMutation(
    agentVerificationService.requestMarkAsSafe,
    handleOnSuccess,
    handleOnError
  )

  const handleApprove = () => requestMarkAsSafe({ id })

  if (status === 'success') {
    return (
      <Modal
        open={isMarkAsSafeModalOpened}
        onClose={handleClose}
        aria-labelledby="agent-verification-mark-as-safe-modal"
      >
        <AcknowledgeModal
          result="positive"
          title={t('dialog.markAsSafe.success.title')}
          description={t('dialog.markAsSafe.success.description', { fullName })}
          buttonText={t('button.close')}
          handleClose={handleClose}
        />
      </Modal>
    )
  }

  if (status === 'error') {
    return (
      <Modal
        open={isMarkAsSafeModalOpened}
        onClose={handleClose}
        aria-labelledby="agent-verification-mark-as-safe-modal"
      >
        <AcknowledgeModal
          result="negative"
          title={t('dialog.markAsSafe.error.title')}
          description={t('dialog.markAsSafe.error.description')}
          buttonText={t('button.close')}
          handleClose={handleClose}
        />
      </Modal>
    )
  }

  return (
    <Modal open={isMarkAsSafeModalOpened} onClose={handleClose} aria-labelledby="agent-verification-mark-as-safe-modal">
      <ConfirmationModal
        title={t('dialog.markAsSafe.title')}
        loading={isLoading}
        primaryButtonText={t('dialog.markAsSafe.submit')}
        disabled={isSubmitDisabled}
        primaryBtnTestId="mark-as-safe-submit"
        secondaryButtonText={t('button.cancel')}
        handleSubmit={handleApprove}
        handleClose={handleClose}
      >
        <Typography>{t('dialog.markAsSafe.description', { fullName, organisationName })}</Typography>
        <Checkbox
          label={t('dialog.markAsSafe.checkbox')}
          name="markAsSafe"
          variant="default"
          defaultChecked={isSubmitDisabled}
          onChange={handleCheckboxChange}
        />
      </ConfirmationModal>
    </Modal>
  )
}
