import { Paper, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider } from '@mui/material'
import scrollIntoView from 'scroll-into-view-if-needed'

import { PercentButton } from '@percent/admin-dashboard/common/components'
import styles from './BankDetailsHeader.module.scss'
import { StatusBadge } from '@percent/admin-dashboard/app/bankDetails/statusBadge/StatusBadge'
import { BankDetailsHeaderProps } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsHeader/BankDetailsHeader.types'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { Alert, Badge, Copy } from '@percent/lemonade'

export function BankDetailsHeader({
  organizationName,
  status,
  reference,
  setDialogState,
  setFormStatus,
  disabledApprove,
  bankData,
  validationFeatureFlag,
  claimer
}: BankDetailsHeaderProps) {
  const validationData = bankData.bankValidationData
  const hasValidationErrors =
    !!validationData && validationData.validationErrors && validationData.validationErrors.length > 0
  const { bankDetailsReviewApprovalFeatureFlag, bankDetailsReviewDeleteFeatureFlag } = useFeatureFlag()
  const { t } = useTranslation()

  function scrollToBankDetails() {
    const element = document.querySelector(`#bankDetailsSection`)

    if (element) {
      scrollIntoView(element, {
        behavior: 'smooth',
        block: 'center'
      })
    }
  }

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.container}>
      <Grid item xs={12} className={styles.nonprofitDetail}>
        <div className={styles.ellipsisParent}>
          <div className={styles.ellipsisContainer}>
            <div className={styles.ellipsisText}>
              <div className={styles.headingContainer}>
                <Typography variant="h6" className={styles.heading}>
                  {organizationName}
                </Typography>
                {claimer ? (
                  <div className={styles.claimedBadge}>
                    <Badge variant="positive" icon="check">
                      {t('typography.claimed')}
                    </Badge>
                  </div>
                ) : (
                  <div className={styles.claimedBadge}>
                    <Badge variant="default" icon="reject">
                      {t('typography.unclaimed')}
                    </Badge>
                  </div>
                )}
              </div>
              <Box className={styles.additionalInfo}>
                <ul className={styles.headerList}>
                  <li>
                    <strong>{t('typography.bankDetailsReviewRequestReference')}</strong>&nbsp;
                    <Copy text={reference}>{reference}</Copy>
                  </li>
                  <Divider orientation="vertical" flexItem />
                  <li>
                    <StatusBadge status={status} />
                  </li>
                  {claimer && (
                    <>
                      <Divider orientation="vertical" flexItem />
                      <li>
                        <strong>{t('typography.claimer')}</strong>&nbsp;
                        {claimer.name ? (
                          <span>
                            {claimer.name} ({claimer.email})
                          </span>
                        ) : (
                          <span>{claimer.email}</span>
                        )}
                      </li>
                    </>
                  )}
                </ul>
              </Box>
            </div>
            {status === 'pending' && bankDetailsReviewApprovalFeatureFlag && (
              <div className={styles.buttonsContainer}>
                <PercentButton
                  title={t('button.approve')}
                  className={styles.approveButton}
                  disabled={disabledApprove}
                  testId="approve-button"
                  onClick={() => {
                    setDialogState(true)
                    setFormStatus('approved')
                  }}
                />
                <PercentButton
                  title={t('button.reject')}
                  testId="reject-button"
                  onClick={() => {
                    setDialogState(true)
                    setFormStatus('rejected')
                  }}
                  className={styles.rejectButton}
                />
              </div>
            )}
            {status === 'approved' && bankDetailsReviewDeleteFeatureFlag && (
              <div className={styles.buttonsContainer}>
                <PercentButton
                  title={t('button.remove')}
                  testId="remove-button"
                  onClick={() => {
                    setDialogState(true)
                    setFormStatus('deleted')
                  }}
                  className={styles.rejectButton}
                />
              </div>
            )}
          </div>
          {validationFeatureFlag && (
            <Box className={styles.additionalInfo}>
              {!!validationData && !validationData.success && (
                <div className={styles.validationStatus}>
                  <Alert variant="error" title={t('bankDetails.validation.invalidDataTitle')} descriptionVariant="div">
                    {!hasValidationErrors && t('bankDetails.validation.unknownErrorReadOnly')}
                    {hasValidationErrors && (
                      <>
                        <span>{t('bankDetails.validation.invalidDataError')}</span>{' '}
                        <span
                          className={styles.bankDetailsAnchor}
                          onClick={() => scrollToBankDetails()}
                          role="link"
                          tabIndex={0}
                          aria-hidden="true"
                          data-testid="validation-error-link"
                        >
                          {t('bankDetails.validation.checkErrors')}
                        </span>
                      </>
                    )}
                  </Alert>
                </div>
              )}
            </Box>
          )}
        </div>
      </Grid>
    </Grid>
  )
}
