import { AutoRequestRejectionListItem } from '@percent/admin-dashboard/api/actions/autoRequestRejectionList/autoRequestRejectionList.types'
import { PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'

export const mockVrBlockListResponse: PBPListResponse<AutoRequestRejectionListItem> = {
  data: [
    {
      createdAt: '2025-03-26T08:52:16.846Z',
      id: 'staging_vrblocklist_000000D3RSnIgnm8ZXKnmj80uc74g',
      organisationIdInRegistry: 'org1',
      registryId: 'reg1'
    },
    {
      createdAt: '2025-03-26T08:52:16.846Z',
      id: 'staging_vrblocklist_11111D3RSnIgnm8ZXKnmj80uc74g',
      organisationIdInRegistry: 'org2',
      registryId: 'reg2'
    },
    {
      createdAt: '2025-03-26T08:52:16.846Z',
      id: 'staging_vrblocklist_22222D3RSnIgnm8ZXKnmj80uc74g',
      organisationIdInRegistry: 'org3',
      registryId: 'reg3'
    },
    {
      createdAt: '2025-03-26T08:52:16.846Z',
      id: 'staging_vrblocklist_333333D3RSnIgnm8ZXKnmj80uc74g',
      organisationIdInRegistry: 'org4',
      registryId: 'reg4'
    }
  ],
  totalResults: 4,
  pageSize: 4,
  object: 'agent_verification_admin_auto_rejection_list_agent',
  _links: {
    current: 'https://staging-api.poweredbypercent.com/v1/admin/agent-verifications-auto-rejection-list?pageSize=25'
  }
}
