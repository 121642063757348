import React from 'react'
import styled from 'styled-components'
import { ModalFooterProps, StyledModalFooterProps } from './modal-footer.types'

export const StyledModalFooter = styled.div<StyledModalFooterProps>`
  ${({ theme, $justify }) =>
    `
    display: flex;
    justify-content: ${$justify};
    padding: ${theme.sizes[6]};
    gap: ${theme.sizes[4]};
  `}
`
export const ModalFooter = ({
  justify = 'flex-end',
  children,
}: ModalFooterProps) => {
  return <StyledModalFooter $justify={justify}>{children}</StyledModalFooter>
}
