import { PBPGetResponse, PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'
import {
  AddToAutoApprovalListProps,
  AutoApprovalListServiceCapabilities,
  AutoApprovalListItem,
  GetAutoApprovalListProps
} from './autoApprovalList.types'

const autoApprovalListUrl = 'v1/admin/verifications-allowlist'

export const autoApprovalListService = ({ percentClient }: AutoApprovalListServiceCapabilities) => ({
  getAutoApprovalList: (params: GetAutoApprovalListProps) =>
    percentClient.get<PBPListResponse<AutoApprovalListItem>>(autoApprovalListUrl, { params }),
  addToAutoApprovalList: (params: AddToAutoApprovalListProps) =>
    percentClient.post<PBPGetResponse<AutoApprovalListItem>>(autoApprovalListUrl, { ...params }),
  removeFromAutoApprovalList: ({ id }: { id: string }) => percentClient.delete(`${autoApprovalListUrl}/${id}`)
})
