import { Alpha3Code } from 'i18n-iso-countries'

import { AgentVerificationStatus } from '@percent/admin-dashboard/api/actions/admin/admin.types'
import {
  AgentVerification,
  AgentVerificationLogType,
  AgentVerificationRejectionReasonDropdownCodes
} from '@percent/admin-dashboard/api/types'

export const mockAV = {
  id: 'agentverification_000000C2rGLv8FSMJGloGT5kXUzKsA',
  firstName: 'John',
  lastName: 'Merril',
  createdAt: '2020-10-13T17:46:54.000Z',
  email: 'john.merril@cancerresearch.org',
  validationRequestId: 'validationrequest_000000C1AZjciXYMmzO202oURVy6g',
  organisationId: 'organisation_000000C1jDNWNYrExOQYep3Psj3OC',
  title: 'Board Member',
  status: AgentVerificationStatus.PENDING as AgentVerificationStatus,
  rejectionReason: 'TBC',
  userVerified: true,
  reviewedBy: 'reviewed',
  organisation: {
    name: 'my nonprofit',
    countryCode: 'ISL' as Alpha3Code
  },
  validationRequest: {
    name: 'my nonprofit'
  },
  partner: {
    name: 'Google'
  }
}

export const mockAgentVerificationsResponse = {
  data: [
    mockAV,
    {
      ...mockAV,
      reviewedBy: 'reviewed',
      partner: {
        name: 'Percent'
      },
      status: 'pending_user_verification' as AgentVerificationStatus,
      email: 'johnn.merril@cancerreach.org'
    }
  ],
  object: 'agent_verification_admin',
  pageSize: 25,
  totalResults: 2,
  _links: {
    current:
      'https://staging-api.poweredbypercent.com/v1/admin/agent-verifications?pageSize=25&sort=createdAt&state=pending'
  }
}

export const mockAgentVerificationsWithLogsResponse = {
  data: [
    ...mockAgentVerificationsResponse.data,
    {
      ...mockAV,
      status: AgentVerificationStatus.PENDING,
      email: 'johnn.merril@cancerreach.org',
      agentVerificationLogs: [
        {
          id: 'staging_avlog_000000CrMxgfgNxRxSpvv5okhMnKe',
          agentVerificationId: 'staging_agentverification_000000CrMxbnkH1ZJXWIPzN6ZR2o5',
          type: 'agent_verification_document_request_email_sent' as AgentVerificationLogType,
          data: {},
          createdAt: '2024-05-29T11:45:05.363Z'
        }
      ]
    }
  ],
  object: 'agent_verification_admin',
  pageSize: 25,
  totalResults: 3,
  _links: {
    current:
      'https://staging-api.poweredbypercent.com/v1/admin/agent-verifications?pageSize=25&sort=createdAt&state=pending'
  }
}

export const mockGoogleAV = {
  data: [mockAV],
  object: 'agent_verification_admin',
  pageSize: 25,
  totalResults: 1,
  _links: {
    current:
      'https://staging-api.poweredbypercent.com/v1/admin/agent-verifications?pageSize=25&sort=createdAt&state=pending'
  }
}

export const mockAgentVerification: AgentVerification = {
  createdAt: '2021-10-04T07:22:33.155Z',
  email: 'email',
  emailVerifiedAt: 'emailVerified',
  firstName: 'firstname',
  externalActionRequired: true,
  configurationName: null,
  configurationId: 'configId',
  id: 'id',
  language: 'en-GB',
  lastName: 'lastname',
  organisationId: 'orgId',
  organisationWebsite: null,
  partnerId: 'partnerId',
  rejectionReasonCode: AgentVerificationRejectionReasonDropdownCodes.UserFailedPercentReview,
  reviewedBy: 'reviewed',
  status: AgentVerificationStatus.PENDING,
  title: 'title',
  updatedAt: '2021-10-04T07:22:33.155Z',
  validationRequest: null,
  validationRequestId: 'id',
  validationSubmissionId: null
}

export const mockAgentVerificationsRejected = {
  ...mockGoogleAV,
  data: [
    {
      ...mockAV,
      status: AgentVerificationStatus.REJECTED
    }
  ]
}

export const mockAgentVerificationsApproved = {
  ...mockGoogleAV,
  data: [
    {
      ...mockAV,
      reviewedBy: 'reviewed',
      status: AgentVerificationStatus.APPROVED
    }
  ]
}

export const mockAgentVerificationsEmpty = {
  data: [{}],
  totalResults: 0
}
