import { Stack } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AgentVerificationStatus, PartnerName } from '@percent/admin-dashboard/api/types'
import { ErrorView, Loader } from '@percent/admin-dashboard/common/components'
import { Documents } from '@percent/admin-dashboard/common/components/documents/Documents'
import { useQuery, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { OrganisationTypes } from '@percent/admin-dashboard/constants/organisationTypes'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { Tab } from '@percent/lemonade'
import { AgentVerificationDetailsProps, AgentVerificationModal } from './AgentVerificationDetails.types'
import { AgentVerificationDecisionContext } from './agentVerificationDecisionContext/AgentVerificationDecisionContext'
import { AgentDetails } from './components/agentDetails/AgentDetails'
import { AgentDetailsTopBar } from './components/agentDetailsTopBar/AgentDetailsTopBar'
import { AgentVerificationDecision } from './components/agentVerificationDecision/AgentVerificationDecision'
import { AgentVerificationDocuments } from './components/agentVerificationDocuments/AgentVerificationDocuments'
import { AgentVerificationDomainAnalysis } from './components/agentVerificationDomainAnalysis/AgentVerificationDomainAnalysis'
import { AgentVerificationOrganisationDetails } from './components/agentVerificationOrganisationDetails/AgentVerificationOrganisationDetails'
import { AgentVerificationSimilar } from './components/agentVerificationSimilar/AgentVerificationSimilar'
import { AgentVerificationTimeline } from './components/agentVerificationTimeline/AgentVerificationTimeline'
import { DomainVerification } from './components/domainVerification/DomainVerification'
import { MatchingDocumentsDetails } from './components/matchingDocumentsDetails/MatchingDocumentsDetails'
import { getFailedDomainAutoChecks } from './utils/getFailedDomainAutoChecks'
import { getGroupedFraudChecks } from './utils/getGroupedFraudChecks'

export function AgentVerificationDetails({
  orgData,
  partnerData,
  orgPartnerLoader,
  orgPartnerError,
  data,
  isLoading,
  errorMessage,
  refresh,
  aproveEmail
}: Readonly<AgentVerificationDetailsProps>) {
  const { id } = useParams<{ id: string }>()
  const [activeTab, setActiveTab] = useState(`/agent-verification/${id}`)
  const [openedModal, setOpenedModal] = useState<AgentVerificationModal | undefined>(undefined)
  const { adminService, agentVerificationService, validationSubmissionsService } = useServices()
  const { domainVerificationFeatureFlag, autoFraudChecksFeatureFlag } = useFeatureFlag()
  const agentVerificationContextValue = useMemo(
    () => ({
      isApproveModalOpened: openedModal === AgentVerificationModal.APPROVE,
      isRejectModalOpened: openedModal === AgentVerificationModal.REJECT,
      isApproveEmailModalOpened: openedModal === AgentVerificationModal.APPROVE_EMAIL,
      isUpdateStatusModalOpened: openedModal === AgentVerificationModal.UPDATE_STATUS,
      isMarkAsSafeModalOpened: openedModal === AgentVerificationModal.MARK_AS_SAFE,
      setOpenedModal
    }),
    [openedModal]
  )

  const [
    { dataOrNull: validationSubmissionDocuments, isLoading: isLoadingValidationSubmissionDocuments },
    { query: refreshValidationSubmissionDocuments }
  ] = useQueryList(
    adminService.getValidationSubmissionDocuments,
    {
      validationSubmissionId: data?.validationSubmissionId
    },
    false
  )

  const [
    { data: agentVerificationLogs, isLoading: isLoadingAgentVerificationLogs },
    { refresh: refreshAgentVerificationLogs }
  ] = useQuery(agentVerificationService.getAgentVerificationLogs, { id })

  const [{ data: agentVerificationSimilar, isLoading: isLoadingAgentVerificationSimilar }] = useQuery(
    agentVerificationService.getAgentVerificationSimilar,
    { id }
  )

  const [{ data: validationSubmissionConfig }] = useQuery(
    validationSubmissionsService.getValidationSubmissionConfiguration,
    { id: data?.configurationId ?? '' },
    { skip: !data?.configurationId || !autoFraudChecksFeatureFlag }
  )
  const [{ data: fraudCheckResults }] = useQuery(agentVerificationService.requestFraudCheckResult, { id })

  const [{ data: domainVerification }] = useQuery(
    agentVerificationService.getAgentVerificationDomainVerification,
    {
      id
    },
    { skip: !domainVerificationFeatureFlag }
  )

  useEffect(() => {
    if (data?.validationSubmissionId) {
      refreshValidationSubmissionDocuments({ validationSubmissionId: data?.validationSubmissionId })
    }
  }, [data, refreshValidationSubmissionDocuments])

  const groupedFraudChecks = useMemo(() => getGroupedFraudChecks(fraudCheckResults), [fraudCheckResults])

  if (errorMessage || orgPartnerError) {
    return <ErrorView errorMessage={errorMessage || orgPartnerError} />
  }

  if (isLoading || orgPartnerLoader) {
    return <Loader />
  }

  const isAutoFraudCheckEnabled = validationSubmissionConfig?.configuration.checks.some(
    c => c.type === 'fraud_check' && c.checks.length > 0
  )

  const showDocumentsWithPreview =
    data?.status &&
    [
      AgentVerificationStatus.PENDING,
      AgentVerificationStatus.PENDING_REVIEW,
      AgentVerificationStatus.PENDING_USER_VERIFICATION
    ].includes(data?.status)

  const isPending =
    data?.status && [AgentVerificationStatus.PENDING, AgentVerificationStatus.PENDING_REVIEW].includes(data?.status)

  const isGooglePartner = partnerData?.name?.toLowerCase() === PartnerName.GOOGLE
  const isMicrosoftPartner = partnerData?.name?.toLowerCase() === PartnerName.MICROSOFT
  const isPendingCanvaEdu =
    isPending &&
    partnerData?.name?.toLowerCase() === PartnerName.CANVA &&
    data?.configuration?.allowedOrganisations?.types?.includes(OrganisationTypes.Education)

  const isApplicationLanguageSuspicious =
    domainVerification?.result?.openAiAnalysis?.languageMatch.match === false &&
    // Show the language automation only if the AV is not rejected
    data?.status !== AgentVerificationStatus.REJECTED

  const failedDomainAutoChecks = groupedFraudChecks?.domains
    .map(c => ({
      name: c.name,
      failedAutoChecks: getFailedDomainAutoChecks(c.fraudChecks)
    }))
    .filter(c => c.failedAutoChecks.total > 0)
  const shouldShowSeparateDomainAnalysis =
    isAutoFraudCheckEnabled && !!failedDomainAutoChecks && failedDomainAutoChecks.length > 0

  const areDomainsAutoApproved = !!(
    domainVerificationFeatureFlag &&
    agentVerificationLogs?.find(log => log.type === 'domain_auto_verified') &&
    !agentVerificationLogs?.find(log => log.type === 'organisation_email_change') &&
    !agentVerificationLogs?.find(log => log.type === 'organisation_domain_change')
  )
  const autoApprovedSources = areDomainsAutoApproved
    ? domainVerification?.result?.domains?.map(domain => domain.source)
    : []

  return (
    <>
      <Stack direction="column" gap={3} mb={3}>
        <AgentVerificationDecisionContext.Provider value={agentVerificationContextValue}>
          <AgentVerificationDecision
            organisationName={orgData?.displayName || orgData?.name}
            agentFullName={`${data?.firstName} ${data?.lastName}`}
            refresh={refresh}
            agentStatus={data?.status}
            isLoading={isLoading}
            errorMessage={errorMessage}
            aproveEmail={aproveEmail}
            fraudFlagsLength={data?.fraudulentFlags?.length ?? 0}
            allowedOrganisations={data?.configuration?.allowedOrganisations?.types || []}
            partnerName={partnerData?.name}
            globalFlags={domainVerification?.result?.globalFlags}
            isStatusDynamic={data?.configuration?.isStatusDynamic}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            // TODO: change this amount to total count whenever BE extends the similar endpoint with pagination (POST-MVP)
            similarApplicationsAmount={agentVerificationSimilar?.length ?? 0}
            fraudCheckResults={fraudCheckResults}
            isAutoFraudCheckEnabled={isAutoFraudCheckEnabled}
          />
        </AgentVerificationDecisionContext.Provider>
        <AgentDetailsTopBar
          agentVerification={data}
          agentVerificationLogs={agentVerificationLogs}
          partnerName={partnerData?.name}
        />
      </Stack>
      <Tab index={`/agent-verification/${id}`} value={activeTab}>
        <Stack
          data-testid={showDocumentsWithPreview && 'documents-preview-layout'}
          sx={{
            flexDirection: {
              xs: 'column',
              lg: showDocumentsWithPreview ? 'row' : 'column'
            },
            gap: showDocumentsWithPreview ? 4 : 2
          }}
        >
          <Stack flex={1}>
            <AgentDetails
              agentVerification={data}
              agentVerificationStatus={data?.status}
              fraudFlags={data?.fraudulentFlags?.filter(f => f.scope === 'agent') ?? []}
              isAgentEmailAutoApproved={areDomainsAutoApproved}
              isApplicationLanguageSuspicious={isApplicationLanguageSuspicious}
              fraudChecks={groupedFraudChecks?.agent}
              isAutoFraudCheckEnabled={isAutoFraudCheckEnabled}
            />
            <AgentVerificationOrganisationDetails
              agentVerificationWebsite={data?.organisationWebsite}
              agentVerification={data}
              organisation={orgData}
              refresh={refresh}
              partnerId={data?.partnerId}
              fraudFlags={data?.fraudulentFlags?.filter(f => f.scope === 'organisation') ?? []}
              agentVerificationLogs={agentVerificationLogs}
              autoApprovedSources={autoApprovedSources}
              fraudChecks={groupedFraudChecks?.domains.length === 1 ? groupedFraudChecks.domains[0] : undefined}
              isAutoFraudCheckEnabled={isAutoFraudCheckEnabled}
            />
            {shouldShowSeparateDomainAnalysis &&
              failedDomainAutoChecks.map((check, index) => (
                <AgentVerificationDomainAnalysis key={check.name} index={index + 1} {...check} />
              ))}
            {domainVerificationFeatureFlag && domainVerification?.result && (
              <DomainVerification domainVerification={domainVerification?.result} />
            )}
          </Stack>
          <Stack flex={1}>
            {(isGooglePartner || isMicrosoftPartner) && isPending ? (
              <AgentVerificationDocuments
                agentVerificationId={data?.id}
                agentVerificationLogs={agentVerificationLogs}
                isLoadingAgentVerificationLogs={isLoadingAgentVerificationLogs}
                refreshAgentVerificationLogs={refreshAgentVerificationLogs}
                isLoading={isLoading || (!!data?.validationSubmissionId && isLoadingValidationSubmissionDocuments)}
                documents={validationSubmissionDocuments || []}
                agentEmail={data?.email}
              />
            ) : (
              <Documents
                isLoading={isLoading || (!!data?.validationSubmissionId && isLoadingValidationSubmissionDocuments)}
                documents={validationSubmissionDocuments || []}
                withPreview={showDocumentsWithPreview}
              >
                {isPendingCanvaEdu && <MatchingDocumentsDetails documents={validationSubmissionDocuments || []} />}
              </Documents>
            )}
          </Stack>
        </Stack>
      </Tab>
      <Tab index={`/agent-verification/${id}/timeline`} value={activeTab}>
        <AgentVerificationTimeline
          logs={agentVerificationLogs || []}
          isLoadingAgentVerificationLogs={isLoadingAgentVerificationLogs}
        />
      </Tab>
      <Tab index={`/agent-verification/${id}/other`} value={activeTab}>
        <AgentVerificationSimilar
          similarApplications={agentVerificationSimilar}
          isLoadingSimilarApplications={isLoadingAgentVerificationSimilar}
        />
      </Tab>
    </>
  )
}
