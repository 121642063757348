import { BankAccountWireTransferDetails } from './types/bankDetails.types'

export const getBankDetailsFields = (
  bankDetailsWithType: BankAccountWireTransferDetails
) => {
  const { type, ...bankDetails } = bankDetailsWithType

  return Object.entries(bankDetails).map(([key, { label, value }]) => ({
    key,
    value,
    label,
  }))
}
