import { useCallback, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'

import { APIErrorHandler } from '../../library/APIErrorHandler'

import { PBPGetResponse } from '@percent/admin-dashboard/services/pbpResponses'

type InferData<A> = A extends AxiosResponse<infer B> ? (B extends PBPGetResponse<infer C> ? C : any) : any

export const useQuery = <A = any, B = any>(
  api: (_: A) => Promise<B>,
  initialQuery: A,
  options?: {
    skip?: boolean
  }
) => {
  const [body, setBody] = useState<any>(null)
  const data = body?.data || null
  const [isLoading, setIsLoading] = useState(!options?.skip)
  const [error, setError] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [status, setStatus] = useState(null)
  const [queryParams, setQueryParams] = useState(initialQuery)
  const query = useCallback((newQueryParams: A) => {
    setQueryParams(newQueryParams)
  }, [])

  const refresh = useCallback(() => {
    setQueryParams({ ...queryParams })
  }, [queryParams])

  const { t } = useTranslation()

  useEffect(() => {
    if (options?.skip) {
      return
    }

    let didCancel = false

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const { data: responseBody, status: apiStatus } = (await api(initialQuery)) as any

        if (!didCancel) {
          if (apiStatus === 204 || !responseBody) {
            setErrorMessage(t('errorMessage.noResult'))
          } else {
            setErrorMessage('')
          }

          setBody(responseBody)
          setStatus(apiStatus)
          setIsLoading(false)
        }
      } catch (err) {
        if (!didCancel) {
          setErrorMessage(t(APIErrorHandler(err?.response?.data?.error)))
          setError(err?.response?.data?.error)
          setStatus(err?.response?.status)
          setIsLoading(false)
        }
      }

      return () => {
        didCancel = true
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, queryParams, options?.skip])

  return [
    {
      body,
      data: data as InferData<B>,
      isLoading,
      errorMessage,
      error,
      status
    },
    {
      refresh,
      query
    }
  ] as const
}
