import { TabProps } from './tab.types'

export function Tab({
  children,
  value,
  index,
  style,
  ...otherProps
}: TabProps) {
  return (
    <div
      {...otherProps}
      style={{ ...style, display: value === index ? 'block' : 'none' }}
    >
      {children}
    </div>
  )
}
