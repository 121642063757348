import { useMemo } from 'react'

import { ActivityTagsContext } from '../activityTagsContext/ActivityTagsContext'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useAuthState, useQuery } from '@percent/admin-dashboard/common/hooks'
import { ActivityTagsContextControllerProps } from './ActivityTagsContextController.types'

export function ActivityTagsContextController({ children }: ActivityTagsContextControllerProps) {
  const { eligibilityService } = useServices()
  const { isAuthorised } = useAuthState()

  const [{ data: activityTags, isLoading, errorMessage }, { refresh, query }] = useQuery(
    eligibilityService.getActivityTags,
    {},
    {
      skip: !isAuthorised
    }
  )

  const memoizedContextValue = useMemo(
    () => ({
      activityTags,
      isLoading,
      errorMessage,
      refresh,
      query
    }),
    [activityTags, errorMessage, isLoading, query, refresh]
  )

  return <ActivityTagsContext.Provider value={memoizedContextValue}>{children}</ActivityTagsContext.Provider>
}
